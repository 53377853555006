<template>
    <el-dialog :visible.sync="visible" width="500px" :modal="false" top="30vh" @close="close" :center="true" modal-append-to-body>
        <div class="top">
            <img src="../assets/img/alert.png" alt="">
            <span class="title">{{ text.title }}</span>
        </div>
        <span class="_content">
            {{ text.content }}
        </span>
        <span slot="footer" class="dialog-footer">
            <el-button class="button_info" @click="close">{{ text.cancelButtonText }}</el-button>
            <el-button class="button_primary" @click="confirm">{{ text.confirmButtonText }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        text: {
            type: Object,
            default: () => {
                return {
                    title: '提示',
                    content: '',
                    confirmButtonText: this.$t('StorageGoods.confirm'),
                    cancelButtonText: this.$t('StorageGoods.cancel'),
                    type: 'waring'
                }
            }
        }
    },
    data() {
        return {
            visible: false
        }
    },
    watch: {
        show: {
            handler(val) {
                this.visible = val;
            },
            immediate: true
        }
    },
    mounted() {

    },
    methods: {
        close() {
            this.$emit('cancel');
        },
        confirm() {
            this.$emit('confirm');
        }
    }
}
</script>
<style lang="scss" scoped>
/deep/.el-dialog__body {
    padding: 0 !important;
}

.top {
    margin: 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1d2129;
    font-weight: bold;
    font-size: 16px;

    img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }
}

._content {
    color: #86909c;
    display: block;
    text-align: center;
    margin-bottom: 10px;
}
</style>