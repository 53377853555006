<template>
  <viewer :disabled="type">
    <img :src="src" @error="errorImg($event)" style="cursor:pointer;" :style="css">
    <!-- <img src="scope.row.GoodsImage" style="height: 32px; width: 32px;cursor:pointer "> -->
  </viewer>
</template>
<script>
export default {
  props: {
    url: {
      type: String
    },
    customstyle: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imageType: true,
      css: '',
      type: false
    }
  },
  mounted() {
    this.css = this.customstyle;
    this.type = this.disabled;
  },
  computed: {
    src() {
      let i = this.url.includes('http://');
      let j = this.url.includes('https://');
      if (i || j) {
        return this.url
      } else {
        return this.$url + this.url;
      }
    }
  },
  methods: {
    errorImg(e) {
      e.srcElement.src = require('../assets/img/loaderr.png');
      //这一句没用，如果默认图片的路径错了还是会一直闪屏，在方法的前面加个.once只让它执行一次也没用
      e.srcElement.onerror = null; //防止闪图
    },
  }
}
</script>
<style lang="scss" scoped>
.el-image {
  width: 35px;
  height: 30px;
}

.errimg {
  width: 30px;
  height: 30px;
}

img {
  height: 32px;
  width: 32px;
}
</style>