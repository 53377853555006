<template>
  <!-- 增加修改页面 -->
  <div class="_dialog">
    <div class="_top">
      <i @click="close" style="cursor: pointer;" class="el-icon-d-arrow-left"></i>
      <span @click="close">{{ $t('StorageGoods.back') }}</span>
      <div style="width: 1px;height: 20px;background-color:#e5e6eb;"></div>
      <font>{{ OutKuTitle }}</font>
    </div>
    <el-form ref="form" :rules="rules" label-position="left" label-width="100px" input-width="100px" :model="StorageOutKuDan">
      <el-row>
        <el-col :span="12"><!-- 请选择库房-->
          <el-form-item :label="$t('StorageOutKu.WarehouseName')" prop="WarehouseName">
            <el-input :placeholder="$t('StorageOutKu.choice')" v-model="StorageOutKuDan.WarehouseName" @input="changeDraft($event, 'WarehouseName', 0)" readonly
              class="input-with-select">
              <el-button slot="append" icon="el-icon-thumb" @click="RoomClick(StorageOutKuDan)"></el-button>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('StorageOutKu.OutKuUserName')" prop="OutKuUserName"> <!-- 领用人 -->
            <el-input :placeholder="$t('StorageOutKu.choice')" v-model="StorageOutKuDan.OutKuUserName" @input="changeDraft($event, 'OutKuUserName', 0)" :readonly="true"
              class="input-with-select">
              <el-button slot="append" icon="el-icon-user-solid" @click="OutKuUser(StorageOutKuDan)" :disabled="outShow"></el-button>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item :label="$t('StorageOutKu.OutKuRemark')">
          <el-input :readonly="outShow" type="textarea" class="textarea" rows="3" maxlength="512" show-word-limit v-model="StorageOutKuDan.OutKuRemark"
            @input="changeDraft($event, 'OutKuRemark', 0)"></el-input>
        </el-form-item>
      </el-row>
    </el-form>

    <div class="tableBuuton">
      <el-divider></el-divider>
      <div class="_filter">
        <div>
          <el-button icon="el-icon-plus" class="button_primary" :disabled="StorageOutKuDan.WarehouseName == ''" @click="AddGoods()">{{
              $t('StorageAllot.SelectGoods') }}</el-button><!-- 选择物资 -->
          <el-button class="button_info" icon="el-icon-delete" @click="DelGoods()" :disabled="goodsChecked">{{
            $t('StorageAllot.DeleteGoods')
          }}</el-button> <!-- 删除所选 -->
          <el-input v-model="batchNumber" v-if="BatchInku" type="number" :placeholder="$t('StorageAllot.BatchInputNews')" @input="BatchInput"
            style="width: 240px !important;"></el-input>
          <el-button class="button_info" icon="el-icon-edit" @click="AddBatch()">{{ $t('StorageAllot.BatchInput')
          }}</el-button> <!-- 批量输入 -->
          <span class="Refresh"><i class="el-icon-setting" @click="TableOk = true" style="color:#165dff;cursor: pointer;">
              {{ $t('BasicDept.CustomizeHeader') }}</i> </span> <!--自定义表头-->
        </div>
      </div>
    </div>
    <div style="position: relative;width: 100%;height: calc(100vh - 500px);">
      <el-table :key="itemKey" style="position: absolute;" :data="OutKuNotes" height="calc(100vh - 500px)" border stripe @selection-change="handleSelectionChange1" ref="dialogList"
        :row-class-name="selectRowClassName1" @row-click="handleRowClick1" @header-dragend="headerDragend">
        <el-table-column v-if="!outShow" type="selection" width="55">
        </el-table-column>
        <el-table-column type="index" width="55" :label="$t('StorageOutKu.NumBer')"> </el-table-column>
        <el-table-column :sortable="item.sort" v-for="(item, index) in TableList" :key="index" :prop="item.propName" :label="item.labelName" :width="item.width">
          <template slot-scope="scope">
            <ImageView v-if="item.propName == 'HuoPinImage'" :url="scope.row.HuoPinImage||scope.row.GoodsImage"></ImageView>
            <el-input :placeholder="$t('StorageInKu.InputCount')" oninput="value=value.replace(/[^\d]/g,'')" type="" v-else-if="item.propName == 'InKuCount'" class="_table_input"
              :ref="`inputRef${scope.$index + 1}`" v-model="scope.row.FactStock" :data-rowindex="'FactStock' + scope.$index"
              @keydown.enter.tab.native="focusTabInput(scope.$index + 1)"></el-input>

            <el-input v-else-if="item.propName == 'OutKuCount'" oninput="value=value.replace(/[^\d]/g,'')" class="_table_input" v-model="scope.row.OutKuCount"
              :data-rowindex="'OutKuCount' + scope.$index" :ref="`inputRef${scope.$index + 1}`" @keydown.tab.native="focusTabInput(scope.$index + 1)"
              @keyup.enter.native="focusTabInput(scope.$index + 1, 'OutKuCount')"></el-input>

            <el-popover v-else-if="item.propName == 'OutKuRemark'" placement="top-start" width="200" trigger="click">
              <span>{{ scope.row.OutKuRemark }}</span>
              <span slot="reference">{{ scope.row.OutKuRemark }}</span>
            </el-popover>
            <span v-else>
              {{ scope.row[item.propName] }}
            </span>
          </template>
        </el-table-column>

      </el-table>
    </div>

    <div class="but_add">
      <div>
        <span> {{$t('StorageOutKu.Count')}} {{ OutKuNumber }} {{$t('StorageOutKu.SumMoney')}}{{ OutKuAllPrice }}</span>
      </div>
      <div>
        <el-button @click="close" class="button_info" style="margin-right: 7px">{{$t('StorageOutKu.cancel')}}</el-button>
        <el-button type="primary" class="button_primary" @click="append()">{{$t('StorageOutKu.confirm')}}</el-button>
      </div>
    </div>

    <!-- 部门组件 -->
    <SelectDept v-if="DeptType" :type="DeptType" @close="DeptType = false" @addMember="addDept" :DataList="DeptList" :ISCompany="2"></SelectDept>
    <!-- 库房组件 -->
    <SelectWarehouse v-if="Roomtype" :type="Roomtype" @close="Roomtype = false" @addMember="addRoom" :DataList="RoomList"></SelectWarehouse>
    <!-- 领用人组件 -->
    <AdminUser v-if="type" :type="type" @close="type = false" @addMember="AddOutKuUser" :DataList="UserList"></AdminUser>
    <!-- 物资组件 -->
    <SystemGoods v-if="gooodsType" :type="gooodsType" :warehouseid="StorageOutKuDan.WarehouseID" :DataList="DataList" @close="gooodsType = false" @addGoods="addGoods">
    </SystemGoods>
    <!-- 出库单详情 -->
    <OutKuDetail v-if="showdetail" :show="showdetail" :form="StorageOutKuDan" :Notes="OutKuNotes" @close="showdetail = false" />
    <DragTable v-if="TableOk" TableName="StorageOutKuAdd" :show="TableOk" @message="Message_">
    </DragTable>
  </div>
</template> 
<script> 
import OutKuDetail from '@/components/storage/OutKuDetail.vue';
import {
  successTips,
  msgTips,
  getOutKuDanStatus,
  updateTime,
  GetBasicDeptTree,
  GetStorageWarehouseTree,
  getOutKuDetail,
  SetBasicUserColumnWidth,
  LoadBasicUserColumn,
  addOutKuDan,
  updateOutKuDan,
  GetStorageShelfPage
} from "@/api/user";
import draft from '@/mixins/draft';
export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  props: {
    show: {
      type: Boolean,
    },
    OutID: {
      type: Number,
      default: 0
    },
    OutKuOpertion: {
      type: Object
    },
    OutKuTitle: {
      type: String,
      default: ''
    },
    state: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  mixins: [draft],
  name: "SettingView",
  components: { OutKuDetail },
  computed: {
    OutKuNumber() {
      let sum = 0;
      let arr = JSON.parse(JSON.stringify(this.OutKuNotes))
      for (let i = 0; i < arr.length; i++) {
        let data = JSON.parse(JSON.stringify(arr[i]))
        sum += parseFloat(data.OutKuCount ? data.OutKuCount : 0);
      }
      return sum || 0;
    },
    OutKuAllPrice() {
      let sum = 0;
      let arr = JSON.parse(JSON.stringify(this.OutKuNotes))
      for (let i = 0; i < arr.length; i++) {
        let data = JSON.parse(JSON.stringify(arr[i]))
        let p = parseFloat((data.OutKuCount ? data.OutKuCount : 0) * data.DefaultPrice).toFixed(2);
        sum += parseFloat(p);
      }
      return sum || 0;
    },
  },
  data() {
    return {
      DeptList: [],
      RoomList: [],
      TableOk: false,
      DeptType: false,
      Roomtype: false,
      goodsAdd: true,
      querystate: true,
      delstate: true,
      operated: true,
      OperateType: "",
      Times: null,
      loading: false,
      outShow: false,
      BatchInku: false,
      tabs: 0,
      menutab: 0,
      ID: "",
      level: "",
      drawer: false,
      filterText: "",
      icon: "",
      title: "",
      currentPage: 1,
      fileList: "",
      Title: "",
      type: false,
      gooodsType: false,
      checked: true,
      goodsChecked: true,
      dialogFormVisible: false,
      // 表头数据
      OutKuTable: [
        {
          propName: "OutKuDanCode",
          labelName: "出库单号",
          width: "20",
        },
        {
          propName: "WarehouseName",
          labelName: "库房名称",
          width: "20",
        },
        {
          propName: "OutKuUserName",
          labelName: "领用人",
          width: "20",
        },
        {
          propName: "OutKuDeptName",
          labelName: "领用部门",
          width: "20",
        },
        {
          propName: "Operator",
          labelName: "经办管理员",
          width: "20",
        },
        {
          propName: "OutKuStatusName",
          labelName: "状态",
          width: "20",
        },
        {
          propName: "OutKuRemark",
          labelName: "出库单备注",
          width: "20",
        },
        {
          propName: "OutKuTime",
          labelName: "出库时间",
          width: "20",
        }
      ],
      tableData: [],
      StorageOutKuDan: {
        OutKuShelfID: '',
        OutKuDanCode: "",
        WarehouseID: "",
        WarehouseName: "",
        OutKuUserID: "",
        OutKuUserName: "",
        OutKuDeptID: "",
        OutKuDeptName: "",
        Operator: "",
        OutKuStatus: "",
        OutKuRemark: "",
        OutKuTime: new Date(),
      },
      TotalCount: 0,
      form: {
        PageSize: 20,
        PageIndex: 0,
        StartTime: null,
        EndTime: null,
        SearchName: "",
        WarehouseID: "",
        WarehouseName: "",
        OutKuDanCode: "",
        OutKuUserName: "",
        OutKuUserID: "",
        OutKuDeptName: "",
        OutKuDeptID: "",
        Operator: "",
        OutKuStatusName: "",
        OutKuStatus: "",
      },
      OutKuNotes: [],
      data: [],
      dept: [],
      TableList: [],
      value: "",
      warehouse: "",
      NotesID: null,
      rules: {
        WarehouseName: [
          { required: true, message: this.$t('StorageOutKu.choice'), trigger: "blur" },
        ],
        OutKuUserName: [{ required: true, message: this.$t('StorageOutKu.choice'), trigger: "blur" }],
        OutKuDeptName: [{ required: true, message: this.$t('StorageOutKu.choice'), trigger: "blur" }],
      },
      options: [
        {
          label: "已确认",
          value: 1,
        },
        {
          label: "待确认",
          value: 2,
        },
      ],

      multipleSelection: [],
      multipleSelection1: [],
      UserList: [],
      upload: false,
      uptype: '',
      itemKey: '',
      exportColumn: [],
      ExportType: '',
      errString: "",
      errUrl: '',
      showdetail: false,
      viewtype: '',
      draftTitle: ['StorageOutKuDan'],
      batchNumber: null,
      Outoptions: []
    };
  },
  // watch:{
  //   currentPrice:{
  //     handler(val){
  //       this.price = JSON.parse(JSON.stringify(val))
  //     },
  //     immediate:true
  //   }
  // },
  created() {
    // 当页面刷新时，若本地存储中存的有表头数组信息，就用本地的。当然第一次本地是没有的
    if (sessionStorage.getItem("OutKuTable")) {
      this.OutKuTable = JSON.parse(sessionStorage.getItem("OutKuTable"));
    }
  },
  mounted() {
    this.getWarehouseTree();
    this.getDeptTree();
    this.getStatus();
    this.getTableColumn();
    if (this.state) {
      this.StorageOutKuDan = JSON.parse(window.localStorage.getItem(this.draftTitle[this.draftIndex]));
      this.OutKuNotes = JSON.parse(window.localStorage.getItem('StorageOutKuNotes')) || [];
    }
    if (this.OutKuOpertion.Opertion == "Update") {
      getOutKuDetail({ OutKuDanID: this.OutID }).then(
        (res) => {
          if (res.Code == 0) {
            this.StorageOutKuDan = res.Data;
            for (let i = 0; i < res.Data.DetailList.length; i++) {
              this.$set(res.Data.DetailList[i], 'GoodsImage', res.Data.DetailList[i].HuoPinImage);
            }
            this.OutKuNotes = res.Data.DetailList;
            this.StorageOutKuDan.OutKuTime = updateTime(this.StorageOutKuDan.OutKuTime);
            if (res.Data.WarehouseName) {
              this.warehouse = res.Data.WarehouseName;
            }

          }
        }
      );
    }
    this.StorageOutKuDan.OutKuTime = new Date();
    this.StorageOutKuDan.Operator = this.$store.state.userinfo.UserName;
    this.dialogFormVisible = this.show;
    document.addEventListener('keydown', this.keyprevent)
  },
  renderHeader(h, { column }) {
    return h('el-input', {
      props: {
        value: column.label,
        size: 'small'
      },
      on: {
        input: (value) => {
          column.label = value;
          this.$forceUpdate(); // 强制Vue更新视图
        }
      }
    });
  },
  //批量填充
  AddBatch() {
    if (this.BatchInku == false) {
      this.BatchInku = true;
    } else {
      this.BatchInku = false;
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keyprevent)
  },
  methods: {
    //刷新
    Message_() {
      this.getTableColumn();
      let NotesList = this.OutKuNotes;
      this.OutKuNotes = NotesList;
      this.itemKey = Math.random();
      this.TableOk = false;
    },
    // 表单加载
    getTableColumn() {
      LoadBasicUserColumn({ TableKey: 'StorageOutKuAdd' }).then(res => {
        if (res.Code == 0) {
          this.TableList = res.Data;
        }
      })
    },
    addRoom(e) {
      this.OutKuNotes = [];
      this.StorageOutKuDan.WarehouseID = e.WarehouseID;
      this.StorageOutKuDan.WarehouseName = e.WarehouseName;
      this.changeDraft(e.WarehouseID, 'WarehouseID', 0);
      this.changeDraft(e.WarehouseName, 'WarehouseName', 0);
      this.$refs['form'].clearValidate(['WarehouseName']);  //移除表单某个字段的校验 
      this.Roomtype = false;
    },
    addDept(e) {
      this.StorageOutKuDan.OutKuDeptID = e.DeptID;
      this.StorageOutKuDan.OutKuDeptName = e.DeptName;
      this.changeDraft(e.DeptID, 'OutKuDeptID', 0);
      this.changeDraft(e.DeptName, 'OutKuDeptName', 0);
      this.DeptType = false;
    },
    //部门点击事件
    DeptClick(data) {
      this.DeptList = [];
      if (data) {
        this.DeptType = true;
        this.DeptList.push(data.DeptID);
      }
    },
    //库房点击事件
    RoomClick(data) {
      this.RoomList = [];
      if (data) {
        this.Roomtype = true;
        this.RoomList.push(data.WarehouseID);
      }
    },
    getShelf() {
      let form = {
        PageIndex: 0,
        PageSize: 100,
        WarehouseID: this.StorageOutKuDan.WarehouseID
      }
      GetStorageShelfPage(form).then(res => {
        if (res.Code == 0) {
          this.Outoptions = res.Data;
        }
      })
    },
    keyprevent(event) {
      if (event.keyCode === 9) {
        event.preventDefault()
      }
    },
    focusTabInput(rowIndex) {
      this.$nextTick(() => {
        this.$refs.dialogList.clearSelection();
        if (rowIndex < this.OutKuNotes.length) {
          this.$refs[`inputRef${parseInt(rowIndex + 1)}`].focus();
          this.$refs.dialogList.toggleRowSelection(this.OutKuNotes[rowIndex]);
        } else {
          this.$refs[`inputRef1`].focus();
          this.$refs.dialogList.toggleRowSelection(this.OutKuNotes[0]);
        }
      })
    },


    //出库人点击事件
    OutKuUser(data) {
      this.UserList = [];
      if (data) {
        this.type = true;
        this.UserList.push(data.OutKuUserID);
      }
    },

    //清空表格数据
    close() {
      this.draftState[this.draftIndex] = false;
      if (this.OutKuNotes.length != 0) {
        window.localStorage.setItem('StorageOutKuNotes', JSON.stringify(this.OutKuNotes))
      }
      this.$emit("close");
    },

    getWarehouseTree() {
      GetStorageWarehouseTree().then((res) => {
        if (res.Code == 0) {
          this.data = res.Data;
        }
      });
    },
    getDeptTree() {
      GetBasicDeptTree().then((res) => {
        if (res.Code == 0) {
          this.dept = res.Data;
        }
      });
    },
    getStatus() {
      getOutKuDanStatus().then((res) => {
        if (res.Code == 0) {
          if (res.Data) {
            this.options = [];
            for (let item of res.Data) {
              this.options.push({
                label: item.name,
                value: item.value,
              });
            }
          }
        }
      });
    },
    selStatus(e) {
      for (let item of this.options) {
        if (item.value == e) {
          this.form.OutKuStatusName = item.label;
        }
      }
    },
    // 获取库房标识
    getWarehouseID(e) {
      if (e) {
        this.StorageOutKuDan.WarehouseID = e.id;
        this.StorageOutKuDan.WarehouseName = e.label;
        this.form.WarehouseID = e.id;
        this.form.WarehouseName = e.label;
        this.StorageOutKuDan.OutKuShelfID = null;
        this.goodsAdd = false;
        this.OutKuNotes = [];
        this.changeDraft(e.WarehouseName, 'WarehouseName', 0);
        window.localStorage.setItem('StorageOutKuNotes', null);
      } else {
        this.goodsAdd = true;
      }
    },
    DeptInput(e) {
      if (e) {
        this.StorageOutKuDan.OutKuDeptID = e.id;
        this.StorageOutKuDan.OutKuDeptName = e.label;
        this.form.OutKuDeptID = e.id;
        this.form.OutKuDeptName = e.label;
        this.changeDraft(e.label, 'OutKuDeptName', 0)
      } else {
        this.StorageOutKuDan.OutKuDeptID = "";
        this.StorageOutKuDan.OutKuDeptName = "";
        this.form.OutKuDeptID = "";
        this.form.OutKuDeptName = "";
        this.changeDraft('', 'OutKuDeptName', 0)
      }
    },
    // 用户点击事件
    AddOutKuUser(e) {
      this.StorageOutKuDan.OutKuUserID = e.UserID;
      this.StorageOutKuDan.OutKuUserName = e.UserName;
      this.StorageOutKuDan.OutKuDeptID = e.DeptID;
      this.StorageOutKuDan.OutKuDeptName = e.DeptName;
      this.form.OutKuUserID = e.UserID;
      this.form.OutKuUserName = e.UserName;
      this.$refs['form'].clearValidate(['OutKuUserName']);  //移除表单某个字段的校验 
      this.type = false;
      this.changeDraft(e.DeptID, 'OutKuDeptID', 0);
      this.changeDraft(e.DeptName, 'OutKuDeptName', 0);
      this.changeDraft(e.UserID, 'OutKuUserID', 0);
      this.changeDraft(e.UserName, 'OutKuUserName', 0);
    },


    //操作
    append() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let list = this.OutKuNotes;
          if (list.length == 0) {
            msgTips(this.$t('StorageOutKu.OutKuMsgTips'));
            return;
          }
          let form = {
            // ID: this.StorageOutKuDan.ID,
            WarehouseID: this.StorageOutKuDan.WarehouseID,
            // Supplier: this.StorageOutKuDan.Supplier,
            OutKuUserID: this.StorageOutKuDan.OutKuUserID,
            OutKuRemark: this.StorageOutKuDan.OutKuRemark,
            OutKuDeptID: this.StorageOutKuDan.OutKuDeptID,
            GoodsDetail: "",
          };
          let str = [];
          for (let item of list) {
            let { GoodsID, OutKuCount, GoodsRemark, DefaultPrice } = item;
            str.push({
              GoodsID,
              FactStock: OutKuCount,
              GoodsRemark,
              AllPrice: parseFloat(OutKuCount * DefaultPrice).toFixed(2),
            });
          }
          form.GoodsDetail = JSON.stringify(str);
          if (this.OutKuOpertion.Opertion == "Add") {
            addOutKuDan(form).then((res) => {
              if (res.Code == 0) {
                successTips(res.Message);
                this.value = "";
                this.OutKuNotes = [];
                window.localStorage.setItem(this.draftTitle[this.draftIndex], null);
                window.localStorage.setItem('StorageOutKuNotes', null);
                this.draftState[this.draftIndex] = false;
                this.dialogFormVisible = false;
                this.reset();
                this.$emit('message', res.Code);
              }
            });
          } else {
            updateOutKuDan({
              OutKuDanID: this.StorageOutKuDan.OutKuDanID,
              ...form,
            }).then((res) => {
              if (res.Code == 0) {
                successTips(res.Message);
                this.value = "";
                this.OutKuNotes = [];
                window.localStorage.setItem(this.draftTitle[this.draftIndex], null);
                window.localStorage.setItem('StorageOutKuNotes', null);
                this.draftState[this.draftIndex] = false;
                this.dialogFormVisible = false;
                this.reset();
                this.$emit('message', res.Code);
              }
            });
          }
        }
      });
    },

    reset() {
      for (let i in this.StorageOutKuDan) {
        this.StorageOutKuDan[i] = "";
      }
      for (let i in this.form) {
        this.form[i] = "";
      }
      this.form.PageIndex = 0;
      this.form.PageSize = 20;
      this.value = "";
      this.warehouse = "";
      if (!this.draftState[this.draftIndex]) {
        this.OutKuNotes = [];
      }
    },
    Search() { },
    //点击行触发，选中或不选中复选框
    handleRowClick(row) {
      if (row.OutKuStatus == 1) {
        return false;
      }
      this.$refs.tableList.toggleRowSelection(row); //设置复选框为选中状态
    },
    selectRowClassName({ row }) {
      var color = "";
      this.multipleSelection.forEach(item => {
        if (item.OutKuDanID == row.OutKuDanID) {
          color = "warning-row";
        }
      });
      return color;
    },

    selectable(row) {
      if (row.OutKuStatus == 1) {
        return false;
      } else {
        return true;
      }
    },
    handleRowClick1(row) {
      // let index = this.multipleSelection1.findIndex(item => {
      //   // 判断已选数组中是否已存在该条数据
      //   return item.HuoPinID == row.HuoPinID
      // });
      // this.$refs.dialogList.clearSelection();
      // if (index == -1) {
      //   // 如果未存在，设置已选状态，并在list中添加这条数据
      //   this.$refs.dialogList.toggleRowSelection(row, true); //设置复选框为选中状态
      // } else {
      //   // 如果已存在，设置未选状态，并在list中删除这条数据
      //   this.$refs.dialogList.toggleRowSelection(row, false); //设置复选框为未选状态
      // }
      this.$refs.dialogList.toggleRowSelection(row); //设置复选框为选中状态
    },
    // 表头拖动事件
    headerDragend(newWidth, data, e) {
      data
      if (e.property != null) {
        SetBasicUserColumnWidth({ TableKey: 'StorageOutKuAdd', ColumnKey: e.property, Width: newWidth }).then(res => {
          if (res.Code == 0) {
            successTips(res.Message);
          }
        })
      }
    },
    selectRowClassName1({ row }) {
      var color = "";
      this.multipleSelection1.forEach(item => {
        if (item.GoodsID == row.GoodsID) {
          color = "warning-row";
        }
      });
      return color;
    },
    // dialog中的货品列表选中数据
    handleSelectionChange1(val) {
      if (val.length != 0) {
        this.goodsChecked = false;
        this.selectTable = val;
      } else {
        this.goodsChecked = true;
      }
      this.multipleSelection1 = JSON.parse(JSON.stringify(val));
      if (val.length == 1) {

        this.NotesID = val[0].GoodsID;
      }
    },
    // 选择资产
    async addGoods(e) {
      for (let i = 0; i < e.length; i++) {
        this.OutKuNotes.push(e[i]);
      }
      let arr1 = this.unique(this.OutKuNotes);
      this.OutKuNotes = [];
      this.OutKuNotes = arr1;
      window.localStorage.setItem('StorageOutKuNotes', JSON.stringify(e));
      this.gooodsType = false;
    },
    //去重
    unique(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.GoodsID) && res.set(arr.GoodsID, 1));
    },
    //批量填充
    AddBatch() {
      if (this.BatchInku == false) {
        this.BatchInku = true;
      } else {
        this.BatchInku = false;
      }
    },
    //增加资产
    AddGoods() {
      this.gooodsType = true;
      this.DataList = [];
      if (this.OutKuNotes != null) {
        for (let i = 0; i < this.OutKuNotes.length; i++) {
          this.DataList.push(this.OutKuNotes[i].GoodsID);
        }
      }
    },
    // 删除货品
    DelGoods() {
      for (let i = 0; i < this.selectTable.length; i++) {
        let list = JSON.parse(JSON.stringify(this.OutKuNotes));
        this.OutKuNotes = list.filter((item) => item.GoodsID != this.selectTable[i].GoodsID);
        window.localStorage.setItem('StorageOutKuNotes', JSON.stringify(this.OutKuNotes));
      }
    },


    BatchInput(e) {
      let list = this.OutKuNotes.map(item => {
        item.OutKuCount = e;
        return item
      })
      this.OutKuNotes = JSON.parse(JSON.stringify(list));
    }
  },
};
</script>
<style lang="scss" scoped>
.el-table .el-table__cell .el-input {
  padding: 0px !important;
}
.but_add {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.tableBuuton {
  margin-top: -20px;
}
.dialog-footer {
  float: right;
}
form.el-form.el-form--label-left {
  margin-top: 24px;
}
.input-with-select.el-input.el-input-group.el-input-group--append {
  width: 75%;
}
._dialog {
  width: 100%;
  height: calc(100vh - 140px);
  padding: 0 20px;
  background-color: #fff;

  ._top {
    height: 48px;
    display: flex;
    align-items: center;

    span {
      color: #4e5969;
      font-size: 14px;
      margin: 0 20px 0 5px;
      cursor: pointer;
    }

    font {
      font-size: 16px;
      font-weight: bold;
      margin-left: 20px;
    }
  }

  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 160px;
      height: 160px;
    }

    span {
      line-height: 30px;
    }

    font {
      color: #5c90ff;
      cursor: pointer;
    }
  }

  ._footer {
    padding: 10px 0 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
._top {
  height: 48px;
  display: flex;
  align-items: center;

  span {
    color: #4e5969;
    font-size: 14px;
    margin: 0 20px 0 5px;
    cursor: pointer;
  }

  font {
    font-size: 16px;
    font-weight: bold;
    margin-left: 20px;
  }
}
.SearchName {
  margin-right: 0% !important;
}

.textarea {
  width: 88.5% !important;
}

/deep/ .el-table__empty-block {
  width: 20%;
  min-width: 121%;
  max-width: 20%;
  padding-right: 20%;
}

.companyimg {
  line-height: 150px;
}

.el-icon-plus {
  border: 1px dashed;
  // background-color: #8c939d;
}

//头像
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 15px;
  color: #8c939d;
  width: 135px;
  height: 128;
  line-height: 120px;
  text-align: center;
}

.el-input--mini .el-input__inne {
  height: 39px;
}

.avatar {
  width: 135px;
  height: 128;
  display: block;
}

//
.ul_but li {
  margin-bottom: 20px;
}

.ul_but:hover {
  cursor: pointer;
}
.el-tabs__nav-wrap {
  width: 100%;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

._table_input {
  border-bottom: 1px solid #eee;

  /deep/.el-input__inner {
    text-align: center;
  }
}

.settin-box {
  width: 100%;
  height: calc(100vh - 20px);
  display: flex;
  background-color: #fff;
  border-radius: 5px;

  ul {
    padding: 0;
    margin: 0;
  }

  ._right {
    display: flex;
    flex: 1;
    position: relative;

    .content_left {
      height: calc(100vh - 70px);
      padding: 30px 10px;
      font-size: 19px;

      .tabs {
        display: flex;
        height: 50px;

        .tabs_item {
          display: block;
          padding: 10px;
          // line-height: 30px;

          &:hover {
            color: #409eff;
            cursor: pointer;
          }
        }
      }

      .content {
        padding: 20px 10px;

        span {
          font-size: 14px;
        }

        .title_box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .el-icon-plus {
            color: #409eff;
            cursor: pointer;
          }
        }

        .title {
          font-size: 14px;
          font-weight: 550;
          display: block;
          margin-bottom: 20px;
        }

        ul li {
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 0 0 10px;
          border-radius: 20px;
          margin: 10px 0;

          // &:hover {
          //   background-color: #409eff;
          //   color: #fff;
          //   cursor: pointer;
          // }

          span {
            margin-left: 10px;
          }
        }
      }
    }

    .content_right {
      height: calc(100vh - 70px);
      position: absolute;
      width: 100%;
      right: 0;
      padding: 10px;

      .head_line {
        display: flex;
        align-items: center;
        height: 80px;

        .el-button {
          margin-left: 20px;
        }
      }

      .content_table {
        width: 100%;
        overflow: hidden;
        box-shadow: 0 0 5px rgb(201, 199, 199);
      }
    }
  }

  @media screen and (max-width: 1400px) {
    ._left {
      display: none;
    }
  }
}

._filter {
  height: 35px;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  span {
    margin-right: 20px;
    font-size: 14px;
    cursor: pointer;
    float: right;
    line-height: 45px;
    .el-icon-refresh {
      margin-left: 10px;
      font-size: 16px;
    }
  }
}

.check {
  color: #409eff;
  border-bottom: 2px solid #409eff;
}

.listindex {
  color: #fff;
  background-color: #409eff;
}

._filter_btn {
  width: 100%;
  text-align: center;
  bottom: 100px;
  margin-top: 15%;

  .el-button {
    width: 100px;

    &:nth-child(2) {
      margin-left: 50px;
    }
  }
}

.el-icon-refresh {
  color: #409eff;
}

.filter_box {
  ul {
    padding-left: 10px;
  }

  li {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .date {
      // margin-left: 45px;
      width: 300px;

      .el-input:nth-child(2) {
        margin-top: 10px;
      }

      /deep/.el-input {
        .el-input__inner {
          width: 300px !important;
        }
      }
    }
  }

  font {
    display: inline-block;
    width: 100px;
    font-size: 14px;
    color: #fff;
  }

  .el-select {
    width: 300px;
  }

  .el-input {
    width: 300px;
  }

  ._filter_btn {
    width: 100%;
    text-align: center;
    bottom: 100px;

    .el-button {
      width: 100px;

      &:nth-child(2) {
        margin-left: 50px;
      }
    }
  }
}

::v-deep .el-form-item > .el-form-item__label::before {
  content: "" !important;
  color: #000 !important;
}

::v-deep .el-form-item__label::after {
  content: "*";
  color: #ffffff;
  margin-left: 4px;
}

::v-deep .is-required .el-form-item__label::after {
  content: "*";
  color: #ff0000;
  margin-left: 4px;
}
</style>