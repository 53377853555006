<template>
  <!-- 增加修改页面 -->
  <div class="_dialog">
    <div class="_top">
      <i @click="close" style="cursor: pointer;" class="el-icon-d-arrow-left"></i>
      <span @click="close"> {{ $t('StorageGoods.back') }}</span>
      <div style="width: 1px;height: 20px;background-color:#e5e6eb;"></div>
      <font>{{ Title }}</font><!--入库单申请-->
    </div>
    <div style="height: calc(100vh - 232px);overflow-y: auto;">
      <el-form ref="form" style="padding:24px" :rules="rules" label-position="left" label-width="100px" input-width="100px" :model="AssetReceived">
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('AssetReceivedAdd.ReceivedUserName')" prop="ReceivedUserName">
              <el-input :placeholder="$t('AssetReceivedAdd.sel')" v-model="AssetReceived.ReceivedUserName" readonly>
                <el-button slot="append" icon="el-icon-user-solid" @click="InkuUserClick(AssetReceived)"></el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <!--所属公司-->
          <el-col :span="12">
            <el-form-item :label="$t('AssetReceivedAdd.BelongCDeptID')" prop="BelongCDeptName">
              <el-input :placeholder="$t('AssetReceivedAdd.BelongCDeptID')" v-model="AssetReceived.BelongCDeptName" @input="changeDraft($event, 'BelongCDeptName', 0)" readonly
                class="input-with-select" style="width: 70%">
                <el-button slot="append" icon="el-icon-thumb" @click="CDeptClick(AssetReceived)"></el-button>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('AssetReceivedAdd.GoodsSource')" prop="GoodsSource">
              <el-select v-model="AssetReceived.GoodsSource" @change="changeDraft($event, 'GoodsSource', 0)" :placeholder="$t('AssetReceivedAdd.sel')">
                <el-option v-for="item in  GoodsSource" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('AssetReceivedAdd.ReceivedTime')">
              <el-date-picker v-model="AssetReceived.ReceivedTime" :readonly="true" @change="changeDraft($event, 'ReceivedTime', 0)" type="datetime"
                :placeholder="$t('AssetReceivedAdd.sel')">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item :label="$t('AssetReceivedAdd.ReceivedRemark')">
          <el-input type="textarea" class="textareaName" rows="2" v-model="AssetReceived.ReceivedRemark" @input="changeDraft($event, 'ReceivedRemark', 0)" maxlength="512"
            show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <el-divider></el-divider>
      <div class="_filter">
        <div>
          <el-button class="button_primary" icon="el-icon-plus" @click="TableAdd">{{
          $t('AssetReceivedAdd.add')
        }}</el-button>
          <el-button class="button_primary" icon="el-icon-plus" @click="CopyTableAdd" :disabled="CopyList.length == 0">{{
          $t('AssetReceivedAdd.copy')
        }}</el-button>
          <el-button class="button_info" icon="el-icon-delete" @click="DelGoods()" :disabled="goodsChecked">{{
          $t('AssetReceivedAdd.delete')
        }}</el-button>
        </div>
        <span class="Refresh"><i class="el-icon-setting" @click="TableOk = true" style="color:#165dff;cursor: pointer;">
            {{ $t('BasicDept.CustomizeHeader') }}</i> </span> <!--自定义表头-->
      </div>
      <div style="position: relative;width: 100%;height: calc(100vh - 550px);">
        <el-table :key="itemKey" style="position: absolute;" border :data="InKuNotes" height="calc(100vh - 550px)" stripe @selection-change="handleSelectionChange1"
          v-loading="loading" @header-dragend="headerDragend">
          ref="rules" @row-click="handleRowClick1">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column type="index" width="55" :label="$t('AssetReceivedAdd.NO')"> </el-table-column>
          <el-table-column :sortable="item.sort" v-for="(item, index) in AddTable" :key="index" :prop="item.propName" :label="item.labelName" :width="item.width">
            <template slot-scope="scope">
              <ImageView v-if="item.propName =='GoodsImage'" :url="scope.row.GoodsImage"></ImageView>
              <el-input v-if="item.propName == 'HuoPinName'" readonly clearable :placeholder="$t('AssetReceivedAdd.sel')" v-model="scope.row.HuoPinName" class="input-with-select"
                style="width:100px;">
                <el-button slot="append" icon="el-icon-plus" v-if="Title == $t('AssetReceived.add')" @click="huoPinClick(scope.row)"></el-button>
                <el-button slot="append" icon="el-icon-plus" v-if="Title == $t('AssetReceived.update')" @click="huoPinClick(scope.row)"></el-button>
              </el-input>

              <el-input v-else-if="item.propName == 'TypeName'" readonly clearable :placeholder="$t('AssetReceivedAdd.sel')" v-model="scope.row.TypeName" class="input-with-select"
                style="width:100px;">
                <el-button slot="append" icon="el-icon-plus" v-if="Title == $t('AssetReceived.add')" @click="typeClick(scope.row)"></el-button>
                <el-button slot="append" icon="el-icon-plus" v-if="Title == $t('AssetReceived.update')" @click="typeClick(scope.row)"></el-button>
              </el-input>

              <el-input v-else-if="item.propName == 'DistrictName'" readonly clearable :placeholder="$t('AssetReceivedAdd.sel')" v-model="scope.row.DistrictName"
                class="input-with-select" style="width:100px;">
                <el-button slot="append" icon="el-icon-plus" v-if="Title == $t('AssetReceived.add')" @click="RegionClick(scope.row)"></el-button>
                <el-button slot="append" icon="el-icon-plus" v-if="Title == $t('AssetReceived.update')" @click="RegionClick(scope.row)"></el-button>
              </el-input>

              <el-input v-else-if="item.propName == 'PutFactAddress'" class="_table_input" v-model="scope.row.PutFactAddress" @change="changeNotes()"></el-input>

              <el-input v-else-if="item.propName == 'BelongCDeptName'" readonly clearable :placeholder="$t('AssetReceivedAdd.sel')" v-model="scope.row.BelongCDeptName"
                class="input-with-select" style="width:100px;">
                <el-button slot="append" icon="el-icon-plus" v-if="Title == $t('AssetReceived.add')" @click="OperCDeptClick(scope.row)"></el-button>
                <el-button slot="append" icon="el-icon-plus" v-if="Title == $t('AssetReceived.update')" @click="OperCDeptClick(scope.row)"></el-button>
              </el-input>

              <el-input v-else-if="item.propName == 'UseDeptName'" readonly clearable :placeholder="$t('AssetReceivedAdd.sel')" v-model="scope.row.UseDeptName"
                class="input-with-select" style="width:100px;">
                <el-button slot="append" icon="el-icon-plus" v-if="Title == $t('AssetReceived.add')" @click="UserDeptClick(scope.row)"></el-button>
                <el-button slot="append" icon="el-icon-plus" v-if="Title == $t('AssetReceived.update')" @click="UserDeptClick(scope.row)"></el-button>
              </el-input>

              <el-input v-else-if="item.propName == 'UseUserName'" :readonly="true" :placeholder="$t('AssetReceivedAdd.sel')" v-model="scope.row.UseUserName"
                class="input-with-select">
                <el-button slot="append" icon="el-icon-user" v-if="Title == $t('AssetReceived.add')" @click="userClick(scope.row)"></el-button>
                <el-button slot="append" icon="el-icon-user" v-if="Title == $t('AssetReceived.update')" @click="userClick(scope.row)"></el-button>
              </el-input>

              <el-input v-else-if="item.propName == 'ManageUserName'" :readonly="true" :placeholder="$t('AssetReceivedAdd.sel')" v-model="scope.row.ManageUserName"
                class="input-with-select">
                <el-button slot="append" icon="el-icon-user" v-if="Title == $t('AssetReceived.add')" @click="adminClick(scope.row)"></el-button>
                <el-button slot="append" icon="el-icon-user" v-if="Title == $t('AssetReceived.update')" @click="adminClick(scope.row)"></el-button>
              </el-input>

              <el-input v-else-if="item.propName == 'GoodsCode'" class="_table_input" v-model="scope.row.GoodsCode" @change="changeNotes()"></el-input>

              <el-input v-else-if="item.propName == 'GoodsName'" class="_table_input" v-model="scope.row.GoodsName" @change="changeNotes()"></el-input>

              <el-input v-else-if="item.propName == 'GoodsRFIDEPC'" id="RFID" class="_table_input" v-model="scope.row.GoodsRFIDEPC" @change="changeNotes()"></el-input>

              <el-input v-else-if="item.propName == 'GoodsModels'" class="_table_input" v-model="scope.row.GoodsModels" @change="changeNotes()"></el-input>

              <el-input v-else-if="item.propName == 'CalcUnit'" class="_table_input" v-model="scope.row.CalcUnit" @change="changeNotes()"></el-input>

              <el-input v-else-if="item.propName == 'SerialNO'" class="_table_input" v-model="scope.row.SerialNO" @change="changeNotes()"></el-input>

              <el-select v-else-if="item.propName == 'GoodsSourceName'" class="_table_input" v-model="scope.row.GoodsSource" @change="changeNotes()" placeholder="">
                <el-option v-for="data in GoodsSource" :key="data.value" :label="data.name" :value="data.value">
                </el-option>
              </el-select>
              <el-input v-else-if="item.propName == 'ServiceLife'" class="_table_input" v-model="scope.row.ServiceLife" @change="changeNotes()"></el-input>
              <el-input v-else-if="item.propName == 'EXPWarning'" class="_table_input" v-model="scope.row.EXPWarning" @change="changeNotes()"></el-input>
              <el-input v-else-if="item.propName == 'EXPAlarm'" class="_table_input" v-model="scope.row.EXPAlarm" @change="changeNotes()"></el-input>
              <el-date-picker v-else-if="item.propName == 'DateOfPurchase'" v-model="scope.row.DateOfPurchase" @change="changeNotes()" type="datetime" class="_table_input"
                :placeholder="$t('AssetReceivedAdd.sel')">
              </el-date-picker>
              <el-input v-else-if="item.propName == 'OriginalValue'" class="_table_input" v-model="scope.row.OriginalValue" @change="changeNotes()"></el-input>
              <el-input v-else-if="item.propName == 'Supplier'" class="_table_input" v-model="scope.row.Supplier" @change="changeNotes()"></el-input>

              <el-input v-else-if="item.propName == 'SupplierContactUser'" class="_table_input" v-model="scope.row.SupplierContactUser" @change="changeNotes()"></el-input>

              <el-input v-else-if="item.propName == 'SupplierContactPhone'" class="_table_input" v-model="scope.row.SupplierContactPhone" @change="changeNotes()"></el-input>

              <el-input v-else-if="item.propName == 'MaintainPersonName'" class="_table_input" v-model="scope.row.MaintainPersonName" @change="changeNotes()"></el-input>

              <el-input v-else-if="item.propName == 'MaintainPersonPhone'" class="_table_input" v-model="scope.row.MaintainPersonPhone" @change="changeNotes()"></el-input>
              <el-date-picker v-else-if="item.propName == 'MaintainExpirationTime'" v-model="scope.row.MaintainExpirationTime" @change="changeNotes()" type="datetime"
                class="_table_input" :placeholder="$t('AssetReceivedAdd.sel')">
              </el-date-picker>

              <el-input v-else-if="item.propName == 'MaintainRemark'" class="_table_input" v-model="scope.row.MaintainRemark" @change="changeNotes()"></el-input>
              <el-input v-else-if="item.propName == 'ReceivedRemark'" class="_table_input" v-model="scope.row.ReceivedRemark" @change="changeNotes()"></el-input>
              <span v-else>
                {{ scope.row[item.propName] }}
              </span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" :label="$t('AssetReceived.operate')" width="100px" align="center">
            <template slot-scope="scope">
              <div class="_operate">
                <el-link type="danger" @click.stop="delgoods(scope.row)">{{ $t('AssetReceived.delete')
                }}</el-link>
              </div>
            </template>
          </el-table-column>
          <div slot="empty" class="empty">
            <img src="@/assets/img/empty.png" />
            <span class="txt">{{ $t('BasicIndex.emptyData') }}</span>
          </div>
        </el-table>
      </div>
    </div>
    <div slot="footer" class="_footer">
      <el-button class="button_info" @click="close" style="margin-right: 7px;">{{ $t('AssetReceivedAdd.quit')
      }}</el-button><!--取消-->
      <el-button class="button_primary" @click="operate()"> {{ $t('AssetReceivedAdd.query') }} </el-button><!--确定-->
    </div>
    <!-- 所属公司组件 -->
    <SelectDept v-if="BelongCDeptType" :type="BelongCDeptType" @close="BelongCDeptType = false" @addMember="addBelongCDept" :ISCompany="1" :DataList="BelongCDeptList"></SelectDept>
    <!-- 领用人组件 -->
    <AdminUser v-if="type1" :type="type1" @close="type1 = false" @addMember="AddInKuUser" :DataList="DataList">
    </AdminUser>
    <!-- 货品组件 -->
    <CategoryList v-if="huopinType" :selectedTable="true" :type="huopinType" @closed="huopinType = false" @addHuopin="addHuopin" :DataList="HuoPinList">
    </CategoryList>
    <!-- 使用人 -->
    <AdminUser v-if="type" :type="type" @close="type = false" @addMember="addMember" :DataList="UserList">
    </AdminUser>
    <!-- 管理人 -->
    <AdminUser v-if="type2" :type="type2" @close="type2 = false" @addMember="addMember2" :DataList="AdminList">
    </AdminUser>
    <DragTable v-if="TableOk" TableName="AssetReceivedAdd" :show="TableOk" @message="Message_">
    </DragTable>
    <!-- 所属公司组件 -->
    <SelectDept v-if="BelongCDeptOpen" :type="BelongCDeptOpen" @close="BelongCDeptOpen = false" @addMember="OpertionCDept" :ISCompany="1" :DataList="BelongCDeptData"></SelectDept>
    <!-- 部门组件 -->
    <SelectDept v-if="DeptType" :type="DeptType" @close="DeptType = false" @addMember="addDept" :DataList="UseDeptList">
    </SelectDept>
    <!-- 分类组件 -->
    <SelectType v-if="TypeOpen" :type="TypeOpen" @close="TypeOpen = false" @addMember="addType" :DataList="TypeList">
    </SelectType>
    <!-- 区域组件 -->
    <SelectRegion v-if="RegionType" :type="RegionType" @close="RegionType = false" @addMember="addRegion" :DataList="RegionList"></SelectRegion>
  </div>
</template>
<script>
import draft from '@/mixins/draft';
import CategoryList from "@/components/CategoryDataList.vue";
import {
  successTips,
  AddAssetReceived,
  GetAssetHuoPin,
  GetAssetTypeTree,
  GetAssetDistrictTree,
  GetBasicDeptTree,
  LoadBasicUserColumn,
  SetBasicUserColumnWidth,
  GetGoodsSource,
  GetReceivedStatus,
  UpdateAssetReceived,
  GetBasicDeptOnlyDeptTree,
  msgTips
} from "@/api/user";
export default {
  mixins: [draft],
  components: {
    CategoryList
  },
  props: {
    Title: {
      type: String
    },
    show: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    state: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    },
    OperateType: {
      type: String
    },
    InKuOpertion: {
      type: Object
    },
    Notes: {
      type: Array,
      default: () => {
        return []
      }
    },
    dept: {
      type: Array,
      default: () => {
        return []
      }
    },
    deptNote: {
      type: Array
    }
  },
  data() {
    return {
      AssetReceived: {
        JobCDeptID: '',
        Operator: '',
        Supplier: '',
        GoodsSource: '',
        BelongCDeptID: '',
        ReceivedUserID: '',
        ReceivedUserName: '',
        ReceivedRemark: '',
        DetailList: '',
        ReceivedTime: new Date(),
        BelongCDeptName: ''
      },
      rules: {
        ReceivedUserName: [
          { required: true, message: this.$t('AssetReceivedAdd.tips1'), trigger: 'blur' }
        ],
        BelongCDeptName: [
          { required: true, message: this.$t('AssetReceivedAdd.tips2'), trigger: 'blur' }
        ],
        GoodsSource: [
          { required: true, message: this.$t('AssetReceivedAdd.tips3'), trigger: 'blur' }
        ]
      },
      AddTable: [],
      RegionList: [],
      UserList: [],
      AdminList: [],
      TypeID: [],
      tableData: [],
      DistrictID: [],
      UseCDeptID: [],
      JobCDeptID: [],
      UseDeptList: [],
      BelongCDeptID: [],
      GoodsSource: [],
      DataList: [],
      InKuNotes: [],
      UseDeptID: [],
      UseDeptIDNotes: [],
      BelongCDeptData: [],
      CopyList: [],
      BelongCDeptList: [],
      TypeList: [],
      obj: {
        ManageUserName: '',
        GoodsID: '',
        CompanyID: '',
        TypeID: '',
        DistrictID: '',
        HuoPinID: '',
        GoodsStatus: 1,
        HuoPinName: '',
        GoodsCode: '',
        GoodsName: '',
        GoodsModels: '',
        GoodsRFIDEPC: '',
        GoodsSourceName: '',
        GoodsImage: '',
        CalcUnit: '',
        SerialNO: '',
        GoodsSource: '',
        BelongCDeptID: '',
        BelongCDeptName: '',
        UserManageID: '',
        UserManageName: '',
        ServiceLife: '',
        LifeISAlarm: '',
        PutFactAddress: '',
        EXPWarning: '',
        EXPWarningISAlarm: '',
        EXPAlarm: '',
        EXPAlarmISAlarm: '',
        DateOfPurchase: new Date(),
        UseCDeptID: '',
        UseCDeptName: '',
        UseDeptID: '',
        UseDeptName: '',
        UseDistrictID: '',
        UseDistrictName: '',
        UsePutFactAddress: '',
        UseUserID: '',
        GoodsAdnexa: '',
        UseUserName: '',
        UseOperator: '',
        UseTime: '',
        BorrowUserID: '',
        BorrowUserName: '',
        BorrowOperator: '',
        BorrowTime: new Date(),
        ReturnOperator: '',
        PlanReturnTime: new Date(),
        ReturnTime: new Date(),
        BorrowISAlarm: '',
        BorrowTipsWarning: '',
        BorrowTipsISAlarm: '',
        CWAssetEntry: '',
        CWAssetType: '',
        TaxAmount: '',
        AmountOfTax: '',
        OriginalValue: '',
        TotalDepreciation: '',
        CurrentValue: '',
        CWServiceLife: '',
        AssetEntryTime: '',
        Supplier: '',
        SupplierContactUser: '',
        SupplierContactPhone: '',
        MaintainPersonName: '',
        MaintainPersonPhone: '',
        MaintainExpirationTime: new Date(),
        MaintainAdnexa: '',
        MaintainRemark: '',
        ZorFAsset: '',
        ZhuGoodsCode: '',
        GoodsRemark: '',
        DateOfProduction: '',
        ExpirationTime: '',
        UpdateTime: '',
        CreateTime: ''
      },
      TableOk: false,
      DeptType: false,
      BelongCDeptOpen: false,
      RegionType: false,
      TypeOpen: false,
      showstate: false,
      loading: false,
      type: false,
      type1: false,
      type2: false,
      BelongCDeptType: false,
      huopinType: false,
      goodsChecked: false,
      typeDisable: false,
      index: '',
      itemKey: '',
      draftTitle: ['AssetReceived']
    }
  },
  watch: {
    deptNote: {
      handler(val) {
        if (this.OperateType == 'update') {
          this.UseDeptIDNotes = val;
        }
      },
      immediate: true
    },
    dept: {
      handler(val) {
        if (this.OperateType == 'update') {
          this.UseDeptID = val;
        }
      },
      immediate: true
    },
  },
  mounted() {
    this.AssetReceived.Operator = this.$store.state.userinfo.UserName;
    this.showstate = this.show;
    this.getTableColumn();
    this.getTree();
    if (this.state) {
      let data = JSON.parse(window.localStorage.getItem(this.draftTitle[this.draftIndex]));
      if (data) {
        if (data.TypeID != 0) {
          this.typeDisable = true;
        }
        if (data.UseCDeptID != null) {
          this.SelectDeptClick({ id: data.UseCDeptID })
        }
        this[this.draftTitle[this.draftIndex]] = JSON.parse(JSON.stringify(data));
        this.InKuNotes = JSON.parse(JSON.stringify(this.Notes));
        this.AssetReceived = JSON.parse(JSON.stringify(this.detail));
      }
    }
    if (this.OperateType == 'update') {
      console.log(this.Notes);
      this.InKuNotes = JSON.parse(JSON.stringify(this.Notes));
      this.AssetReceived = JSON.parse(JSON.stringify(this.detail));
    }
  },
  methods: {
    // 表头拖动事件
    headerDragend(newWidth, data, e) {
      data 
      if (e.property != null) {
        SetBasicUserColumnWidth({ TableKey: 'AssetReceivedAdd', ColumnKey: e.property, Width: newWidth }).then(res => {
          if (res.Code == 0) {
            res
          }
        })
      } 
    },
    //刷新
    Message_(e) {
      if (e == 0) {
        let data = this.InKuNotes;
        this.InKuNotes = data;
        this.getTableColumn();
        this.itemKey = Math.random();
      }
      this.TableOk = false;
    },
    // 表单加载
    getTableColumn() {
      LoadBasicUserColumn({ TableKey: 'AssetReceivedAdd' }).then(res => {
        if (res.Code == 0) {
          this.AddTable = res.Data;
        }
      })
    },
    //所属公司点击事件
    CDeptClick(data) {
      if (data.BelongCDeptName == "") {
        data.BelongCDeptID = "";
      }
      this.BelongCDeptList = [];
      if (data) {
        this.BelongCDeptType = true;
        this.BelongCDeptList.push(data.BelongCDeptID);
      }
    },
    //入库人点击事件
    InkuUserClick(data) {
      this.DataList = [];
      if (data) {
        this.type1 = true;
        this.DataList.push(data.ReceivedUserID);
      }
    },
    getTree() {
      //所属公司渲染
      GetBasicDeptTree({ OnlyCompany: 1 }).then((res) => {
        if (res.Code == 0) {
          this.BelongCDeptID = res.Data;
          this.UseCDeptID = res.Data;
          this.JobCDeptID = res.Data;
        }
      });
      //入库状态渲染
      GetReceivedStatus({ OnlyCompany: 1 }).then((res) => {
        if (res.Code == 0) {
          this.ReceivedStatus = res.Data;
        }
      });
      //分类渲染
      GetAssetTypeTree().then((res) => {
        if (res.Code == 0) {
          this.TypeID = res.Data;
        }
      });
      //资产来源渲染
      GetGoodsSource().then((res) => {
        if (res.Code == 0) {
          this.GoodsSource = res.Data;
        }
      });
      //区域渲染
      GetAssetDistrictTree().then((res) => {
        if (res.Code == 0) {
          this.DistrictID = res.Data;
          this.DistrictName = res.Data.DistrictName;
        }
      });
    },
    selectStatus(e) {
      this.form.ReceivedStatusName = e.name;
    },
    //所属部门选中事件
    ClickBelongCDeptID(e) {
      this.form.BelongCDeptName = e.label;
    },
    //业务所属公司选中事件
    ClickJobCDeptID(e) {
      this.form.JobCDeptName = e.label;
    },
    //资产来源选中事件
    selectGoodsSource(e) {
      this.form.GoodsSourceName = e.name;
    },
    // 添加行
    TableAdd() {
      let obj = JSON.parse(JSON.stringify(this.obj));
      let len = this.InKuNotes.length;
      let index;
      if (len == 0) {
        index = 0
      } else {
        index = this.InKuNotes[len - 1].index;
      }
      for (let i = index + 1; i < parseInt(2 + index); i++) {
        this.InKuNotes.push({ index: i, ...obj });
      }
      this.UseDeptIDNotes.push([]);
      window.localStorage.setItem('AssetInKuNotes', JSON.stringify(this.InKuNotes));
    },
    //添加复制行
    CopyTableAdd() {
      if (this.CopyList.length > 10) {
        msgTips(this.$t('AssetReceivedAdd.tips4'));
        return;
      }
      let obj = JSON.parse(JSON.stringify(this.CopyList));
      let arr = []
      this.indexCount = 0;
      for (let j = 0; j < obj.length; j++) {
        obj[j].GoodsRFIDEPC = "";
        this.UseDeptIDNotes.push(this.UseDeptIDNotes[obj[j].index - 1]);
        this.indexCount = this.InKuNotes.length + j;
        obj[j].index = this.indexCount + 1;
        arr.push(obj[j]);
      }
      this.InKuNotes = [...this.InKuNotes, ...arr];
      window.localStorage.setItem('AssetInKuNotes', JSON.stringify(this.InKuNotes));
    },

    //下拉框搜索
    selectDeptClick(e, id) {
      //使用部门渲染
      GetBasicDeptOnlyDeptTree({ DeptID: id }).then((res) => {
        if (res.Code == 0) {
          this.$nextTick(() => {
            this.InKuNotes[e.index - 1].UseDeptID = '';
            this.InKuNotes[e.index - 1].UseDeptName = '';
            this.UseDeptIDNotes.splice(e.index - 1, 1, res.Data);
          })
        }
      });
    },
    // 选择入库人 ID + 名称
    AddInKuUser(e) {
      this.DataList = [];
      this.AssetReceived.ReceivedUserID = e.UserID;
      this.AssetReceived.ReceivedUserName = e.UserName;
      this.changeDraft(e.UserID, 'ReceivedUserID', 0);
      this.changeDraft(e.UserName, 'ReceivedUserName', 0);
      this.DataList.push(e.UserID);
      this.AssetReceived.BelongCDeptID = e.CDeptID;
      this.changeDraft(e.CDeptID, 'BelongCDeptID', 0);
      this.$refs['form'].clearValidate(['ReceivedUserName']);
      this.type1 = false;
    },
    // 选择货品
    addHuopin(e) {
      this.typeDisable = true;
      //查询货品信息  
      if (this.OperateType == 'update') {
        GetAssetHuoPin({ HuoPinID: e[0].HuoPinID }).then((res) => {
          if (res.Code == 0) {
            let list = JSON.parse(JSON.stringify(this.InKuNotes));
            list[this.index - 1].HuoPinName = res.Data.HuoPinName;
            list[this.index - 1].GoodsImage = res.Data.HuoPinImage;
            list[this.index - 1].HuoPinID = e[0].HuoPinID;
            list[this.index - 1].GoodsModels = e[0].HuoPinModels;
            list[this.index - 1].OriginalValue = e[0].DefaultPrice;
            list[this.index - 1].CalcUnit = e[0].CalcUnit;
            list[this.index - 1].GoodsName = e[0].HuoPinName;
            list[this.index - 1].TypeID = e[0].TypeID;
            list[this.index - 1].TypeName = e[0].TypeName;
            window.localStorage.setItem('AssetInKuNotes', JSON.stringify(list));
            this.huopinType = false;
            this.InKuNotes = list;
          }
        });
      } else {
        GetAssetHuoPin({ HuoPinID: e[0].HuoPinID }).then((res) => {
          if (res.Code == 0) {
            let list = JSON.parse(JSON.stringify(this.InKuNotes));
            list[this.index].GoodsImage = res.Data.HuoPinImage;
            list[this.index].HuoPinName = res.Data.HuoPinName;
            list[this.index].HuoPinID = e[0].HuoPinID;
            list[this.index].GoodsModels = e[0].HuoPinModels;
            list[this.index].OriginalValue = e[0].DefaultPrice;
            list[this.index].CalcUnit = e[0].CalcUnit;
            list[this.index].GoodsName = e[0].HuoPinName;
            list[this.index].TypeID = e[0].TypeID;
            list[this.index].TypeName = e[0].TypeName;
            window.localStorage.setItem('AssetInKuNotes', JSON.stringify(list));
            this.huopinType = false;
            this.InKuNotes = list;
          }
        });
      }
    },
    //所属公司组件事件
    addBelongCDept(e) {
      this.AssetReceived.BelongCDeptID = e.DeptID;
      this.AssetReceived.BelongCDeptName = e.DeptName;
      this.changeDraft(e.DeptID, 'BelongCDeptID', 0);
      this.changeDraft(e.DeptName, 'BelongCDeptName', 0);
      this.$refs['form'].clearValidate(['BelongCDeptName']);
      this.BelongCDeptType = false;
    },
    //选择使用人
    addMember(e) {
      this.UserList = [];
      if (e) {
        this.InKuNotes[this.index].UseUserID = e.UserID;
        this.InKuNotes[this.index].UseUserName = e.UserName;
        this.UserList.push(e.UserID);
        this.changeDraft(e.UserID, 'UseUserID', 0);
        this.changeDraft(e.UserName, 'UseUserName', 0);
      } else {
        this.InKuNotes[this.index].UseUserID = "";
        this.InKuNotes[this.index].UseUserName = "";
        this.changeDraft(0, 'UseUserID', 0);
        this.changeDraft('', 'UseUserName', 0);
        this.UserList.push(e.UserID);
      }
      this.$refs['form'].clearValidate(['ReceivedUserName']);
      window.localStorage.setItem('AssetInKuNotes', JSON.stringify(this.InKuNotes));
      this.InKuNotes = JSON.parse(JSON.stringify(this.InKuNotes));
      this.type = false;
    },
    //选择管理人
    addMember2(e) {
      this.AdminList = [];
      if (e) {
        this.InKuNotes[this.index].ManageUserID = e.UserID;
        this.InKuNotes[this.index].ManageUserName = e.UserName;
        this.changeDraft(e.UserID, 'ManageUserID', 0);
        this.changeDraft(e.UserName, 'ManageUserName', 0);
        this.AdminList.push(e.UserID);
      } else {
        this.InKuNotes[this.index].ManageUserID = "";
        this.InKuNotes[this.index].ManageUserName = "";
        this.changeDraft(0, 'ManageUserID', 0);
        this.changeDraft('', 'ManageUserName', 0);
        this.AdminList.push(e.UserID);
      }
      this.$refs['form'].clearValidate(['ReceivedUserName']);
      window.localStorage.setItem('AssetInKuNotes', JSON.stringify(this.InKuNotes));
      this.InKuNotes = JSON.parse(JSON.stringify(this.InKuNotes));
      this.type2 = false;
    },
    //选择分类
    addType(e) {
      this.TypeList = [];
      if (e) {
        this.InKuNotes[this.index].TypeID = e.TypeID;
        this.InKuNotes[this.index].TypeName = e.TypeName;
        this.changeDraft(e.TypeID, 'TypeID', 0);
        this.changeDraft(e.TypeName, 'TypeName', 0);
        this.TypeList.push(e.TypeID);
      } else {
        this.InKuNotes[this.index].TypeID = "";
        this.InKuNotes[this.index].TypeName = "";
        this.changeDraft(0, 'TypeID', 0);
        this.changeDraft('', 'TypeName', 0);
        this.TypeList.push(e.TypeID);
      }
      this.$refs['form'].clearValidate(['TypeName']);
      window.localStorage.setItem('AssetInKuNotes', JSON.stringify(this.InKuNotes));
      this.InKuNotes = JSON.parse(JSON.stringify(this.InKuNotes));
      this.TypeOpen = false;
    },
    //选择部门
    addDept(e) {
      this.UseDeptList = [];
      if (e) {
        this.InKuNotes[this.index].UseDeptID = e.DeptID;
        this.InKuNotes[this.index].UseDeptName = e.DeptName;
        this.changeDraft(e.DeptID, 'UseDeptID', 0);
        this.changeDraft(e.DeptName, 'UseDeptName', 0);
        this.UseDeptList.push(e.DeptID);
      } else {
        this.InKuNotes[this.index].DeptID = "";
        this.InKuNotes[this.index].DeptName = "";
        this.changeDraft(0, 'UseDeptID', 0);
        this.changeDraft('', 'UseDeptName', 0);
        this.UseDeptList.push(e.DeptID);
      }
      this.$refs['form'].clearValidate(['UseDeptName']);
      window.localStorage.setItem('AssetInKuNotes', JSON.stringify(this.InKuNotes));
      this.InKuNotes = JSON.parse(JSON.stringify(this.InKuNotes));
      this.DeptType = false;
    },
    //选择区域
    addRegion(e) {
      this.RegionList = [];
      if (e) {
        this.InKuNotes[this.index].DistrictID = e.DistrictID;
        this.InKuNotes[this.index].DistrictName = e.DistrictName;
        this.changeDraft(e.DistrictID, 'DistrictID', 0);
        this.changeDraft(e.DistrictName, 'DistrictName', 0);
        this.RegionList.push(e.DistrictID);
      } else {
        this.InKuNotes[this.index].DistrictID = "";
        this.InKuNotes[this.index].DistrictName = "";
        this.changeDraft(0, 'DistrictID', 0);
        this.changeDraft('', 'DistrictName', 0);
        this.RegionList.push(e.DistrictID);
      }
      this.$refs['form'].clearValidate(['DistrictName']);
      window.localStorage.setItem('AssetInKuNotes', JSON.stringify(this.InKuNotes));
      this.InKuNotes = JSON.parse(JSON.stringify(this.InKuNotes));
      this.RegionType = false;
    },
    //所属公司
    OpertionCDept(e) {
      this.BelongCDeptData = [];
      if (e) {
        this.InKuNotes[this.index].BelongCDeptID = e.DeptID;
        this.InKuNotes[this.index].BelongCDeptName = e.DeptName;
        this.changeDraft(e.DeptID, 'BelongCDeptID', 0);
        this.changeDraft(e.DeptName, 'BelongCDeptName', 0);
        this.BelongCDeptData.push(e.BelongCDeptID);
      } else {
        this.InKuNotes[this.index].BelongCDeptID = "";
        this.InKuNotes[this.index].BelongCDeptName = "";
        this.changeDraft(0, 'BelongCDeptID', 0);
        this.changeDraft('', 'BelongCDeptName', 0);
        this.BelongCDeptData.push(e.DeptID);
      }
      this.$refs['form'].clearValidate(['BelongCDeptName']);
      window.localStorage.setItem('AssetInKuNotes', JSON.stringify(this.InKuNotes));
      this.InKuNotes = JSON.parse(JSON.stringify(this.InKuNotes));
      this.BelongCDeptOpen = false;
    },
    // dialog中的货品列表选中数据
    handleSelectionChange1(val) {
      if (val.length == 1) {
        this.goodsChecked = false;
        this.GoodsID = val[0].index;
      }
      if (val.length != 0) {
        this.CopyList = val;
      }
    },
    // 操作页面表单选中
    handleRowClick1(row) {
      this.$refs.rules.toggleRowSelection(row);
    },
    // 删除资产
    delgoods(e) {
      let list = JSON.parse(JSON.stringify(this.InKuNotes));
      this.InKuNotes = list.filter(item => item.index != e.index);
      this.resetNotes();
    },
    DelGoods() {
      let list = JSON.parse(JSON.stringify(this.InKuNotes));
      this.CopyList.forEach(item => {
        list = list.filter(e => e.index != item.index);
        this.UseDeptIDNotes.splice(parseInt(item.index - 1), 1);
      })
      this.InKuNotes = list;
      this.resetNotes();
      window.localStorage.setItem('AssetInKuNotes', JSON.stringify(this.InKuNotes));
    },
    resetNotes() {
      for (let i = 0; i < this.InKuNotes.length; i++) {
        this.InKuNotes[i].index = parseInt(i + 1);
      }
    },
    changeNotes() {
      window.localStorage.setItem('AssetInKuNotes', JSON.stringify(this.InKuNotes));
      this.InKuNotes = JSON.parse(JSON.stringify(this.InKuNotes));
    },
    //使用人点击事件
    userClick(data) {
      this.UserList = [];
      if (data) {
        if (this.Title == this.$t('AssetReceived.add')) {
          this.type = true;
          this.index = data.index - 1;
        }
        if (this.Title == this.$t('AssetReceived.update')) {
          this.type = true;
          this.index = data.index;
        }
        this.UserList.push(data.UseUserID);
      }
    },
    //管理人点击事件
    adminClick(data) {
      this.AdminList = [];
      if (data) {
        if (this.Title == this.$t('AssetReceived.add')) {
          this.type2 = true;
          this.index = data.index - 1;
        }
        if (this.Title == this.$t('AssetReceived.update')) {
          this.type2 = true;
          this.index = data.index;
        }
        this.AdminList.push(data.ManageUserID);
      }
    },
    //使用人，管理人点击事件
    huoPinClick(data) {
      this.HuoPinList = [];
      if (data) {
        if (this.Title == this.$t('AssetReceived.add')) {
          this.huopinType = true;
          this.index = data.index - 1;
        }
        if (this.Title == this.$t('AssetReceived.update')) {
          this.huopinType = true;
          this.index = data.index;
        }
        this.HuoPinList.push(data.HuoPinID);
      }
    },
    //分类点击事件
    typeClick(data) {
      this.TypeList = [];
      if (data) {
        if (this.Title == this.$t('AssetReceived.add')) {
          this.TypeOpen = true;
          this.index = data.index - 1;
        }
        if (this.Title == this.$t('AssetReceived.update')) {
          this.TypeOpen = true;
          this.index = data.index;
        }
        this.TypeList.push(data.TypeID);
      }
    },
    //区域点击事件
    RegionClick(data) {
      this.RegionList = [];
      if (data) {
        if (this.Title == this.$t('AssetReceived.add')) {
          this.RegionType = true;
          this.index = data.index - 1;
        }
        if (this.Title == this.$t('AssetReceived.update')) {
          this.RegionType = true;
          this.index = data.index;
        }
        this.RegionList.push(data.DistrictID);
      }
    },
    //所属公司点击事件
    OperCDeptClick(data) {
      this.BelongCDeptData = [];
      if (data) {
        if (this.Title == this.$t('AssetReceived.add')) {
          this.BelongCDeptOpen = true;
          this.index = data.index - 1;
        }
        if (this.Title == this.$t('AssetReceived.update')) {
          this.BelongCDeptOpen = true;
          this.index = data.index;
        }
        this.BelongCDeptData.push(data.BelongCDeptID);
      }
    },
    //使用部门点击事件
    UserDeptClick(data) {
      this.UseDeptList = [];
      if (data) {
        if (this.Title == this.$t('AssetReceived.add')) {
          this.DeptType = true;
          this.index = data.index - 1;
        }
        if (this.Title == this.$t('AssetReceived.update')) {
          this.DeptType = true;
          this.index = data.index;
        }
        this.UseDeptList.push(data.UseDeptID);
      }
    },

    // 操作
    operate() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.AssetReceived.ReceivedUserID == '') {
            msgTips(this.$t('AssetReceivedAdd.tips5'));
            return;
          }
          if (this.InKuNotes.length == 0) {
            msgTips(this.$t('AssetReceivedAdd.tips6'));
            return;
          }
          if (this.OperateType == 'add') {
            this.AssetReceived.DetailList = JSON.stringify(this.InKuNotes);
            AddAssetReceived(this.AssetReceived).then(res => {
              if (res.Code == 0) {
                successTips(this.$t('AssetReceivedAdd.tips7'));
                window.localStorage.setItem('AssetInKuNotes', null);
                window.localStorage.setItem(this.draftTitle[this.draftIndex], null);
                this.$emit('success');
                return;
              }
            });
          } else if (this.OperateType == 'update') {
            this.AssetReceived.DetailList = JSON.stringify(this.InKuNotes);
            UpdateAssetReceived(this.AssetReceived).then(res => {
              if (res.Code == 0) {
                successTips(res.Message);
                window.localStorage.setItem('AssetInKuNotes', null);
                window.localStorage.setItem(this.draftTitle[this.draftIndex], null);
                this.$emit('success');
                return;
              }
            });
          }
        }
      });
    },
    close() {
      this.$emit('close');
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-form-item__error {
  padding-top: 5px !important;
}
._dialog ._footer,
._info ._footer {
  margin-top: -21px;
}
.el-divider--horizontal {
  margin: 10px 0;
}
.el-dialog__footer {
  margin-top: 20px !important;
}

.el-table .el-table__cell .el-input,
.el-table .el-table__cell .el-input__inner {
  width: 100% !important;
}

.SearchName {
  margin-right: 0% !important;
}

.el-input {
  width: 75% !important;
}

.el-select {
  width: 75% !important;
}

.textarea {
  width: 75% !important;
}

.textareaName {
  width: 88.5% !important;
}

.el-icon-plus {
  border: 1px dashed;
  // background-color: #8c939d;
}

._table_input {
  /deep/.el-input__inner {
    text-align: center;
  }
}

._filter {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 10px 0;
  justify-content: space-between;
  span {
    margin-right: 20px;
    font-size: 14px;
    cursor: pointer;

    .el-icon-refresh {
      margin-left: 10px;
      font-size: 16px;
    }
  }
}
</style>