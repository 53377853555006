import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { msgTips } from './user';
import { getCookie } from './cookie.js'
let baseURL = '/webapi';
// let baseURL = 'http://localhost:62498';

/* 自定义axios实例 */
let request = axios.create({
    // "baseURL":'http://192.168.0.108:10003',
    // "baseURL": 'http://47.112.208.53',
    // "baseURL": 'http://localhost:8888',
    "baseURL": baseURL,
    // "withCredentials":false,
    timeout: 30000
});

let request1 = axios.create({
    baseURL,
    timeout: 30000
})

//添加请求拦截器
request.interceptors.request.use(config => {
    let token = getCookie('token');
    // console.log(token)
    config.headers = {
        "Content-Type": "application/octet-stream",
        "Access-Control-Allow-Origin": "*",
        "Language": localStorage.getItem("locale") || "zh_CN"
    }
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config
})
// 上传文件添加请求拦截器
request1.interceptors.request.use(config => {
    let token = getCookie('token');
    // console.log(token)
    config.headers = {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Language": localStorage.getItem("locale") || "zh_CN"
    }
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config
})

//添加响应拦截器
request.interceptors.response.use(response => {
    if (response.status == 200) {
        if (response.data) {
            let data = response.data.response || '';
            if (data.Code == -1) {
                router.push('/');
            }
            if (data.Code == 1) {
                msgTips(data.Message);
            }
        }
    }
    return response;
})

export function fetchPost(url, data) {
    return new Promise((resolve, reject) => {
        store.commit('SET_LOADING', true)
        request.post(url, data).then(res => {
            store.commit('SET_LOADING', false)
            resolve(res.data.response)
        }).catch(err => {
            store.commit('SET_LOADING', false)
            reject(err)
        })
    })
}

export function fetchGet1(url, params) {
    return new Promise((resolve, reject) => {
        request.get(url, { params }).then(res => {
            resolve(res.data.response)
        }).catch(err => {
            reject(err)
        })
    })
}

export function fetchGet(url, params) {
    return new Promise((resolve, reject) => {
        store.commit('SET_LOADING', true)
        request.get(url, { params }).then(res => {
            store.commit('SET_LOADING', false)
            resolve(res.data.response)
        }).catch(err => {
            store.commit('SET_LOADING', false)
            reject(err)
        })
    })
}

export function fetchPost1(url, data) {
    return new Promise((resolve, reject) => {
        request1.post(url, data).then(res => {
            resolve(res.data.response)
        }).catch(err => {
            reject(err)
        })
    })
}
