import { Notification } from 'element-ui';
import { fetchGet, fetchPost, fetchPost1, fetchGet1 } from './request' //fetchPost 
import i18n from '@/lang';
//转时间格式
export function updateTime(date) {
    if (date) {
        return new Date(parseInt(date.slice(6, 19))).toLocaleString('chinese', { hour12: false }).replaceAll('/', '-');
    }
    return ''
}
//转时间格式
export function UpdateTime(date) {
    if (date) {
        return new Date(parseInt(date.slice(6, 19))).toLocaleString('chinese', { hour12: false });
    }
    return ''
}

 

// 加载当前表单配置数据
export function LoadBasicUserColumn(data) {
    return fetchGet('/BasicUserColumn/LoadBasicUserColumn', data)
}

// 获取RFID编码
export function ProductRFID(data) {
    return fetchGet('/Background/ProductRFID', data)
}

// 代办事项
export function GetBasicSchedulePage(data) {
    return fetchPost('/BasicSchedule/GetBasicSchedulePage', data)
}

// 获取待办事项数量
export function GetBasicScheduleCount() {
    return fetchGet1('/BasicSchedule/GetBasicScheduleCount')
}

// 获取待办事项数量明细
export function GetBasicScheduleDetailCount() {
    return fetchGet1('/BasicSchedule/GetBasicScheduleDetailCount')
}

// 处理代办事项
export function DealBasicSchedule(data) {
    return fetchGet('/BasicSchedule/DealBasicSchedule', data)
}
// 获取代办事项详情
export function GetBasicScheduleDetail(data) {
    return fetchGet1('/BasicSchedule/GetBasicSchedule', data)
}

// 查询审批配置
export function GetBasicSchduleSystemSetting() {
    return fetchGet('/BasicSchedule/GetBasicSchduleSystemSetting')
}

// 设置审批配置
export function SetBasicSchduleSystemSetting(data) {
    return fetchGet('/BasicSchedule/SetBasicSchduleSystemSetting', data)
}

//系统消息
export function GetBasicMessagePage(data) {
    return fetchPost('/BasicMessage/GetBasicMessagePage', data)
}

//  获取系统消息数量
export function GetBasicMessageCount() {
    return fetchGet1('/BasicMessage/GetBasicMessageCount')
}

// 标记消息为已读
export function DealBasicMessage(data) {
    return fetchGet('/BasicMessage/ReadBasicMessage', data)
}

// 获取系统消息数量明细 
export function GetBasicMessageDetailCount(data) {
    return fetchGet('/BasicMessage/GetBasicMessageDetailCount', data)
}

// 获取消息表格
export function GetBasicUserColumn(data) {
    return fetchGet('/BasicUserColumn/GetBasicUserColumn', data)
}
// 获取消息表格
export function SetBasicUserColumn(data) {
    return fetchPost('/BasicUserColumn/SetBasicUserColumn', data)
}
// 拖拽表格
export function SetBasicUserColumnWidth(data) {
    return fetchGet('/BasicUserColumn/SetBasicUserColumnWidth', data)
}
 
 

// 注销企业
export function RegistOver(data) {
    return fetchGet('/Regist/RegistOver', data)
}
// 获取当前时间和七天前的时间
export function getTimeRange() {
    return fetchGet('/Background/GetStartEndTime')
}
// 获取物资存在的状态
export function getGoodsStatus() {
    return fetchGet('/Select/GetGoodsStatus')
}
// 获取入库单存在的状态
export function getInKuDanStatus() {
    return fetchGet('/Select/GetInKuDanStatus')
}
// 获取出库单存在的状态
export function getOutKuDanStatus() {
    return fetchGet('/Select/GetOutKuDanStatus')
}
// 获取出库单存在的状态
export function getAllotDanStatus() {
    return fetchGet('/Select/GetAllotDanStatus')
}
// 设备绑定系统枚举
export function GetRFIDDeviceDataUse() {
    return fetchGet('/Select/GetRFIDDeviceDataUse')
}
// 获取盘点单存在的状态
export function getPanDianDStatus() {
    return fetchGet('/Select/GetPanDianDStatus')
}
// 获取盘点单结果的状态
export function getPanDianResult() {
    return fetchGet('/Select/GetPanDianResult')
}
// 获取资产的状态
export function GetGoodsStatus() {
    return fetchGet('/Select/GetGoodsStatus')
}
/* 成功信息提示 */
export function successTips(msg) {
    Notification({
        title: i18n.t('BasicIndex.KindReminder'),
        message: msg,
        type: "success",
        customClass: 'customClassNotify'
    })

}
/* 弹框提示 */
export function msgTips(msg) {
    Notification.error({
        title: i18n.t('BasicIndex.KindReminder'),
        message: msg,
        customClass: 'customClassNotify'
    });

}

// 获取App最新版本
export function GetAPPVersion(data) {
    return fetchGet('/Background/GetNewAPPVersion', data)
}

//获取发卡子系统版本
export function GetPrintVersion() {
    return fetchGet('/Background/GetPrintVersion')
}
// //查询时间
// export function getDateRange(dateNow,intervalDays,bolPastTime) {
//     let oneDayTime = 24 * 60 * 60 * 1000;
//     let list = [];
//     let lastDay;

//     if(bolPastTime == true){
//         lastDay = new Date(dateNow.getTime() - intervalDays * oneDayTime);
//         list.push(this.formateDate(lastDay));
//         list.push(this.formateDate(dateNow));
//     }else{
//         lastDay = new Date(dateNow.getTime() + intervalDays * oneDayTime);
//         list.push(this.formateDate(dateNow));
//         list.push(this.formateDate(lastDay));
//     }
//     return list;
// }
// export function formateDate(time) {
//     let year = time.getFullYear()
//     let month = time.getMonth() + 1
//     let day = time.getDate()

//     if (month < 10) {
//       month = '0' + month
//     }

//     if (day < 10) {
//       day = '0' + day
//     }
//     return year + '-' + month + '-' + day + ''
// }

// 登录
export function UserLogin(data) {
    return fetchGet('/Login/UserLogin', data);
}
// 退出登录
export function Logout() {
    return fetchGet('/Login/UserLoginOut');
}
///修改密码
export function UpdatePassword(data) {
    return fetchPost('/BasicUser/UpdateLoginPassword', data)
}


// 登录发送验证码
export function SendCode(data) {
    return fetchGet('/Login/SendVerifyCodeByLogin', data);
}

// 验证码登录验证
export function CodeLogin(data) {
    return fetchGet('/Login/UserLoginByVerifyCode', data);
}

// 注册发送验证码
export function SendRegisterCode(data) {
    return fetchGet('/Regist/SendVerifyCodeByRegist', data);
}

// 验证码注册验证
export function CodeRegister(data) {
    return fetchGet('/Regist/CheckVerifyCodeByRegist', data);
}

// 发送找回密码验证码
export function SendVerifyCodeByResetPass(data) {
    return fetchGet('/Regist/SendVerifyCodeByResetPass', data);
}
//验证找回密码验证码
export function CheckVerifyCodeByResetPass(data) {
    return fetchGet('/Regist/CheckVerifyCodeByResetPass', data);
}

// 用户企业认证
export function GetUSerCheckPost(data) {
    return fetchPost('/Regist/RegistUserCheck', data)
}

// 用户企业认证
export function AttestationPost(data) {
    return fetchPost('/Regist/RegistUser', data)
}

// 获取用户信息
export function GetUserInfo() {
    return fetchGet('/BasicUser/GetLoginUser')
}

// 获取用户信息
export function JiHuo(data) {
    return fetchGet('/Regist/JiHuo', data)
}
// 获取用户状态
export function GetUserStatus() {
    return fetchGet('/Select/GetUserStatus')
}
//绑定用户RFID
export function BindBasicUserRFID(data) {
    return fetchGet('/BasicUser/BindBasicUserRFID', data)
}
//解绑用户RFID
export function UnBindBasicUserRFID(data) {
    return fetchGet('/BasicUser/UnBindBasicUserRFID', data)
}



// 升级企业
export function UpgradeSystem(data) {
    return fetchGet('/BasicCompany/UpgradeSystem', data)
}
// 获取菜单路由
export function GetBasicMenuJson() {
    return fetchGet('/BasicMenu/GetBasicMenuJson')
}
// 获取菜单树
export function GetBasicMenuTree() {
    return fetchGet('/BasicMenu/GetBasicMenuTree')
}
export function GetBasicMenuPC() {
    return fetchPost('/BasicMenu/GetBasicMenuPCTree')
}
// 获取菜单收藏集合
export function GetBasicUserMenu() {
    return fetchGet('/BasicUserMenu/GetBasicUserMenu')
}
// 收藏菜单
export function AddBasicUserMenu(data) {
    return fetchGet('/BasicUserMenu/AddBasicUserMenu', data)
}

// 平台统计图
export function GetStorageGoodsSYTJ(data) {
    return fetchGet('/StorageGoods/GetStorageGoodsSYTJ', data)
}

// 资产平台统计图
export function GetAssetGoodsSYTJ(data) {
    return fetchGet('/AssetGoods/GetAssetGoodsSYTJ', data)
}


// 获取企业菜单
export function GetBasicCompanyMenuTree(data) {
    return fetchGet('/BasicMenu/GetBasicCompanyMenuTree', data)
}

// 启用企业菜单
export function LimitCompanyRight(data) {
    return fetchGet('/BasicMenu/LimitCompanyRight', data)
}

// 禁用企业菜单
export function UnLimitCompanyRight(data) {
    return fetchGet('/BasicMenu/UnLimitCompanyRight', data)
}

// 获取管理员菜单
export function GetBasicManageMenuTree(data) {
    return fetchGet('/BasicMenu/GetBasicManageMenuTree', data)
}

// 启用管理员菜单 MenuKey ManageID 
export function LimitManageRight(data) {
    return fetchGet('/BasicMenu/LimitManageRight', data)
}

// 禁用管理员菜单 MenuKey ManageID 
export function UnLimitManageRight(data) {
    return fetchGet('/BasicMenu/UnLimitManageRight', data)
}

// 获取系统权限菜单
export function GetMenuTree(data) {
    return fetchGet('/System/GetMenuTree', data)
}

// 获取菜单集合
export function getBasicMenuPCList(data) {
    return fetchPost('/BasicMenu/GetBasicMenuPCList', data)
}

// 修改系统菜单名称
export function UpdateRight(data) {
    return fetchGet('System/UpdateRight', data)
}

// 启用系统权限菜单 MenuKey ManageID 
export function LimitRight(data) {
    return fetchGet('/System/LimitRight', data)
}

// 禁用系统权限菜单 MenuKey ManageID 
export function UnLimitRight(data) {
    return fetchGet('/System/UnLimitRight', data)
}

// 禁用系统权限菜单 MenuKey ManageID 
export function GetCompanyList(data) {
    return fetchPost('/System/GetCompanyList', data)
}

// 获取企业菜单 CompanyID=0
export function GetCompanyMenuTree(data) {
    return fetchGet('/System/GetCompanyMenuTree', data)
}

// 启用企业菜单 MenuKey=A000&CompanyID=0
export function LimitCompanyRightSys(data) {
    return fetchGet('/System/LimitCompanyRight', data)
}

// 禁用企业菜单 MenuKey=A000&CompanyID=0
export function UnLimitCompanyRightSys(data) {
    return fetchGet('/System/UnLimitCompanyRight', data)
}

/* 表单列表配置 */
//分页获取所有表单信息
export function GetTableList(data) {
    return fetchPost('/System/GetTableList ',data)
}
//获取表信息
export function GetBasicTable(data) {
    return fetchGet('/System/GetBasicTable',data)
}
//新增表
export function AddBasicTable(data) {
    return fetchPost('/System/AddBasicTable',data)
}
//修改表
export function UpdateBasicTable(data) {
    return fetchPost('/System/UpdateBasicTable',data)
}
//删除表
export function DeleteBasicTable(data) {
    return fetchGet('/System/DeleteBasicTable',data)
}
//获取表的列信息列表
export function GetOriginalColumnList(data) {
    return fetchGet('/System/GetOriginalColumnList',data)
}
//新增表的列信息(单)
export function AddBasicOriginalColumn(data) {
    return fetchPost('/System/AddBasicOriginalColumn',data)
}
//新增表的列信息(批量)
export function BatchAddBasicOriginalColumn(data) {
    return fetchPost('/System/BatchAddBasicOriginalColumn',data)
}
//修改表的列信息(单个)
export function UpdateBasicOriginalColumn(data) {
    return fetchPost('/System/UpdateBasicOriginalColumn',data)
}
//删除列
export function DeleteBasicOriginalColumn(data) {
    return fetchGet('/System/DeleteBasicOriginalColumn',data)
}

// 获取企业信息
export function GetBasicCompany() {
    return fetchGet('/BasicCompany/GetBasicCompany')
}
// 获取行业类型
export function GetIndustryType() {
    return fetchGet('/Select/GetIndustryType')
}
// 获取人员规模
export function GetPopulationRange() {
    return fetchGet('/Select/GetPopulationRange')
}
// 修改企业信息路由
export function UpdateBasicCompany(data) {
    return fetchPost('/BasicCompany/UpdateBasicCompany', data)
}
// 获取用户类型
export function GetUserType() {
    return fetchGet('/Select/GetUserType')
}

// 获取操作类型
export function GetOperType() {
    return fetchGet('/Select/GetOperType')
}
// 获取操作对象
export function GetOperObj() {
    return fetchGet('/Select/GetOperObj')
}
// 获取用户操作应用
export function GetOperApplication() {
    return fetchGet('/Select/GetOperApplication')
}
// 获取登录日志
export function GetBasicLoginLog(data) {
    return fetchPost('/BasicLogLogin/GetBasicLoginLog', data)
}
// 获取平台日志
export function GetBasicLogPlatform(data) {
    return fetchPost('/BasicLogPlatform/GetBasicLogPlatform', data)
}
// 获取应用日志
export function GetBasicLogApplication(data) {
    return fetchPost('/BasicLogApplication/GetBasicLogApplication', data)
}



// 获取树形结构数据 
export function GetBasicDeptTree(data) {
    return fetchGet('/BasicDept/GetBasicDeptTree', data)
}
// 拖拽树形结构数据 
export function ChangeBasicDept(data) {
    return fetchGet('/BasicDept/ChangeBasicDept', data)
}
// 获取部门列表
export function GetBasicDeptSelect(data) {
    return fetchGet('/BasicDept/GetBasicDeptSelect', data)
}
// 获取企业列表
export function GetBasicDeptOnlyCompanyTree() {
    return fetchGet('/BasicDept/GetBasicDeptOnlyCompanyTree')
}
// 分页获取部门列表  
export function GetBasicDeptPage(data) {
    return fetchPost('/BasicDept/GetBasicDeptPage', data)
}
// 分页获取种类列表  
export function GetStorageCategoryPage(data) {
    return fetchPost('/StorageCategory/GetStorageCategoryPage', data)
}

// 获取组织架构树(仅部门)
export function GetBasicDeptOnlyDeptTree(data) {
    return fetchGet('/BasicDept/GetBasicDeptOnlyDeptTree', data)
}

// 根据ID获取单个组织信息  
export function GetBasicDept(data) {
    return fetchGet('/BasicDept/GetBasicDept', data)
}

// 增加组织  
export function AddBasicDept(data) {
    return fetchPost('/BasicDept/AddBasicDept', data)
}

// 修改组织  
export function UpdateBasicDept(data) {
    return fetchPost('/BasicDept/UpdateBasicDept', data)
}
// 删除组织  
export function DeleteBasicDept(data) {
    return fetchGet('/BasicDept/DeleteBasicDept', data)
}
// 禁用组织  
export function LimitBasicDept(data) {
    return fetchGet('/BasicDept/LimitBasicDept', data)
}





// 获取管理员数据 
export function GetBasicManageList() {
    return fetchGet('/BasicManage/GetBasicManageList')
}
// 获取管理员明细
export function GetBasicManageDetail(data) {
    return fetchGet('/BasicManage/GetBasicManageDetail', data)
}

// 根据ID获取单个管理员信息  
export function GetBasicManage(data) {
    return fetchGet('/BasicManage/GetBasicManage', data)
}

// 增加管理员  
export function AddBasicManage(data) {
    return fetchPost('/BasicManage/AddBasicManage', data)
}

// 修改管理员  
export function UpdateBasicManage(data) {
    return fetchPost('/BasicManage/UpdateBasicManage', data)
}
// 删除管理员
export function DeleteBasicManage(data) {
    return fetchGet('/BasicManage/DeleteBasicManage', data)
}

// 禁用管理员  
export function LimitBasicManage(data) {
    return fetchGet('/BasicManage/LimitBasicManage', data)
}

// 授权管理员  
export function AuthorityBasicManage(data) {
    return fetchPost('/BasicManage/AuthorityBasicManage', data)
}
// 绑定管理员  
export function BindBasicManage(data) {
    return fetchGet('/BasicManage/BindBasicManage', data)
}
// 解绑管理员  
export function UnBindBasicManage(data) {
    return fetchGet('/BasicManage/UnBindBasicManage', data)
}




// 分页查询用户
export function GetBasicUserPage(data) {
    return fetchPost('/BasicUser/GetBasicUserPage', data)
}
// 增加用户 
export function AddBasicUser(data) {
    return fetchPost('/BasicUser/AddBasicUser', data)
}
// 修改用户  
export function UpdateBasicUser(data) {
    return fetchPost('/BasicUser/UpdateBasicUser', data)
}
// 删除用户  
export function DeleteBasicUser(data) {
    return fetchGet('/BasicUser/DeleteBasicUser', data)
}

// 批量删除用户  
export function BatchDeleteBasicUser(data) {
    return fetchGet('/BasicUser/BatchDeleteBasicUser', data)
}

// 根据id查询用户
export function GetBasicUser(data) {
    return fetchGet('/BasicUser/GetBasicUser', data)
}
// 禁用启用
export function LimitBasicUser(data) {
    return fetchGet('/BasicUser/LimitBasicUser', data)
}


// 分页查询RFID设备
export function GetBasicRFIDDevicePage(data) {
    return fetchPost('/BasicRFIDDevice/GetBasicRFIDDevicePage', data)
}
// 增加RFID设备
export function AddBasicRFIDDevice(data) {
    return fetchPost('/BasicRFIDDevice/AddBasicRFIDDevice', data)
}
// 修改RFID设备
export function UpdateBasicRFIDDevice(data) {
    return fetchPost('/BasicRFIDDevice/UpdateBasicRFIDDevice', data)
}
// 删除用户  
export function DeleteBasicRFIDDevice(data) {
    return fetchGet('/BasicRFIDDevice/DeleteBasicRFIDDevice', data)
}
// 根据id设备
export function GetBasicRFIDDevice(data) {
    return fetchGet('/BasicRFIDDevice/GetBasicRFIDDevice', data)
}
// 禁用设备
export function LimitBasicRFIDDevice(data) {
    return fetchGet('/BasicRFIDDevice/LimitBasicRFIDDevice', data)
}
// 获取设备类型
export function GetRFIDDeviceType() {
    return fetchGet('/Select/GetRFIDDeviceType')
}
// 获取设备网络类型
export function GetRFIDDeviceNetType() {
    return fetchGet('/Select/GetRFIDDeviceNetType')
}
// 获取门禁工作类型
export function GetWorkType() {
    return fetchGet('/Select/GetWorkType')
}
// 获取门禁状态
export function GetDirection() {
    return fetchGet('/Select/GetDirection')
}
// 获取设备状态
export function GetRFIDDeviceStatus() {
    return fetchGet('/Select/GetRFIDDeviceStatus')
}

//获取退库单状态
export function GetTuiKuDanStatus() {
    return fetchGet('/Select/GetTuiKuDanStatus')
}

//----------------------库存管理------------------------

// 分页查询物资
export function GetStorageGoodsPage(data) {
    return fetchPost('/StorageGoods/GetStorageGoodsPage', data)
}
// 分页查询物资
export function getGoodsData(data) {
    return fetchPost('/StorageGoods/GetStorageGoodsListByIDList', data)
}
// 通过多个RFID批量快速查询物资
export function GetStorageGoodsByRFIDMultipleFast(data) {
    return fetchPost('/StorageGoods/GetStorageGoodsByRFIDMultipleFast', data)
}
// 增加物资
export function AddStorageGoods(data) {
    return fetchPost('/StorageGoods/AddStorageGoods', data)
}
// 修改物资
export function UpdateStorageGoods(data) {
    return fetchPost('/StorageGoods/UpdateStorageGoods', data)
}
// 删除物资  
export function DeleteStorageGoods(data) {
    return fetchGet('/StorageGoods/DeleteStorageGoods', data)
}
// 根据RFID查询数据
export function GetStorageGoodsByRFIDSingle(data) {
    return fetchGet('/StorageGoods/GetStorageGoodsByRFIDSingle', data)
}
// 根据id设备
export function GetStorageGoods(data) {
    return fetchGet('/StorageGoods/GetStorageGoods', data)
}
// 查询物资种类标识
export function GetCategoryID(data) {
    return fetchGet('/StorageGoods/GetCategoryID', data)
}
// 查询物资仓库标识
export function GetWarehouseID(data) {
    return fetchGet('/StorageGoods/GetWarehouseID', data)
}
// 查询货品标识
export function GetHuoPinID(data) {
    return fetchGet('/StorageGoods/GetHuoPinID', data)
}
//上传文件
export function upLoadFile(data) {
    return fetchPost1(`/Background/PublicUploadFile`, data)
}

// 清理文件 
export function clearFile(data) {
    return fetchGet('/Background/PublicClearFile', data)
}



/* 
    入库单
*/


// 分页获取入库单
export function getInKuPage(data) {
    return fetchPost('/StorageInKuDan/GetStorageInKuDanPage', data)
}
// 获取入库单详情
export function getInKuDetail(data) {
    return fetchGet('/StorageInKuDan/GetStorageInKuDanDetail', data)
}
// 添加入库单
export function addInkuDan(data) {
    return fetchPost('/StorageInKuDan/AddStorageInKuDan', data)
}
// 添加入库单
export function AddStorageGoodsInKuDan(data) {
    return fetchPost('/StorageInKuDan/AddStorageGoodsInKuDan', data)
}
// 修改入库单
export function updateInKuDan(data) {
    return fetchPost('/StorageInKuDan/UpdateStorageInKuDan', data)
}
// 确认入库单
export function queryInKuDan(data) {
    return fetchGet('/StorageInKuDan/ApprovedStorageInKuDan', data)
}
// 驳回入库单
export function rejectInKuDan(data) {
    return fetchGet('/StorageInKuDan/RejectedStorageInKuDan', data)
}
// 删除入库单
export function delInKuDan(data) {
    return fetchGet('/StorageInKuDan/DeleteStorageInKuDan', data)
}

// 下载入库明细单
export function InKuDownExcel(data) {
    return fetchGet('/StorageInKuDan/InKuDownExcel', data)
}


/* 
    出库单
*/
// 分页获取出库单
export function getOutKuPage(data) {
    return fetchPost('/StorageOutKuDan/GetStorageOutKuDanPage', data)
}
// 获取出库单详情
export function getOutKuDetail(data) {
    return fetchGet('/StorageOutKuDan/GetStorageOutKuDanDetail', data)
}
// 添加出库单
export function addOutKuDan(data) {
    return fetchPost('/StorageOutKuDan/AddStorageOutKuDan', data)
}
// 修改出库单
export function updateOutKuDan(data) {
    return fetchPost('/StorageOutKuDan/UpdateStorageOutKuDan', data)
}
// 确认出库单
export function queryOutKuDan(data) {
    return fetchGet('/StorageOutKuDan/ApprovedStorageOutKuDan', data)
}
// 驳回出库单
export function rejectOutKuDan(data) {
    return fetchGet('/StorageOutKuDan/RejectedStorageOutKuDan', data)
}
// 删除出库单
export function delOutKuDan(data) {
    return fetchGet('/StorageOutKuDan/DeleteStorageOutKuDan', data)
}

// 分页获取退库单
export function GetStorageTuiKuDanPage(data) {
    return fetchPost('/StorageTuiKuDan/GetStorageTuiKuDanPage', data)
}

// 新增退库单
export function AddStorageTuiKuDan(data) {
    return fetchPost('/StorageTuiKuDan/AddStorageTuiKuDan', data)
}

// 驳回退库单
export function RejectedStorageTuiKuDann(data) {
    return fetchGet('/StorageTuiKuDan/RejectedStorageTuiKuDann', data)
}

// 删除退库单
export function DeleteStorageTuiKuDan(data) {
    return fetchGet('/StorageTuiKuDan/DeleteStorageTuiKuDan', data)
}

// 确认退库单
export function ApprovedStorageTuiKuDan(data) {
    return fetchGet('/StorageTuiKuDan/ApprovedStorageTuiKuDan', data)
}

// 获取退库单
export function GetStorageTuiKuDan(data) {
    return fetchGet('/StorageTuiKuDan/GetStorageTuiKuDan', data)
}

// 获取退库单详情
export function GetStorageTuiKuDanDetail(data) {
    return fetchGet('/StorageTuiKuDan/GetStorageTuiKuDanDetail', data)
}

// 获取可退库物资明细
export function GetSTuiKuGoodsList(data) {
    return fetchGet('/StorageOutKuDan/GetStorageOutKuDanTuiKuGoodsList', data)
}


/* 
    库存调拨
*/
// 分页获取调拨单
export function getAllotPage(data) {
    return fetchPost('/StorageAllotDan/GetStorageAllotDanPage', data)
}
// 获取调拨单详情
export function getAllotDetail(data) {
    return fetchGet('/StorageAllotDan/GetStorageAllotDanDetail', data)
}
// 添加调拨单
export function addAllotDan(data) {
    return fetchPost('/StorageAllotDan/AddStorageAllotDan', data)
}
// 修改调拨单
export function updateAllotDan(data) {
    return fetchPost('/StorageAllotDan/UpdateStorageAllotDan', data)
}
// 确认调拨单
export function queryAllotDan(data) {
    return fetchGet('/StorageAllotDan/ApprovedStorageAllotDan', data)
}
// 驳回调拨单
export function rejectAllotDan(data) {
    return fetchGet('/StorageAllotDan/RejectedStorageAllotDan', data)
}
// 删除调拨单
export function delAllotDan(data) {
    return fetchGet('/StorageAllotDan/DeleteStorageAllotDan', data)
}
// 接收调拨单
export function receivedAllotDan(data) {
    return fetchGet('/StorageAllotDan/ReceivedStorageAllotDan', data)
}
// 拒收调拨单
export function RefuseAllotDan(data) {
    return fetchGet('/StorageAllotDan/RefuseStorageAllotDan', data)
}

/* 
    库存调整
*/
// 分页获取调整单
export function getTiaoZDanPage(data) {
    return fetchPost('/StorageTiaoZDan/GetStorageTiaoZDanPage', data)
}
// 获取调整单详情
export function getTiaoZDanDetail(data) {
    return fetchGet('/StorageTiaoZDan/GetStorageTiaoZDanDetail', data)
}
// 添加调整单
export function addTiaoZDan(data) {
    return fetchPost('/StorageTiaoZDan/AddStorageTiaoZDan', data)
}
// 确认调整单
export function queryTiaoZDan(data) {
    return fetchGet('/StorageTiaoZDan/ApprovedStorageTiaoZDan', data)
}
// 驳回调整单
export function rejectTiaoZDan(data) {
    return fetchGet('/StorageTiaoZDan/RejectedStorageTiaoZDan', data)
}
// 删除调整单
export function delTiaoZDan(data) {
    return fetchGet('/StorageTiaoZDan/DeleteStorageTiaoZDan', data)
}
// 获取调整单状态
export function getTiaoZDanStatus() {
    return fetchGet('/Select/GetTiaoZDanStatus')
}

/* 
    库存盘点
*/
// 分页获取盘点单
export function getPanDianPage(data) {
    return fetchPost('/StoragePanDianDan/GetStoragePanDianDanPage', data)
}
// 获取盘点单详情
export function getPanDian(data) {
    return fetchGet('/StoragePanDianDan/GetStoragePanDianDan', data)
}
// 获取盘点单详情
export function getPanDianDetail(data) {
    return fetchGet('/StoragePanDianDan/GetStoragePanDianDanDetail', data)
}
// 添加盘点单
export function addPanDianDan(data) {
    return fetchPost('/StoragePanDianDan/AddStoragePanDianDan', data)
}
// 修改盘点单
export function updatePanDianDan(data) {
    return fetchPost('/StoragePanDianDan/UpdateStoragePanDianDan', data)
}
// 确认盘点单
export function queryPanDianDan(data) {
    return fetchGet('/StoragePanDianDan/ApprovedStoragePanDianDan', data)
}
// 驳回盘点单
export function ScrapPanDianDan(data) {
    return fetchGet('/StoragePanDianDan/ScrapStoragePanDianDan', data)
}
// 删除盘点单
export function delPanDianDan(data) {
    return fetchGet('/StoragePanDianDan/DeleteStoragePanDianDan', data)
}
// 添加盘点单
export function stockPanDianDan(data) {
    return fetchPost('/StoragePanDianDan/PanDianStoragePanDianDan', data)
}


// 获取种类
export function GetStorageCategoryTree() {
    return fetchGet('/StorageCategory/GetStorageCategoryTree')
}
// 拖拽种类
export function ChangeStorageCategory(data) {
    return fetchGet('/StorageCategory/ChangeStorageCategory', data)
}
// 增加种类
export function AddStorageCategory(data) {
    return fetchPost('/StorageCategory/AddStorageCategory', data)
}
// 修改种类
export function UpdateStorageCategory(data) {
    return fetchPost('/StorageCategory/UpdateStorageCategory', data)
}
// 删除种类  
export function DeleteStorageCategory(data) {
    return fetchGet('/StorageCategory/DeleteStorageCategory', data)
}
// 根据id种类
export function GetStorageCategory(data) {
    return fetchGet('/StorageCategory/GetStorageCategory', data)
}
// 禁用种类
export function LimitStorageCategory(data) {
    return fetchGet('/StorageCategory/LimitStorageCategory', data)
}

// 禁用库存货品
export function LimitStorageHuoPin(data) {
    return fetchGet('/StorageHuoPin/LimitStorageHuoPin', data)
}

// 获取货品
export function GetStorageHuoPinPage(data) {
    return fetchPost('/StorageHuoPin/GetStorageHuoPinPage', data)
}
// 获取货品列表
// export function GetStorageHuoPinList() {
//     return fetchPost('/StorageHuoPin/GetStorageHuoPinPage')
// }
// 增加货品
export function AddStorageHuoPin(data) {
    return fetchPost('/StorageHuoPin/AddStorageHuoPin', data)
}
// 修改货品
export function UpdateStorageHuoPin(data) {
    return fetchPost('/StorageHuoPin/UpdateStorageHuoPin', data)
}
// 删除货品  
export function DeleteStorageHuoPin(data) {
    return fetchGet('/StorageHuoPin/DeleteStorageHuoPin', data)
}
// 根据id货品
export function GetStorageHuoPin(data) {
    return fetchGet('/StorageHuoPin/GetStorageHuoPin', data)
}



// 获取仓库树形结构
export function GetStorageWarehouseTree() {
    return fetchGet('/StorageWarehouse/GetStorageWarehouseTree')
}
// 拖拽仓库树形结构
export function ChangeStorageWarehouse(data) {
    return fetchGet('/StorageWarehouse/ChangeStorageWarehouse', data)
}
// 获取仓库
export function GetStorageWarehousePage(data) {
    return fetchPost('/StorageWarehouse/GetStorageWarehousePage', data)
}
// 增加仓库
export function AddStorageWarehouse(data) {
    return fetchPost('/StorageWarehouse/AddStorageWarehouse', data)
}
// 修改仓库
export function UpdateStorageWarehouse(data) {
    return fetchPost('/StorageWarehouse/UpdateStorageWarehouse', data)
}
// 删除仓库  
export function DeleteStorageWarehouse(data) {
    return fetchGet('/StorageWarehouse/DeleteStorageWarehouse', data)
}
// 根据id仓库
export function GetStorageWarehouse(data) {
    return fetchGet('/StorageWarehouse/GetStorageWarehouse', data)
}
// 根据禁用仓库
export function LimitStorageWarehouse(data) {
    return fetchGet('/StorageWarehouse/LimitStorageWarehouse', data)
}
// 获取仓库类型
export function GetWarehouseType() {
    return fetchGet('/Select/GetWarehouseType')
}
// 获取仓库状态
export function getWarehouseStatus() {
    return fetchGet('/Select/GetWarehouseStatus')
}
// 获取货架
export function GetStorageShelfPage(data) {
    return fetchPost('/StorageShelf/GetStorageShelfPage', data)
}
// 查询货架
export function GetStorageShelf(data) {
    return fetchGet('/StorageShelf/GetStorageShelf', data)
}
// 新增货架
export function AddStorageShelf(data) {
    return fetchPost('/StorageShelf/AddStorageShelf', data)
}
// 修改货架
export function UpdateStorageShelf(data) {
    return fetchPost('/StorageShelf/UpdateStorageShelf', data)
}
// 删除货架
export function DeleteStorageShelf(data) {
    return fetchGet('/StorageShelf/DeleteStorageShelf', data)
}
// 启/禁用货架
export function LimitStorageShelf(data) {
    return fetchGet('/StorageShelf/LimitStorageShelf', data)
}
// 获取货架状态
export function getShelfStatus() {
    return fetchGet('/Select/GetShelfStatus')
}



// 分页获取发卡
export function GetStorageFakaPage(data) {
    return fetchPost('/StorageFaka/GetStorageFakaPage', data)
}
// 查询发卡
export function GetStorageFaka(data) {
    return fetchPost('/StorageFaka/GetStorageFaka', data)
}
// 绑定发卡
export function BindStorageGoodsEPCList(data) {
    return fetchPost('/StorageGoods/BindStorageGoodsEPCList', data)
}
//发卡绑定
export function BindStorageFaka(data) {
    return fetchGet('/StorageFaka/BindStorageFaka', data)
}
//标签解绑
export function UnBindStorageFaka(data) {
    return fetchGet('/StorageFaka/UnBindStorageFaka', data)
}


// 解绑物资
export function UnBindStorageGoodsEPCList(data) {
    return fetchPost('/StorageGoods/UnBindStorageGoodsEPCList', data)
}


// 获取标签分组
export function GetBasicLableGroupList() {
    return fetchGet('/BasicLableGroup/GetBasicLableGroupList')
}
// 增加标签分组
export function AddBasicLableGroup(data) {
    return fetchPost('/BasicLableGroup/AddBasicLableGroup', data)
}
// 修改标签分组
export function UpdateBasicLableGroup(data) {
    return fetchPost('/BasicLableGroup/UpdateBasicLableGroup', data)
}
// 删除标签分组  
export function DeleteBasicLableGroup(data) {
    return fetchGet('/BasicLableGroup/DeleteBasicLableGroup', data)
}
// 根据id标签分组
export function GetBasicLableGroup(data) {
    return fetchGet('/BasicLableGroup/GetBasicLableGroup', data)
}
// 禁用标签分组
export function LimitBasicLableGroup(data) {
    return fetchGet('/BasicLableGroup/LimitBasicLableGroup', data)
}



// 获取RFID标签
export function GetBasicRFIDLablePage(data) {
    return fetchPost('/BasicRFIDLable/GetBasicRFIDLablePage', data)
}
// 增加RFID标签
export function AddBasicRFIDLable(data) {
    return fetchPost('/BasicRFIDLable/AddBasicRFIDLable', data)
}
// 修改RFID标签
export function UpdateBasicRFIDLable(data) {
    return fetchPost('/BasicRFIDLable/UpdateBasicRFIDLable', data)
}
// 删除RFID标签  
export function DeleteBasicRFIDLable(data) {
    return fetchGet('/BasicRFIDLable/DeleteBasicRFIDLable', data)
}
// 根据idRFID标签
export function GetBasicRFIDLable(data) {
    return fetchGet('/BasicRFIDLable/GetBasicRFIDLable', data)
}
// 禁用RFID标签
export function LimitBasicRFIDLable(data) {
    return fetchGet('/BasicRFIDLable/LimitBasicRFIDLable', data)
}
// 获取标签编码类型
export function GetEPCType(data) {
    return fetchGet('/Select/GetEPCType', data)
}
// 获取标签来源
export function GetLableSource(data) {
    return fetchGet('/Select/GetLableSource', data)
}
// 获取标签状态
export function GetRFIDStatus(data) {
    return fetchGet('/Select/GetRFIDStatus', data)
}
// 获取标签用途
export function GetRFIDDataUse(data) {
    return fetchGet('/Select/GetRFIDDataUse', data)
}

// 获取库存核算方式
export function GetStorageSystemSetting() {
    return fetchGet('/StorageSystemSetting/GetStorageSystemSetting')
}
// 设置库存核算方式
export function SetStorageSystemSetting(data) {
    return fetchGet('/StorageSystemSetting/SetStorageSystemSetting', data)
}
// 设置库存核算方式
export function SetStorageInKuSetting(data) {
    return fetchGet('/StorageSystemSetting/SetStorageInKuSetting', data)
}

// 库存分类统计报表
export function GetStorageReportA(data) {
    return fetchPost('/StorageReportA/GetStorageReportA', data)
}
// 物资入库统计
export function GetStorageReportB(data) {
    return fetchPost('/StorageReportB/GetStorageReportB', data)
}
// 物资出库统计
export function GetStorageReportC(data) {
    return fetchPost('/StorageReportC/GetStorageReportC', data)
}
// 部门领用汇总
export function GetStorageReportD(data) {
    return fetchPost('/StorageReportD/GetStorageReportD', data)
}
// 个人领用汇总
export function GetStorageReportE(data) {
    return fetchPost('/StorageReportE/GetStorageReportE', data)
}

// 获取模板列表
export function GetStorageTemplateList(data) {
    return fetchGet('/StorageTemplate/GetStorageTemplateList', data)
}
// 查询模板信息
export function GetStorageTemplate(data) {
    return fetchGet('/StorageTemplate/GetStorageTemplate', data)
}
// 添加模板信息
export function AddStorageTemplate(data) {
    return fetchPost('/StorageTemplate/AddStorageTemplate', data)
}
// 修改模板信息
export function UpdateStorageTemplate(data) {
    return fetchPost('/StorageTemplate/UpdateStorageTemplate', data)
}
// 删除模板信息
export function DeleteStorageTemplate(data) {
    return fetchGet('/StorageTemplate/DeleteStorageTemplate', data)
}
// 获取模板列
export function GetColunm(data) {
    return fetchGet('/StorageTemplate/GetColunm', data)
}
// 获取预览图片
export function GetImage(data) {
    return fetchPost('/StorageTemplate/GetImage', data)
}








//--------------------------------资产管理接口--------------------------------
// 获取区域树形结构
export function GetAssetDistrictTree() {
    return fetchGet('/AssetDistrict/GetAssetDistrictTree')
}
// 拖拽区域树形结构
export function ChangeAssetDistrict(data) {
    return fetchGet('/AssetDistrict/ChangeAssetDistrict', data)
}
// 获取区域
export function GetAssetDistrictPage(data) {
    return fetchPost('/AssetDistrict/GetAssetDistrictPage', data)
}
// 增加区域
export function AddAssetDistrict(data) {
    return fetchPost('/AssetDistrict/AddAssetDistrict', data)
}
// 修改区域
export function UpdateAssetDistrict(data) {
    return fetchPost('/AssetDistrict/UpdateAssetDistrict', data)
}
// 删除区域  
export function DeleteAssetDistrict(data) {
    return fetchGet('/AssetDistrict/DeleteAssetDistrict', data)
}
// 根据id区域
export function GetAssetDistrict(data) {
    return fetchGet('/AssetDistrict/GetAssetDistrict', data)
}
// 根据禁用区域
export function LimitAssetDistrict(data) {
    return fetchGet('/AssetDistrict/LimitAssetDistrict', data)
}



// 获取分类树形结构
export function GetAssetTypeTree() {
    return fetchGet('/AssetType/GetAssetTypeTree')
}
// 拖拽分类树形结构
export function ChangeAssetType(data) {
    return fetchGet('/AssetType/ChangeAssetType', data)
}
// 获取分类
export function GetAssetTypePage(data) {
    return fetchPost('/AssetType/GetAssetTypePage', data)
}
// 增加分类
export function AddAssetType(data) {
    return fetchPost('/AssetType/AddAssetType', data)
}
// 修改分类
export function UpdateAssetType(data) {
    return fetchPost('/AssetType/UpdateAssetType', data)
}
// 删除分类  
export function DeleteAssetType(data) {
    return fetchGet('/AssetType/DeleteAssetType', data)
}
// 根据id分类
export function GetAssetType(data) {
    return fetchGet('/AssetType/GetAssetType', data)
}

// 获取模板列表
export function GetAssetTemplateList(data) {
    return fetchGet('/AssetTemplate/GetAssetTemplateList', data)
}
// 查询模板信息
export function GetAssetTemplate(data) {
    return fetchGet('/AssetTemplate/GetAssetTemplate', data)
}
// 添加模板信息
export function AddAssetTemplate(data) {
    return fetchPost('/AssetTemplate/AddAssetTemplate', data)
}
// 修改模板信息
export function UpdateAssetTemplate(data) {
    return fetchPost('/AssetTemplate/UpdateAssetTemplate', data)
}
// 删除模板信息
export function DeleteAssetTemplate(data) {
    return fetchGet('/AssetTemplate/DeleteAssetTemplate', data)
}
// 获取模板列
export function GetAssetColunm(data) {
    return fetchGet('/AssetTemplate/GetColunm', data)
}
// 获取预览图片
export function GetAssetImage(data) {
    return fetchPost('/AssetTemplate/GetImage', data)
}


// 根据禁用分类
export function LimitAssetType(data) {
    return fetchGet('/AssetType/LimitAssetType', data)
}


// 获取货品
export function GetAssetHuoPinPage(data) {
    return fetchPost('/AssetHuoPin/GetAssetHuoPinPage', data)
}
// 增加货品
export function AddAssetHuoPin(data) {
    return fetchPost('/AssetHuoPin/AddAssetHuoPin', data)
}
// 修改货品
export function UpdateAssetHuoPin(data) {
    return fetchPost('/AssetHuoPin/UpdateAssetHuoPin', data)
}
// 删除货品  
export function DeleteAssetHuoPin(data) {
    return fetchGet('/AssetHuoPin/DeleteAssetHuoPin', data)
}
// 根据id货品
export function GetAssetHuoPin(data) {
    return fetchGet('/AssetHuoPin/GetAssetHuoPin', data)
}
// 根据禁用货品
export function LimitAssetHuoPin(data) {
    return fetchGet('/AssetHuoPin/LimitAssetHuoPin', data)
}
// 获取货品状态
export function GetHuoPinStatus() {
    return fetchGet('/Select/GetHuoPinStatus')
}

// 获取资产 
export function GetAssetGoodsPage(data) {
    return fetchPost('/AssetGoods/GetAssetGoodsPage', data)
}
// 增加资产
export function AddAssetGoods(data) {
    return fetchPost('/AssetGoods/AddAssetGoods', data)
}
// 修改资产
export function UpdateAssetGoods(data) {
    return fetchPost('/AssetGoods/UpdateAssetGoods', data)
}
// 删除资产  
export function DeleteAssetGoods(data) {
    return fetchGet('/AssetGoods/DeleteAssetGoods', data)
}
// 根据id资产
export function GetAssetGoods(data) {
    return fetchGet('/AssetGoods/GetAssetGoods', data)
}
// 根据禁用资产
export function LimitAssetGoods(data) {
    return fetchGet('/AssetGoods/LimitAssetGoods', data)
}
// 获取资产来源
export function GetGoodsSource(data) {
    return fetchGet('/Select/GetGoodsSource', data)
}
// 资产批量发卡
export function BindAssetGoodsEPCList(data) {
    return fetchPost('/AssetGoods/BindAssetGoodsEPCList', data)
}
// 单个发卡
export function BindSingleStorageGoodsEPCList(data) {
    return fetchPost('/StorageGoods/BindSingleStorageGoodsEPCList', data)
}
// 资产批量解绑
export function UnBindAssetGoodsEPCList(data) {
    return fetchPost('/AssetGoods/UnBindAssetGoodsEPCList', data)
}


// 分页获取资产发卡
export function GetAssetFakaPage(data) {
    return fetchPost('/AssetFaka/GetAssetFakaPage', data)
}
// 查询资产发卡
export function GetAssetFaka(data) {
    return fetchPost('/AssetFaka/GetAssetFaka', data)
}
// 绑定资产发卡
export function BindAssetFaka(data) {
    return fetchGet('/AssetFaka/BindAssetFaka', data)
}

// 解绑资产物资
export function UnBindAssetFaka(data) {
    return fetchGet('/AssetFaka/UnBindAssetFaka', data)
}



// 分页获取入库单
export function GetAssetReceivedPage(data) {
    return fetchPost('/AssetReceived/GetAssetReceivedPage', data)
}
// 获取入库单
export function GetAssetReceived(data) {
    return fetchGet('/AssetReceived/GetAssetReceived', data)
}
// 获取入库单明细
export function GetAssetReceivedDetail(data) {
    return fetchGet('/AssetReceived/GetAssetReceivedDetail', data)
}
// 新增入库单
export function AddAssetReceived(data) {
    return fetchPost('/AssetReceived/AddAssetReceived', data)
}
// 修改入库单
export function UpdateAssetReceived(data) {
    return fetchPost('/AssetReceived/UpdateAssetReceived', data)
}
// 确定入库单
export function ApprovedAssetReceived(data) {
    return fetchGet('/AssetReceived/ApprovedAssetReceived', data)
}
// 驳回入库单
export function RejectedAssetReceived(data) {
    return fetchGet('/AssetReceived/RejectedAssetReceived', data)
}
// 删除入库单
export function DeleteAssetReceived(data) {
    return fetchGet('/AssetReceived/DeleteAssetReceived', data)
}
// 获取入库单状态
export function GetReceivedStatus(data) {
    return fetchGet('/Select/GetReceivedStatus', data)
}




// 分页获取领用单
export function GetAssetRequisitionPage(data) {
    return fetchPost('/AssetRequisition/GetAssetRequisitionPage', data)
}
// 获取领用单
export function GetAssetRequisition(data) {
    return fetchGet('/AssetRequisition/GetAssetRequisition', data)
}
// 获取领用单明细
export function GetAssetRequisitionDetail(data) {
    return fetchGet('/AssetRequisition/GetAssetRequisitionDetail', data)
}
// 新增领用单
export function AddAssetRequisition(data) {
    return fetchPost('/AssetRequisition/AddAssetRequisition', data)
}
// 修改领用单
export function UpdateAssetRequisition(data) {
    return fetchPost('/AssetRequisition/UpdateAssetRequisition', data)
}
// 确定领用单
export function ApprovedAssetRequisition(data) {
    return fetchGet('/AssetRequisition/ApprovedAssetRequisition', data)
}
// 驳回领用单
export function RejectedAssetRequisition(data) {
    return fetchGet('/AssetRequisition/RejectedAssetRequisition', data)
}
// 删除领用单
export function DeleteAssetRequisition(data) {
    return fetchGet('/AssetRequisition/DeleteAssetRequisition', data)
}
// 获取领用单状态
export function GetRequisitionStatus() {
    return fetchGet('/Select/GetRequisitionStatus')
}




// 分页获取退库单
export function GetAssetRevertPage(data) {
    return fetchPost('/AssetRevert/GetAssetRevertPage', data)
}
// 获取退库单
export function GetAssetRevert(data) {
    return fetchGet('/AssetRevert/GetAssetRevert', data)
}
// 获取退库单明细
export function GetAssetRevertDetail(data) {
    return fetchGet('/AssetRevert/GetAssetRevertDetail', data)
}
// 新增退库单
export function AddAssetRevert(data) {
    return fetchPost('/AssetRevert/AddAssetRevert', data)
}
// 修改退库单
export function UpdateAssetRevert(data) {
    return fetchPost('/AssetRevert/UpdateAssetRevert', data)
}
// 确定退库单
export function ApprovedAssetRevert(data) {
    return fetchGet('/AssetRevert/ApprovedAssetRevert', data)
}
// 驳回退库单
export function RejectedAssetRevert(data) {
    return fetchGet('/AssetRevert/RejectedAssetRevert', data)
}
// 删除退库单
export function DeleteAssetRevert(data) {
    return fetchGet('/AssetRevert/DeleteAssetRevert', data)
}


// 分页获取借用单
export function GetAssetBorrowPage(data) {
    return fetchPost('/AssetBorrow/GetAssetBorrowPage', data)
}
// 获取借用单
export function GetAssetBorrow(data) {
    return fetchGet('/AssetBorrow/GetAssetBorrow', data)
}
// 获取借用单明细
export function GetAssetBorrowDetail(data) {
    return fetchGet('/AssetBorrow/GetAssetBorrowDetail', data)
}
// 新增借用单
export function AddAssetBorrow(data) {
    return fetchPost('/AssetBorrow/AddAssetBorrow', data)
}
// 修改借用单
export function UpdateAssetBorrow(data) {
    return fetchPost('/AssetBorrow/UpdateAssetBorrow', data)
}
// 确定借用单
export function ApprovedAssetBorrow(data) {
    return fetchGet('/AssetBorrow/ApprovedAssetBorrow', data)
}
// 驳回借用单
export function RejectedAssetBorrow(data) {
    return fetchGet('/AssetBorrow/RejectedAssetBorrow', data)
}
// 删除借用单
export function DeleteAssetBorrow(data) {
    return fetchGet('/AssetBorrow/DeleteAssetBorrow', data)
}
// 借用单
export function GetBorrowStatus() {
    return fetchGet('/Select/GetBorrowStatus')
}
//归还借用单
export function ReturnedAssetBorrow(data) {
    return fetchGet('/AssetBorrow/ReturnedAssetBorrow', data)
}

// 分页获取归还单
export function GetAssetReturnPage(data) {
    return fetchPost('/AssetReturn/GetAssetReturnPage', data)
}
// 获取归还单
export function GetAssetReturn(data) {
    return fetchGet('/AssetReturn/GetAssetReturn', data)
}
// 获取归还单明细
export function GetAssetReturnDetail(data) {
    return fetchGet('/AssetReturn/GetAssetReturnDetail', data)
}
// 新增归还单
export function AddAssetReturn(data) {
    return fetchPost('/AssetReturn/AddAssetReturn', data)
}
// 修改归还单
export function UpdateAssetReturn(data) {
    return fetchPost('/AssetReturn/UpdateAssetReturn', data)
}
// 确定归还单
export function ApprovedAssetReturn(data) {
    return fetchGet('/AssetReturn/ApprovedAssetReturn', data)
}
// 驳回归还单
export function RejectedAssetReturn(data) {
    return fetchGet('/AssetReturn/RejectedAssetReturn', data)
}
// 删除归还单
export function DeleteAssetReturn(data) {
    return fetchGet('/AssetReturn/DeleteAssetReturn', data)
}
// 归还单
export function GetReturnStatus() {
    return fetchGet('/Select/GetReturnStatus')
}
//归还归还单
export function ReturnedAssetReturn(data) {
    return fetchGet('/AssetReturn/ReturnedAssetReturn', data)
}


// 分页获取调拨单
export function GetAssetAllotPage(data) {
    return fetchPost('/AssetAllot/GetAssetAllotPage', data)
}
// 获取调拨单
export function GetAssetAllot(data) {
    return fetchGet('/AssetAllot/GetAssetAllot', data)
}
// 获取调拨单明细
export function GetAssetAllotDetail(data) {
    return fetchGet('/AssetAllot/GetAssetAllotDetail', data)
}
// 新增调拨单
export function AddAssetAllot(data) {
    return fetchPost('/AssetAllot/AddAssetAllot', data)
}
// 修改调拨单
export function UpdateAssetAllot(data) {
    return fetchPost('/AssetAllot/UpdateAssetAllot', data)
}
// 确定调拨单
export function ApprovedAssetAllot(data) {
    return fetchGet('/AssetAllot/ApprovedAssetAllot', data)
}
// 接收调拨单
export function ReceivedAssetAllot(data) {
    return fetchGet('/AssetAllot/ReceivedAssetAllot', data)
}
// 驳回调拨单
export function RejectedAssetAllot(data) {
    return fetchGet('/AssetAllot/RejectedAssetAllot', data)
}
// 拒收调拨单
export function RejectionAssetAllot(data) {
    return fetchGet('/AssetAllot/RejectionAssetAllot', data)
}
// 删除调拨单
export function DeleteAssetAllot(data) {
    return fetchGet('/AssetAllot/DeleteAssetAllot', data)
}
// 获取调拨状态
export function GetAllotStatus() {
    return fetchGet('/Select/GetAllotStatus')
}




// 分页获取交接单
export function GetAssetHandoverPage(data) {
    return fetchPost('/AssetHandover/GetAssetHandoverPage', data)
}
// 获取交接单
export function GetAssetHandover(data) {
    return fetchGet('/AssetHandover/GetAssetHandover', data)
}
// 获取交接单明细
export function GetAssetHandoverDetail(data) {
    return fetchGet('/AssetHandover/GetAssetHandoverDetail', data)
}
// 新增交接单
export function AddAssetHandover(data) {
    return fetchPost('/AssetHandover/AddAssetHandover', data)
}
// 修改交接单
export function UpdateAssetHandover(data) {
    return fetchPost('/AssetHandover/UpdateAssetHandover', data)
}
// 确定交接单
export function ApprovedAssetHandover(data) {
    return fetchGet('/AssetHandover/ApprovedAssetHandover', data)
}
// 接收交接单
export function ReceivedAssetHandover(data) {
    return fetchGet('/AssetHandover/ReceivedAssetHandover', data)
}
// 驳回交接单
export function RejectedAssetHandover(data) {
    return fetchGet('/AssetHandover/RejectedAssetHandover', data)
}
// 删除交接单
export function DeleteAssetHandover(data) {
    return fetchGet('/AssetHandover/DeleteAssetHandover', data)
}
// 获取交接单状态
export function GetHandoverStatus() {
    return fetchGet('/Select/GetHandoverStatus')
}


// 分页获取盘点单
export function GetAssetPanDianPage(data) {
    return fetchPost('/AssetPanDian/GetAssetPanDianPage', data)
}
// 获取条件下资产
export function GetAssetPanDianGoodsPage(data) {
    return fetchPost('/AssetPanDian/GetAssetPanDianGoodsPage', data)
}
// 获取盘点单详情
export function GetAssetPanDian(data) {
    return fetchGet('/AssetPanDian/GetAssetPanDian', data)
}
// 获取盘点单详情
export function GetAssetPanDianDetailList(data) {
    return fetchGet('/AssetPanDian/GetAssetPanDianDetail', data)
}
// 获取盘点单物资明细
export function GetAssetPanDianDetail(data) {
    return fetchPost('/AssetPanDian/GetAssetPanDianDetail', data)
}
// 添加盘点单
export function AddAssetPanDian(data) {
    return fetchPost('/AssetPanDian/AddAssetPanDian', data)
}
// 修改盘点单
export function UpdateAssetPanDian(data) {
    return fetchPost('/AssetPanDian/UpdateAssetPanDian', data)
}
// 确认盘点单
export function ApprovedAssetPanDian(data) {
    return fetchGet('/AssetPanDian/ApprovedAssetPanDian', data)
}
// 作废盘点单
export function ScrapAssetPanDian(data) {
    return fetchGet('/AssetPanDian/ScrapAssetPanDian', data)
}
// 删除盘点单
export function DeleteAssetPanDian(data) {
    return fetchGet('/AssetPanDian/DeleteAssetPanDian', data)
}
// 执行盘点单
export function MultiPanDianAssetPanDian(data) {
    return fetchPost('/AssetPanDian/MultiPanDianAssetPanDian', data)
}
// 获取盘点状态
export function GetPanDianStatus(data) {
    return fetchPost('/Select/GetPanDianStatus', data)
}
// 获取盘点结果
export function GetPanDianResult(data) {
    return fetchPost('/Select/GetPanDianResult', data)
}
// 获取盘点范围
export function GetPanDianRange(data) {
    return fetchPost('/Select/GetPanDianRange', data)
}
// 获取盘点类型
export function GetPanDianType(data) {
    return fetchPost('/Select/GetPanDianType', data)
}

// 获取变更 
export function GetAssetGoodsChangePage(data) {
    return fetchPost('/AssetGoodsChange/GetAssetGoodsChangePage', data)
}
// 增加变更
export function AddAssetGoodsChange(data) {
    return fetchPost('/AssetGoodsChange/AddAssetGoodsChange', data)
}
// 修改变更
export function UpdateAssetGoodsChange(data) {
    return fetchPost('/AssetGoodsChange/UpdateAssetGoodsChange', data)
}
// 删除变更  
export function DeleteAssetGoodsChange(data) {
    return fetchGet('/AssetGoodsChange/DeleteAssetGoodsChange', data)
}
// 根据id变更
export function GetAssetGoodsChange(data) {
    return fetchGet('/AssetGoodsChange/GetAssetGoodsChange', data)
}
// 获取资产变更单明细
export function GetAssetGoodsChangeDetail(data) {
    return fetchGet('/AssetGoodsChange/GetAssetGoodsChangeDetail', data)
}
// 确定变更
export function ApprovedAssetGoodsChange(data) {
    return fetchGet('/AssetGoodsChange/ApprovedAssetGoodsChange', data)
}
// 驳回变更
export function RejectedAssetGoodsChange(data) {
    return fetchGet('/AssetGoodsChange/RejectedAssetGoodsChange', data)
}
// 获取资产变更状态
export function GetGoodsChangeStatus(data) {
    return fetchPost('/Select/GetGoodsChangeStatus', data)
}




// 获取维保 
export function GetAssetMaintenanceChangePage(data) {
    return fetchPost('/AssetMaintenanceChange/GetAssetMaintenanceChangePage', data)
}
// 增加维保
export function AddAssetMaintenanceChange(data) {
    return fetchPost('/AssetMaintenanceChange/AddAssetMaintenanceChange', data)
}
// 修改维保
export function UpdateAssetMaintenanceChange(data) {
    return fetchPost('/AssetMaintenanceChange/UpdateAssetMaintenanceChange', data)
}
// 删除维保  
export function DeleteAssetMaintenanceChange(data) {
    return fetchGet('/AssetMaintenanceChange/DeleteAssetMaintenanceChange', data)
}
// 根据id维保
export function GetAssetMaintenanceChange(data) {
    return fetchGet('/AssetMaintenanceChange/GetAssetMaintenanceChange', data)
}
// 根据维保明细
export function GetAssetMaintenanceChangeDetail(data) {
    return fetchGet('/AssetMaintenanceChange/GetAssetMaintenanceChangeDetail', data)
}
// 确定维保
export function ApprovedAssetMaintenanceChange(data) {
    return fetchGet('/AssetMaintenanceChange/ApprovedAssetMaintenanceChange', data)
}
// 驳回维保
export function RejectedAssetMaintenanceChange(data) {
    return fetchGet('/AssetMaintenanceChange/RejectedAssetMaintenanceChange', data)
}
// 获取维保变更状态
export function GetMaintenanceChangeStatus(data) {
    return fetchPost('/Select/GetMaintenanceChangeStatus', data)
}

// 获取报修单 
export function GetAssetRepairPlanPage(data) {
    return fetchPost('/AssetRepairPlan/GetAssetRepairPlanPage', data)
}
// 增加报修单
export function AddAssetRepairPlan(data) {
    return fetchPost('/AssetRepairPlan/AddAssetRepairPlan', data)
}
// 修改报修单
export function UpdateAssetRepairPlan(data) {
    return fetchPost('/AssetRepairPlan/UpdateAssetRepairPlan', data)
}
// 删除报修单  
export function DeleteAssetRepairPlan(data) {
    return fetchGet('/AssetRepairPlan/DeleteAssetRepairPlan', data)
}
// 根据id报修单
export function GetAssetRepairPlan(data) {
    return fetchGet('/AssetRepairPlan/GetAssetRepairPlan', data)
}
// 获取维保单明细
export function GetAssetRepairPlanDetail(data) {
    return fetchGet('/AssetRepairPlan/GetAssetRepairPlanDetail', data)
}
// 确定报修单
export function ApprovedAssetRepairPlan(data) {
    return fetchGet('/AssetRepairPlan/ApprovedAssetRepairPlan', data)
}
// 驳回报修单
export function RejectedAssetRepairPlan(data) {
    return fetchGet('/AssetRepairPlan/RejectedAssetRepairPlan', data)
}
// 获取报修单变更状态
export function GetRepairPlanStatus(data) {
    return fetchPost('/Select/GetRepairPlanStatus', data)
}

// 获取维修 
export function GetAssetRepairWorkPage(data) {
    return fetchPost('/AssetRepairWork/GetAssetRepairWorkPage', data)
}
// 增加维修
export function AddAssetRepairWork(data) {
    return fetchPost('/AssetRepairWork/AddAssetRepairWork', data)
}
// 修改维修
export function UpdateAssetRepairWork(data) {
    return fetchPost('/AssetRepairWork/UpdateAssetRepairWork', data)
}
// 删除维修  
export function DeleteAssetRepairWork(data) {
    return fetchGet('/AssetRepairWork/DeleteAssetRepairWork', data)
}
// 根据id维修
export function GetAssetRepairWork(data) {
    return fetchGet('/AssetRepairWork/GetAssetRepairWork', data)
}
// 获取维保单明细
export function GetAssetRepairWorkDetail(data) {
    return fetchGet('/AssetRepairWork/GetAssetRepairWorkDetail', data)
}
// 确定维修
export function ApprovedAssetRepairWork(data) {
    return fetchGet('/AssetRepairWork/ApprovedAssetRepairWork', data)
}
// 驳回维修
export function RejectedAssetRepairWork(data) {
    return fetchGet('/AssetRepairWork/RejectedAssetRepairWork', data)
}
// 获取维修变更状态
export function GetRepairStatus(data) {
    return fetchPost('/Select/GetRepairStatus', data)
}

// 分页获取计保列表
export function GetAssetMaintenancePlanPage(data) {
    return fetchPost('/AssetMaintenancePlan/GetAssetMaintenancePlanPage', data)
}

// 获取计保单
export function GetAssetMaintenancePlan(data) {
    return fetchGet('/AssetMaintenancePlan/GetAssetMaintenancePlan', data)
}

// 获取计保单通过资产ID
export function GetAssetMaintenancePlanByGoodsID(data) {
    return fetchGet('/AssetMaintenancePlan/GetAssetMaintenancePlanByGoodsID', data)
}

// 获取计保单明细通过资产ID
export function GetAssetMaintenancePlanDetailByGoodsID(data) {
    return fetchGet('/AssetMaintenancePlan/GetAssetMaintenancePlanDetailByGoodsID', data)
}

// 获取计保单明细
export function GetAssetMaintenancePlanDetail(data) {
    return fetchGet('/AssetMaintenancePlan/GetAssetMaintenancePlanDetail', data)
}

// 新增计保单
export function AddAssetMaintenancePlan(data) {
    return fetchPost('/AssetMaintenancePlan/AddAssetMaintenancePlan', data)
}

// 修改计保单
export function UpdateAssetMaintenancePlan(data) {
    return fetchPost('/AssetMaintenancePlan/UpdateAssetMaintenancePlan', data)
}

// 禁用计保单
export function LimitAssetMaintenancePlan(data) {
    return fetchGet('/AssetMaintenancePlan/LimitAssetMaintenancePlan', data)
}

// 解禁计保单
export function UnLimitAssetMaintenancePlan(data) {
    return fetchGet('/AssetMaintenancePlan/UnLimitAssetMaintenancePlan', data)
}

// 删除计保单
export function DeleteAssetMaintenancePlan(data) {
    return fetchGet('/AssetMaintenancePlan/DeleteAssetMaintenancePlan', data)
}

// 获取计保状态
export function GetMaintenancePlanStatus(data) {
    return fetchGet('/Select/GetMaintenancePlanStatus', data)
}

// 分页获取保养单
export function GetAssetMaintenanceWorkPage(data) {
    return fetchPost('/AssetMaintenanceWork/GetAssetMaintenanceWorkPage', data)
}

// 新增保养单
export function AddAssetMaintenanceWork(data) {
    return fetchPost('/AssetMaintenanceWork/AddAssetMaintenanceWork', data)
}

// 修改保养单
export function UpdateAssetMaintenanceWork(data) {
    return fetchPost('/AssetMaintenanceWork/UpdateAssetMaintenanceWork', data)
}

// 获取保养单
export function GetAssetMaintenanceWork(data) {
    return fetchGet('/AssetMaintenanceWork/GetAssetMaintenanceWork', data)
}

// 获取保养单明细
export function GetAssetMaintenanceWorkDetail(data) {
    return fetchGet('/AssetMaintenanceWork/GetAssetMaintenanceWorkDetail', data)
}

// 删除保养单
export function DeleteAssetMaintenanceWork(data) {
    return fetchGet('/AssetMaintenanceWork/DeleteAssetMaintenanceWork', data)
}

// 获取保养状态
export function GetMaintenanceWorkStatus(data) {
    return fetchGet('/Select/GetMaintenanceWorkStatus', data)
}



// 获取清理报废 
export function GetAssetClearScrapPage(data) {
    return fetchPost('/AssetClearScrap/GetAssetClearScrapPage', data)
}
// 增加清理报废
export function AddAssetClearScrap(data) {
    return fetchPost('/AssetClearScrap/AddAssetClearScrap', data)
}
// 修改清理报废
export function UpdateAssetClearScrap(data) {
    return fetchPost('/AssetClearScrap/UpdateAssetClearScrap', data)
}
// 删除清理报废  
export function DeleteAssetClearScrap(data) {
    return fetchGet('/AssetClearScrap/DeleteAssetClearScrap', data)
}
// 根据id清理报废
export function GetAssetClearScrap(data) {
    return fetchGet('/AssetClearScrap/GetAssetClearScrap', data)
}
// 获取维保单明细
export function GetAssetClearScrapDetail(data) {
    return fetchGet('/AssetClearScrap/GetAssetClearScrapDetail', data)
}
// 确定清理报废
export function ApprovedAssetClearScrap(data) {
    return fetchGet('/AssetClearScrap/ApprovedAssetClearScrap', data)
}
// 驳回清理报废
export function RejectedAssetClearScrap(data) {
    return fetchGet('/AssetClearScrap/RejectedAssetClearScrap', data)
}
// 获取清理报废状态
export function GetClearScrapStatus(data) {
    return fetchPost('/Select/GetClearScrapStatus', data)
}



// 获取附属变更 
export function GetAssetChildChangePage(data) {
    return fetchPost('/AssetChildChange/GetAssetChildChangePage', data)
}
// 增加附属变更
export function AddAssetChildChange(data) {
    return fetchPost('/AssetChildChange/AddAssetChildChange', data)
}
// 修改附属变更
export function UpdateAssetChildChange(data) {
    return fetchPost('/AssetChildChange/UpdateAssetChildChange', data)
}
// 删除附属变更  
export function DeleteAssetChildChange(data) {
    return fetchGet('/AssetChildChange/DeleteAssetChildChange', data)
}
// 根据id附属变更
export function GetAssetChildChange(data) {
    return fetchGet('/AssetChildChange/GetAssetChildChange', data)
}
// 获取附属单明细
export function GetAssetChildChangeDetail(data) {
    return fetchGet('/AssetChildChange/GetAssetChildChangeDetail', data)
}
// 确定附属变更
export function ApprovedAssetChildChange(data) {
    return fetchGet('/AssetChildChange/ApprovedAssetChildChange', data)
}
// 驳回附属变更
export function RejectedAssetChildChange(data) {
    return fetchGet('/AssetChildChange/RejectedAssetChildChange', data)
}
// 获取附属变更状态
export function GetChildChangeStatus(data) {
    return fetchPost('/Select/GetChildChangeStatus', data)
}
// 获取新增时默认附资产列表
export function GetFuGoodsDefault(data) {
    return fetchGet('/AssetGoods/GetFuGoodsDefault', data)
}


// 获取财务变更 
export function GetAssetFinancialChangePage(data) {
    return fetchPost('/AssetFinancialChange/GetAssetFinancialChangePage', data)
}
// 增加财务变更
export function AddAssetFinancialChange(data) {
    return fetchPost('/AssetFinancialChange/AddAssetFinancialChange', data)
}
// 修改财务变更
export function UpdateAssetFinancialChange(data) {
    return fetchPost('/AssetFinancialChange/UpdateAssetFinancialChange', data)
}
// 删除财务变更  
export function DeleteAssetFinancialChange(data) {
    return fetchGet('/AssetFinancialChange/DeleteAssetFinancialChange', data)
}
// 根据id财务变更
export function GetAssetFinancialChange(data) {
    return fetchGet('/AssetFinancialChange/GetAssetFinancialChange', data)
}
// 获取财务单明细
export function GetAssetFinancialChangeDetail(data) {
    return fetchGet('/AssetFinancialChange/GetAssetFinancialChangeDetail', data)
}
// 确定财务变更
export function ApprovedAssetFinancialChange(data) {
    return fetchGet('/AssetFinancialChange/ApprovedAssetFinancialChange', data)
}
// 驳回财务变更
export function RejectedAssetFinancialChange(data) {
    return fetchGet('/AssetFinancialChange/RejectedAssetFinancialChange', data)
}
// 获取财务变更状态
export function GetFinancialChangeStatus(data) {
    return fetchPost('/Select/GetFinancialChangeStatus', data)
}


// 获取资产汇总一览
export function GetAssetReportA(data) {
    return fetchPost('/AssetReportA/GetAssetReportA', data)
}
// 获取资产汇总一览资产列表
export function GetAssetGoodsStatePage(data) {
    return fetchPost('/AssetReportA/GetAssetGoodsPage', data)
}
// 获取资产分类汇总
export function GetAssetReportB(data) {
    return fetchPost('/AssetReportB/GetAssetReportB', data)
}
// 获取资产标准品汇总
export function GetAssetReportC(data) {
    return fetchPost('/AssetReportC/GetAssetReportC', data)
}
// 获取区域资产汇总
export function GetAssetReportE(data) {
    return fetchPost('/AssetReportE/GetAssetReportE', data)
}
// 获取公司资产汇总
export function GetAssetReportD(data) {
    return fetchPost('/AssetReportD/GetAssetReportD', data)
}
// 获取公司汇总
export function GetAssetReportF(data) {
    return fetchPost('/AssetReportF/GetAssetReportF', data)
}
// 获取部门汇总
export function GetAssetReportH(data) {
    return fetchPost('/AssetReportH/GetAssetReportH', data)
}
// 获取个人使用统计
export function GetAssetReportI(data) {
    return fetchPost('/AssetReportI/GetAssetReportI', data)
}

// 查询资产核算方式
export function GetAssetBaseSetting(data) {
    return fetchGet('/AssetBaseSetting/GetAssetBaseSetting', data)
}

// 设置资产核算方式 
export function SetAssetBaseSetting(data) {
    return fetchGet('/AssetBaseSetting/SetAssetBaseSetting', data)
}

// 获取资产数量金额
export function GetAssetGoodsCountMoney(data) {
    return fetchGet('/AssetGoods/GetAssetGoodsCountMoney', data)
}
// 获取库存数量金额
export function GetStorageGoodsCountMoney(data) {
    return fetchGet('/StorageGoods/GetStorageGoodsCountMoney', data)
}


/* 
    导入导出表格接口
*/
// 获取表头参数
export function GetExportColumn(data) {
    return fetchGet('/Export/GetExportColumn', data)
}
// 获取导出下载链接
export function GetExportDownUrl(data) {
    return fetchPost('/Export/GetExportDownUrl', data)
}
// 导入表格数据
export function ImportData(data) {
    return fetchGet('/Import/ImportData', data)
}

/* 
    权限校验 
*/
export function CheckMenuAuthority(data) {
    return fetchGet('/Base/CheckMenuAuthority', data)
}

/* 离线数据 */

// 离线部门
export function GetDeptOffline(data) {
    return fetchGet('/BasicDept/GetDownMation', data)
}

// 离线用户
export function GetUserOffline(data) {
    return fetchGet('/BasicUser/GetDownMation', data)
}

// 获取离线资产
export function GetGoodsOffline(data) {
    return fetchPost('/AssetGoods/GetDownMation', data)
}

// 离线分类
export function GetTypeOffline(data) {
    return fetchGet('/AssetType/GetDownMation', data)
}

// 离线区域
export function GetDistrictOffline(data) {
    return fetchGet('/AssetDistrict/GetDownMation', data)
}

// 离线库房
export function GetWarehouseOffline(data) {
    return fetchGet('/StorageWarehouse/GetDownMation', data)
}

// 离线物资
export function GetStorageGoodsOffline(data) {
    return fetchPost('/StorageGoods/GetDownMation', data)
}






