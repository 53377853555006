<template>
  <el-select :disabled="this.allType" class="treeTable" clearable ref="select" value-key="id" :value="valueId"
    :placeholder="$t('CategoryList.sel')" @visible-change="visibleChange" style="width: 100%;" @clear="clear">
    <el-option :ref="'option' + item.id" v-for="item of options" class="option" :value="item.id" :key="item.id"
      :label="item.label" style="display: none;"></el-option>
    <el-tree ref="tree" class="tree" :node-key="nodeKey" :data="data" :props="props"
      :default-expanded-keys='defaultExpandedKey' highlight-current :expand-on-click-node="false"
      @node-click="handleNodeClick"></el-tree>
  </el-select>
</template>

<script>
export default {
  name: 'TreeSelect',
  props: {
    // v-model绑定
    value: {
      type: [String, Number],
      default: () => { return null }
    },
    // 树形的数据
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 每个树节点用来作为唯一标识的属性
    nodeKey: {
      type: String,
      default: 'id'
    },
    // tree的props配置
    props: {
      type: Object,
      default: () => {
        return {
          id: 'id',
          label: 'label',
          children: 'children'
        }
      }
    },
    allType: {
      type: Boolean
    },
  },
  data() {
    return {
      optionData: {
        id: '',
        label: ''
      },
      valueId: null,
      currentLabel: '',
      options: [],
      defaultExpandedKey: []
    }
  },
  watch: {
    value: {
      handler() {
        this.valueId = this.value;
      },
      immediate: true
    },
    data: {
      handler(val) {
        this.$nextTick(() => {
          if (!this.isEmpty(this.data)) {
            this.init(val)
          }
        })
      },
      immediate: true
    }
  },
  mounted() {

  },
  methods: {
    // 是否为空
    isEmpty(val) {
      for (let key in val) {
        return false;
      }
      return true;
    },
    handleNodeClick(data) {
      if (data.disabled && data.level == 1) return
      // this.optionData.id = data.id;
      // this.optionData.label = data.label;
      this.$emit('input', data.label, data.id);
      this.$emit('selectRoom', data);
      this.$emit('change', Number(data.id));
      this.$refs.select.visible = false;
      this.$refs.select.blur();
    },
    init(val) {
      if (val) {
        this.$nextTick(() => {
          let options = this.TreeToArray(val);
          if(this.value){
            this.defaultExpandedKey = [this.value.toString()];
          }
          // let option = options.filter(e => e.value == this.value);
          this.options = options;
          // if (option.length == 0) {
          //   this.optionData.id = '';
          //   this.optionData.label = '';
          // } else {
          //   this.optionData.id = option[0].value;
          //   this.optionData.label = option[0].label;
          // }
          this.$forceUpdate();
        })
      } else {
        this.$refs.tree.setCurrentKey(null);
      }
    },
    TreeToArray(array, result = []) {
      array.forEach(item => {
        result.push(item)
        if (item.children && item.children.length !== 0) {
          this.TreeToArray(item.children, result)
        }
      })
      return JSON.parse(JSON.stringify(result))
    },
    visibleChange(e) {
      if (e) {
        let selectDom = this.$refs.tree.$el.querySelector('.is-current');
        setTimeout(() => {
          this.$refs.select.scrollToOption({ $el: selectDom });
        }, 0)
      }
    },
    clear() {
      this.$emit('input', '');
      this.$emit('clear');
      this.$emit('selectRoom', { id: null, label: '' });
      this.$emit('change', null);
    }
  }
}
</script>

<style lang="scss" scoped>
* {

  /* 滚动条样式 */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #fff;

    &:hover {
      background-color: #fff;
    }
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
    background: #fff;
    border-radius: 12px;
  }
}

.treeTable {
  overflow: auto;
  max-height: 600px;
}

.option {
  height: auto;
  line-height: 1;
  padding: 0;
  background-color: #fff;
}

.tree {
  padding: 4px 20px;
  font-weight: 400;
}
</style>

