import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import i18n from './lang';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/style/style.scss';
import SystemUser from '@/components/SystemUser';
import SelectView from '@/components/Select_Tree';
import TreeViewcopy from '@/components/TreeViewcopy'
import SystemGoods from '@/components/SystemGoods';
import SystemAssetGoods from '@/components/SystemAssetGoods';
import SelectTree from '@/components/SelectTree';
import SelectDeptTree from '@/components/SelectDeptTree';
import AdminUser from '@/components/AdminUser';
import ImageView from '@/components/ImageView';
import SelectMenu from '@/components/SelectMenu';
import ExportView from '@/components/ExportView';
import TreeSelect from "@/components/TreeView.vue";
import BasicDeptOperation from "@/components/basicadd/BasicDeptOperation.vue";
import BasicUserOperation from "@/components/basicadd/BasicUserOperation.vue";
import BasicLableOperation from "@/components/basicadd/BasicLableOperation.vue";
import BasicRFIDDevice from "@/components/basicadd/BasicRFIDDevice.vue";
import GoodsDanFa from "@/components/storageadd/Goods/GoodsDanFa.vue";
import GoodsBatchFaKa from "@/components/storageadd/Goods/GoodsBatchFaKa.vue";
import StorageInKu from "@/components/storageadd/StorageInKu/StorageInKu.vue";
import ReceivedAdd from '@/components/assetadd/ReceivedAdd.vue';
import StorageChange from "@/components/storageadd/StorageChange/StorageChange.vue";

import PanDianIn from '@/components/assetadd/PanDianIn.vue';


import StorageOuKu from "@/components/storageadd/StorageOuKu/StorageOuKu.vue";
import StorageAllot from "@/components/storageadd/StorageAllot/StorageAllot.vue";
import StoragePanDianDan from "@/components/storageadd/StoragePanDianDan/StoragePanDianDan.vue";
import StorageWarehouse from "@/components/storageadd/StorageWarehouse/StorageWarehouse.vue";
import StorageHuoPin from "@/components/storageadd/StorageHuoPin/StorageHuoPin.vue";
import StorageDetail from "@/components/storageadd/StorageHuoPin/StorageDetail.vue";
import ChangeDetail from "@/components/storage/ChangeDetail.vue";
import SelectWarehouse from "@/components/storageadd/StorageWarehouse/SelectWarehouse.vue";
import SelectDept from "@/components/SelectDept.vue";
import SelectCategory from "@/components/SelectCategory.vue";
import SelectRegion from "@/components/SelectRegion.vue";
import SelectType from "@/components/SelectType.vue";
import CategoryList from "@/components/CategoryDataList.vue";
import MessageTip from '@/components/MessageTip.vue';
import DragTable from '@/components/DragTable.vue';
// 导入element-ui,和全局使用element-ui样式
import "element-ui/lib/theme-chalk/index.css";

// 使用 ElementUI 组件库
Vue.use(ElementUI);

Vue.component('PanDianIn', PanDianIn)
Vue.component('DragTable', DragTable)
Vue.component('ReceivedAdd', ReceivedAdd)
Vue.component('CategoryList', CategoryList)
Vue.component('SelectType', SelectType)
Vue.component('SelectRegion', SelectRegion)
Vue.component('SelectCategory', SelectCategory)
Vue.component('SelectDept', SelectDept)
Vue.component('SelectWarehouse', SelectWarehouse)
Vue.component('SelectView', SelectView)
Vue.component('StorageDetail', StorageDetail)
Vue.component('StorageHuoPin', StorageHuoPin)
Vue.component('StorageWarehouse', StorageWarehouse)
Vue.component('StoragePanDianDan', StoragePanDianDan)
Vue.component('StorageAllot', StorageAllot)
Vue.component('StorageOuKu', StorageOuKu)
Vue.component('StorageInKu', StorageInKu)
Vue.component('GoodsBatchFaKa', GoodsBatchFaKa)
Vue.component('GoodsDanFa', GoodsDanFa)
Vue.component('BasicRFIDDevice', BasicRFIDDevice)
Vue.component('BasicLableOperation', BasicLableOperation)
Vue.component('BasicDeptOperation', BasicDeptOperation)
Vue.component('BasicUserOperation', BasicUserOperation)
Vue.component('SelectMenu', SelectMenu)
Vue.component('TreeSelect', TreeSelect)
Vue.component('SystemUser', SystemUser)
Vue.component('AdminUser', AdminUser)
Vue.component('SelectTree', SelectTree)
Vue.component('SelectDeptTree', SelectDeptTree)
Vue.component('SystemGoods', SystemGoods)
Vue.component('SystemAssetGoods', SystemAssetGoods)
Vue.component('TreeViewcopy', TreeViewcopy)
Vue.component('ImageView', ImageView)
Vue.component('ExportView', ExportView)
Vue.component('MessageTip', MessageTip)
Vue.component('StorageChange', StorageChange)
Vue.component('ChangeDetail', ChangeDetail)
Vue.prototype.LimitaTionImg = function (file) {
  const size = file.size / 1024 / 1024 > 1
  if (size) {
    msgTips("当前图片过大:" + parseFloat(file.size / 1024 / 1024).toFixed(2) + 'MB,' + "允许最大上传图片1MB");
    return true;
  }
};
Vue.prototype.$transferTime = date => {
  if (date.toString().includes('/Date')) {
    if (date) {
      return new Date(parseInt(date.slice(6, 19))).toLocaleString().replace(/\//g, '-');
    }
  }
  return date;
}
Vue.prototype.selectCheck = function (id, list) {
  if (list == null) {
    return true;
  }
  for (let item of list) {
    if (item.value == id) {
      // id存在
      return false;
    }
  }
  //id不存在
  return true;
};


//图片放大组件
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.use(Viewer)
Vue.prototype.$url = ''  //本地调试
// Vue.prototype.$url = 'http://www.sbkj-rfid.link'  //本地调试 
// Vue.prototype.$url = 'http://localhost:62498'  //本地调试\
// Vue.prototype.$url = 'http://192.168.0.80'  //本地调试

// Vue.prototype.$url = '' //打包
ElementUI.Dialog.props.closeOnClickModal.default = false
import _ from 'lodash';
import { msgTips } from './api/user';
// import * as echarts from 'echarts';
// Vue.prototype.$echarts = echarts;
Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype._ = _;


new Vue({
  render: h => h(App),
  router,
  store,
  i18n
}).$mount('#app')
