<template>
  <el-dialog :visible.sync="state" center width="65%" @close="close" custom-class="_dialog_height">
    <div class="settin-box">
      <div class="_right">
        <div class="content_left">
          <div class="tabs">
            <span v-for="(item, index) in list" :key="index" class="tabs_item" :class="{ check: tabs == index }"
              @click="tabs = index">{{ item }}</span>
          </div>
          <div class="content" v-show="tabs == 0">
            <span class="title">组织</span>
            <el-input placeholder="输入组织" v-model="filterText">
            </el-input>
            <el-tree class="treeTable" :data="data" node-key="id" ref="tree" default-expand-all :expand-on-click-node="false"
              :filter-node-method="filterNode" @node-click="handleNodeClick">
              <span class="custom-tree-node" slot-scope="{ node,data }">
                <span>
                  <i :class="data.level == 1 ? 'el-icon-office-building' : 'el-icon-s-home'"></i>
                  {{ node.label }}
                </span>
              </span>
            </el-tree>
          </div> 
        </div>
        <div class="content_right">
          <div class="table">
            <span class="title">{{ title }}</span>
            <div class="head_line">
              <el-input type="text" placeholder="名称/编号" v-model="SearchName"> </el-input>
              <el-button type="primary" @click="SelectUser()">搜索</el-button>
              <el-button type="primary" :disabled="checked" @click="append()">确定</el-button>
            </div>
            <div class="content_table">
              <el-table border :data="tableData"  v-loading="loading" height="350px" stripe @selection-change="handleSelectionChange"
                :row-class-name="selectRowClassName" ref="tableList" @row-click="handleRowClick">
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column sortable label="用户图片" width="180">
                  <template slot-scope="scope">
                    <ImageView :url="scope.row.UserImage"></ImageView>
                  </template>
                </el-table-column>
                <el-table-column prop="UserCode" sortable label="用户编号" width="180">
                </el-table-column>
                <el-table-column prop="UserName" sortable label="用户名称" width="180">
                </el-table-column>
                <el-table-column prop="UserSex" sortable label="用户性别" width="180">
                </el-table-column>
                <el-table-column prop="UserAccount" sortable label="登录账号" width="180">
                </el-table-column>
                <el-table-column prop="UserFace" sortable label="用户人脸" width="180">
                </el-table-column>
                <el-table-column prop="UserUHF" sortable label="用户UHF" width="180">
                </el-table-column>
                <el-table-column prop="UserEPC" sortable label="用户RFID" width="180">
                </el-table-column>
                <el-table-column prop="MobilePhone" sortable label="用户移动电话" width="220">
                </el-table-column>
                <el-table-column prop="TelePhone" sortable label="用户固定电话" width="220">
                </el-table-column>
                <el-table-column prop="UserStatus" sortable label="用户状态" width="180">
                </el-table-column>
                <el-table-column prop="UserRemark" sortable label="用户描述" width="180">
                </el-table-column>
                <el-table-column prop="CreateTime" sortable label="创建时间" width="180">
                </el-table-column>
              </el-table>
            </div>
            <el-pagination v-if="TotalCount > 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="pageNumber" :page-sizes="[20, 50, 100]" :page-size="20"
              layout="total, sizes, prev, pager, next, jumper" :total="TotalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
  
<script>
import {
 // msgTips,
 // successTips,
 GetBasicDeptTree,
 GetBasicUserPage,
 updateTime

} from "@/api/user";
import mixins from "@/mixins";
export default {
 watch: {
  filterText(val) {
   this.$refs.tree.filter(val);
  }
 },
 name: "SettingView",
 components: {},
 mixins: [mixins],
 computed: {
  state: {
   get() {
    return this.type;
   },
   set() {

   }
  }
 },
 props: {
  type: {
   type: Boolean,
   default: () => {
    return false
   }
  },
  DataList: {
   type: Array,
   default() {
    return []
   }
  },
  seltype: {
   type: Boolean,
   default: () => {
    return true
   }
  }
 },
 data() {
  return {
   // state:'', 
   TotalCount: 0,
   pageNumber: 0,
   UserRoomShow: false,
   addUserImg: '',
   tabs: 0,
   menutab: 0,
   ID: "",
   level: "",
   userState: '',
   userText: '禁用/启用',
   filterText: "",
   drawer: false,
   icon: '',
   UserTitle: '',
   companyChecked: false,
   dialogFormVisible: false,
   userChecked: false,
   checked: true,
   passWordChecked: false,
   list: ["组织", "员工组"],
   title: "全部成员",
   currentPage: 1,
   value: '',
   DeptTitle: "",
   SearchName: '',
   DeptID: '',
   tableData: [],
   Dept: {
    DeptCode: '',
    DeptName: '',
    ParentDeptID: '',
    ParentDeptID1: '',
    ParentDeptID2: '',
    ParentDeptID3: '',
    ParentDeptID4: '',
    ParentDeptID5: '',
    ParentDeptID6: '',
    ParentDeptID7: '',
    ParentDeptID8: '',
    ParentDeptID9: '',
    ParentDeptID10: '',
    MainUserID: '',
    DeptLevel: '',
    ISCompany: '',
    DeptStatus: '',
    pid: '',
    DeptRemark: ''
   },
   BasicUser: {
    SearchName: '',
    PageSize: '20',
    PageIndex: '0',
    UserCode: '',
    UserName: '',
    UserSex: '',
    UserAccount: '',
    UserPassword: '',
    UserFace: '',
    UserUHF: '',
    UserEPC: '',
    UserImage: '',
    MobilePhone: '',
    TelePhone: '',
    UserEmail: '',
    DeptID: '',
    ManageID: '',
    UserType: '',
    UserStatus: '',
    UserRemark: '',
    UpdateTime: '',
    CreateTime: ''
   },
   loading: false,
   BasicUserList: [],
   tabslist: [
    {
     title: "全部成员",
     icon: "el-icon-user",
    },
    {
     title: "离职成员",
     icon: "el-icon-user",
    },
   ],
   data: [],
   // type: false,
   updatetype: false,
   oldImg: '',
   imageNum: 0,
   multipleSelection: [],
  };
 },
 mounted() {
  this.state = this.type;
  this.getData();
  this.SelectUser();
  this.show();
 },
 methods: {
  show() {
   this.loading = true;
   setTimeout(() => {
    this.loading = false;
    for (let i = 0; i < this.tableData.length; i++) {
     for (let j = 0; j < this.DataList.length; j++) {
      if (this.tableData[i].UserID == this.DataList[j]) {
       this.$refs.tableList.toggleRowSelection(this.tableData[i]);
      }
     }
    }
   }, 1000)
  },
  //点击获取节点数据
  handleNodeClick(data) {
   if (data.level == 1) {
    this.companyChecked = true;
   } else {
    this.companyChecked = false;
   } 
   this.Dept.pid = data.pid;
   this.Dept.ParentDeptID = data.id;
   this.level = data.level;
   this.DeptID = data.id;
   this.SelectUser();
  },

  getData() {
   GetBasicDeptTree().then((res) => {
    if (res.Code == 0) {
     let obj = { pid: 0, id: 0, label: '全部用户', level: 1, status: 1, children: [] };
     res.Data.unshift(obj);
     this.data = res.Data;
     if (res.TotalCount != 0) {
      if (res.Data.level == 1) {
       this.icon = "el-icon-office-building";
      } else {
       this.icon = "el-icon-s-home";
      }
     }
    } else {
     this.data = [];
    }
   });
  },
  SelectUser() {
   if (this.BasicUser.PageSize == "" || this.BasicUser.PageSize == null) {
    this.BasicUser.PageSize = 20;
   }
   this.BasicUser.SearchName = this.SearchName;
   this.BasicUser.DeptID = this.DeptID;
   if (this.BasicUser.DeptID == "" || this.BasicUser.DeptID == null) {
    this.BasicUser.DeptID = 0;
   }
   GetBasicUserPage(this.BasicUser).then((res) => {
    if (res.Code == 0 && res.Data != null) {
     this.tableData = res.Data;
     for (let i = 0; i < this.tableData.length; i++) {
      this.tableData[i].CreateTime = updateTime(this.tableData[i].CreateTime);
     }
    } else {
     this.tableData = [];
    }
   });
  },
  SelectPlat() {
   //高级查询 
   this.BasicUser.PageSize = 20;
   this.BasicUser.PageIndex = 0;
   this.BasicUser.SearchName = "";
   GetBasicUserPage(this.BasicUser).then((res) => {
    if (res.Code == 0) {
     this.tableData = res.Data;
     for (let i = 0; i < this.tableData.length; i++) {
      this.tableData[i].CreateTime = updateTime(this.tableData[i].CreateTime);
     }
    }
   });
  },
  handleSizeChange(val) {
   this.BasicUser.PageSize = val; //每页数量
   this.SelectPlat();
  },
  handleCurrentChange(val) {
   this.BasicUser.PageIndex = parseInt(val - 1);//第几页
   this.SelectPlat();

  },
  filterNode(value, data) {
   if (!value) return true;
   return data.label.indexOf(value) !== -1;
  },

  close() {
   this.$emit('close')
  },
  append() {
   if (!this.seltype) {
    this.$emit('addMember', this.BasicUserList);
    return
   }
   this.$emit('addMember', this.BasicUser.UserID, this.BasicUser.UserName, this.BasicUser);
  },
  remove(node, data) {
   const parent = node.parent;
   const children = parent.data.children || parent.data;
   const index = children.findIndex((d) => d.id === data.id);
   children.splice(index, 1);
  },
  resetting() {
   //重置
   this.clearImg();
   for (let i in this.BasicUser) {
    this.BasicUser[i] = '';
   }
  },
  Search() { },
  selectRowClassName({ row }) {
   var color = "";
   this.multipleSelection.forEach(item => {
    if (item.UserID == row.UserID) {
     color = "warning-row";
    }
   });
   return color;
  },
  //选择整行
  handleRowClick(row) {
   if (this.seltype) {
    this.$refs.tableList.clearSelection()
   }
   let index = this.multipleSelection.findIndex(item => {
    // 判断已选数组中是否已存在该条数据
    return item.UserID == row.UserID
   });
   if (index == -1) {
    // 如果未存在，设置已选状态，并在list中添加这条数据
    this.$refs.tableList.toggleRowSelection(row, true); //设置复选框为选中状态
   } else {
    // 如果已存在，设置未选状态，并在list中删除这条数据
    this.$refs.tableList.toggleRowSelection(row, false); //设置复选框为未选状态
   }
  },
  handleSelectionChange(val) {
   this.multipleSelection = val;
   // 获取选中点击的数据 
   if (this.seltype) {
    if (val.length > 0) {
     this.BasicUser = val[0];
     this.checked = false;
    } else {
     this.checked = true;
    }
   } else {
    if (val) {
     this.BasicUserList = val;
     this.checked = false;
    } else {
     this.checked = true;
    }
   }
  },
 },
};
</script>

<style lang="scss" scoped>
.treeTable{
  overflow:auto;
  max-height: 300px;
}
//头像
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.el-icon-plus {
  border: 1px dashed;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

._filter {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  span {
    margin-right: 20px;
    color: #1a73e8;
    font-size: 14px;
    cursor: pointer;

    .el-icon-refresh {
      margin-left: 10px;
      font-size: 16px;
    }
  }
}

._filter_btn {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 100px;

  .el-button {
    width: 100px;

    &:nth-child(2) {
      margin-left: 50px;
    }
  }
}

.avatar-uploader-icon {
  font-size: 15px;
  color: #8c939d;
  width: 135px;
  height: 128;
  line-height: 120px;
  text-align: center;
}

.avatars {
  width: 135px;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  line-height: 1;
  background-color: #eee;
  border: 1px solid #ccc;
  cursor: pointer;

  &:hover {
    background-color: #fffefe;
  }
}

.avatar {
  width: 135px;
  height: 128px;
  display: block;
}

//
.ul_but li {
  margin-bottom: 20px;
}

.ul_but:hover {
  cursor: pointer;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.settin-box {
  width: 100%;
  height: calc(100vh - 600px);
  display: flex;
  background-color: #fff;
  border-radius: 5px;

  ul {
    padding: 0;
    margin: 0;
  }

  ._right {
    display: flex;
    flex: 1;
    position: relative;

    .content_left {
      width: 25%;
      height: calc(100vh - 700px);
      padding: 30px 10px;
      font-size: 19px;

      .tabs {
        display: flex;
        height: 50px;

        .tabs_item {
          display: block;
          padding: 10px;
          line-height: 30px;

          &:hover {
            color: #409eff;
            cursor: pointer;
          }
        }
      }

      .content {
        padding: 20px 10px;

        span {
          font-size: 14px;
        }

        .title_box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .el-icon-plus {
            color: #409eff;
            cursor: pointer;
          }
        }

        .title {
          font-size: 14px;
          font-weight: 550;
          display: block;
          margin-bottom: 20px;
        }

        .cy li {
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 0 0 10px;
          border-radius: 20px;
          margin: 5px 0;

          &:hover {
            background-color: #409eff;
            color: #fff;
            cursor: pointer;
          }

          span {
            margin-left: 10px;
          }
        }

        ul li {
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 0 0 10px;
          border-radius: 20px;
          margin: 5px 0;

          span {
            margin-left: 10px;
          }
        }
      }
    }

    .companyimg {
      line-height: 150px;
    }

    .content_right {
      height: calc(100vh - 700px);
      min-height: 400px;
      position: absolute;
      width: 75%;
      right: 0;
      padding: 10px;

      .head_line {
        display: flex;
        align-items: center;
        height: 80px;

        .el-input {
          width: 200px !important;
        }

        .el-button {
          margin-left: 20px;
        }
      }

      .content_table {
        width: 100%;
        overflow: hidden;
        box-shadow: 0 0 5px rgb(201, 199, 199);
      }
    }
  }

  @media screen and (max-width: 1400px) {
    ._left {
      display: none;
    }
  }
}

.check {
  color: #409eff;
  border-bottom: 2px solid #409eff;
}

.listindex {
  color: #fff;
  background-color: #409eff;
}
</style>