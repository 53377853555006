<template>
  <div style="width: 50%;">
    <div class="mask" v-show="isShowSelect"></div>
    <el-popover :disabled="disabled" placement="bottom-start" popper-class="popoverStyle" :width="popoverWidth" :close-on-click-modal="true"
      trigger="click" @hide="popoverHide">
      <el-tree :disabled="disabled" class="common-tree" :width="width" ref="tree" :data="treeData" :props="obj" :show-checkbox="multiple"
        :node-key="obj.id" :check-strictly="checkStrictly" :default-expanded-keys="defaultKeys || []"
        :expand-on-click-node="false" :highlight-current="true" @check-change="nodeClick" @check="checkNode"></el-tree>
      <el-select :disabled="disabled" :placeholder="Title" slot="reference" ref="select" :size="size" :width="width" v-model="returnDataKeys"
        style="max-height: 50px;" :multiple="multiple" :clearable="clearable" :collapse-tags="collapseTags"
        @click.native="selectClick" @remove-tag="removeTag" @clear="clean" class="tree-select">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <!-- <el-row>
                <el-button v-if="multiple" class="ok" @click="isShowSelect=false" size="mini" type="text">确定</el-button>
            </el-row> -->
    </el-popover>
  </div>
</template>

<script>
export default {
  name: 'tree-select',
  props: {
    //绑定的值
    value: {
      tyep: Object
    },
    Title: {
      type: String,
      default() {
        return '请选择';
      }
    },
    // 树结构数据
    list: {
      type: Array,
      default() {
        return [];
      }
    },
    obj: {
      type: Object,
      required: false,
      default: () => {
        return {
          pid: "pid",
          id: 'id',// ID
          label: 'label',// 显示名称
          level: 'level', //子级字段名
          status: 'status',//路径
          children: 'children',//描述 
        }
      }
    },
    // 配置是否可多选
    multiple: {
      type: Boolean,
      default() {
        return false;
      }
    },
    // 配置是否可清空选择
    clearable: {
      type: Boolean,
      default() {
        return false;
      }
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      }
    },
    // 配置多选时是否将选中值按文字的形式展示
    collapseTags: {
      type: Boolean,
      default() {
        return false;
      }
    },
    // 配置禁用
    disabledTags: {
      type: Boolean,
      default() {
        return false;
      }
    },
    // 显示复选框情况下，是否严格遵循父子不互相关联
    // checkStrictly: {
    //     type: Boolean,
    //     default() {
    //         return false;
    //     }
    // },
    //多选是设置点击节点是否可以选中
    checkClickNode: {
      type: Boolean,
      default() {
        return false;
      }
    },
    //多选时：点击节点展开还是点三角标
    expandClickNode: {
      type: Boolean,
      default() {
        return false;
      }
    },
    // 默认选中的节点key
    // defaultKey: {
    //     type: [Number,String,Array,Object],
    //     default () {
    //         return [];
    //     }
    // },
    size: {
      type: String,
      default() {
        return 'small';
      }
    },
    width: {
      type: String,
      default() {
        return '100%';
      }
    },
    height: {
      type: String,
      default() {
        return '300px';
      }
    }
  },
  //上面是父组件可传入参数
  data() {
    return {
      defaultKeys: [],
      defaultKey: '',
      first: false,//
      popoverWidth: "30px",//下拉框大小
      isShowSelect: false, // 是否显示树状选择器
      options: [],//select option选项
      returnDatas: [],//返回给父组件数组对象
      returnDataKeys: [],//返回父组件数组主键值
      checkStrictly: false,
      num: 0,
      treeData: []
    };
  },
  // computed: {
  //   treeData() { // 若非树状结构，则转化为树状结构数据

  //   },
  // },
  watch: {
    // eslint-disable-next-line no-unused-vars
    isShowSelect(val) {
      // 隐藏select自带的下拉框
      this.$refs.select.blur();
    },
    treeData() {//监听tree数据
      this.$nextTick(() => {
        if (this.options.length == 0) {
          var checkedKeys = this.$refs.tree.getCheckedKeys(); // 所有被选中的节点的 key 所组成的数组数据 
          this.options = checkedKeys.map((item) => {//设置option选项
            var node = this.$refs.tree.getNode(item); // 所有被选中的节点对应的node
            return { label: node.label, value: node.key };
          });
        }
        this.init();
      })
    },
    list: {
      handler(val) {
        let list = JSON.stringify(val).indexOf(this.obj.children) !== -1 ? val : this.switchTree();
        this.treeData = list;
        this.init();
      }, immediate: true
    },
    value: {
      handler(val) {
        if (this.multiple) {
          if (typeof (val) == 'string' && val != '') {
            this.defaultKeys = JSON.parse(val);
          } else {
            this.defaultKeys = val;
          }
        } else {
          this.defaultKey = val
        }
        this.init();
      },
      immediate: true
    },
    returnDataKeys: {
      handler(val) {
        if (this.first || val) {
          this.first = true;
          this.$emit('input', val);
        } else {
          this.first = true;
        }
      }
    },
  },
  methods: {
    init() {
      // eslint-disable-next-line no-undef,no-debugger
      if (this.multiple) {
        this.$nextTick(() => {
          if (this.defaultKeys != undefined && this.defaultKeys.length > 0) {
            // 多选
            if (Object.prototype.toString.call(this.defaultKeys).indexOf("Array") != -1) {
              if (Object.prototype.toString.call(this.defaultKeys[0]).indexOf("Object") != -1) {//对象
                this.setDatas(this.defaultKeys);
                this.checkStrictly = true;
              } else if (Object.prototype.toString.call(this.defaultKeys[0]).indexOf("Number") != -1
                || Object.prototype.toString.call(this.defaultKeys[0]).indexOf("String") != -1) {
                this.setKeys(this.defaultKeys);
                this.checkStrictly = true;
              }  
            }  
          }
        })
      } else {
        // 单选
        if (Object.prototype.toString.call(this.defaultKey).indexOf("Number") != -1
          || Object.prototype.toString.call(this.defaultKey).indexOf("String") != -1
          || Object.prototype.toString.call(this.defaultKey).indexOf("Object") != -1) {
          this.setKey(this.defaultKey);
        }  
      }
    },
    //下拉框select点击[入口]
    selectClick() {
      this.$nextTick(function () {//设置下拉框自适应宽度
        this.popoverWidth = this.$refs.select.$el.clientWidth - 5;
        // this.popoverWidth = '300px';
      })
      //显示下拉框
      return this.isShowSelect = !this.isShowSelect
    },
    //单选: 树点击方法
    nodeClick(data, _node) {
      if (this.disabledTags == true) {
        return;
      }
      if (!this.multiple) {//单选
        this.isShowSelect = false;
        this.setKey(_node.key);
      } else {//多选 
        var checkedKeys = this.$refs.tree.getCheckedKeys(); // 所有被选中的节点的 key 所组成的数组数据 
        var t = [];
        this.options = checkedKeys.map((item) => {//设置option选项
          var node = this.$refs.tree.getNode(item); // 所有被选中的节点对应的node
          t.push(node.data);
          return { label: node.label, value: node.key };
        });
        this.returnDataKeys = this.options.map((item) => {
          return item.value;
        });
        this.returnDatas = t;
        // 草稿id存放
        let ids = this.options.map(item => {
          return item.value
        })
        this.$emit('change', ids);
      }
    },
    checkNode(data, _node) {
      let selected = _node.checkedKeys.indexOf(data.id) // -1未选中,>=0为选中  
      this.$emit('select', data);
      // 选中
      if (selected !== -1) { 
        // // 子节点只要被选中父节点就被选中(需要选中父节点时候调用此方法)
        // this.selectedParent(data)
        // // 统一处理子节点为相同的勾选状态
        // this.uniteChildSame(data, true)

      } else {
        // 未选中 处理子节点全部未选中
        if (data.children.length !== 0) {
          // this.uniteChildSame(data, false)
        }
      }
    },
    // 统一处理父节点为选中
    selectedParent(currentObj) {
      if (currentObj.pid) {
        let node = this.$refs.tree.getNode(currentObj.id);
        let data = node.parent.data;
        this.$nextTick(() => {
          this.$refs.tree.setChecked(data.id, true)
        })
        this.selectedParent(node.parent.data)
      }
    },
    // 统一处理子节点为相同的勾选状态
    uniteChildSame(treeList, isSelected) {
      this.$nextTick(() => {
        this.$refs.tree.setChecked(treeList.id, isSelected)
      })
      for (let i = 0; i < treeList.children.length; i++) {
        this.uniteChildSame(treeList.children[i], isSelected)
      }
    },
    //单选:清空选中
    clean() {
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys([]);//清除树选中key
      })
      this.returnDatas = null;
      this.returnDataKeys = [];
      this.popoverHide();
    },
    //单选:设置、初始化值 key
    setKey(thisKey) {
      this.$refs.tree.setCurrentKey(thisKey);
      var node = this.$refs.tree.getNode(thisKey);
      this.setData(node.data);
    },
    //单选：设置、初始化对象
    setData(data) {
      this.options = [];
      this.options.push({ label: data[this.obj.label], value: data[this.obj.id] });
      this.returnDatas = data;
      this.returnDataKeys = data[this.obj.id]

    },
    //多选:设置、初始化值 keys
    setKeys(thisKeys) {
      this.$refs.tree.setCheckedKeys(thisKeys);
      var checkedKeys = this.$refs.tree.getCheckedKeys();
      this.returnDataKeys = thisKeys;
      var t = [];
      //设置option选项
      checkedKeys.map((item) => {
        //所有被选中的节点对应的node
        var node = this.$refs.tree.getNode(item);
        if (node != null) {
          t.push(node.data);
          return { label: node.label, value: node.key };
        }
      });

      this.returnDatas = t;
      this.popoverHide()
    },
    //多选:设置、初始化对象
    setDatas(data) {
      this.$refs.tree.setCheckedNodes(data);
      this.returnDatas = data;
      var t = [];
      data.map((item) => {//设置option选项
        t.push(item[this.obj.id]);
      });
      this.returnDataKeys = t;
      this.popoverHide()
    },
    // 多选,删除任一select选项的回调
    removeTag(val) {
      this.$refs.tree.setChecked(val, false);//设置为未选中
      var node = this.$refs.tree.getNode(val);//获取节点 
      this.$emit('removeTag', node);
      if (!this.checkStrictly && node.childNodes.length > 0) {
        this.treeToList(node).map(item => {
          if (item.childNodes.length <= 0) {
            this.$refs.tree.setChecked(item, false);
          }
        });
      }
      this.nodeClick();
      this.popoverHide();

    },
    //下拉框关闭执行
    popoverHide() {
      this.$emit('getValue', this.returnDataKeys, this.returnDatas);
    },
    // 多选，清空所有勾选
    clearSelectedNodes() {
      var checkedKeys = this.$refs.tree.getCheckedKeys(); // 所有被选中的节点的 key 所组成的数组数据
      for (let i = 0; i < checkedKeys.length; i++) {
        this.$refs.tree.setChecked(checkedKeys[i], false);
      }
    },

    //树形转为集合
    treeToList(tree) {
      var queen = [];
      var out = [];
      queen = queen.concat(tree);
      while (queen.length) {
        var first = queen.shift();
        if (first.childNodes) {
          queen = queen.concat(first.childNodes);
        }
        out.push(first);
      }
      return out;
    },
    switchTree() {
      return this.buildTree(this.list, this.defaultValue);
    },
    // 将一维的扁平数组转换为多层级对象
    buildTree(data, id) {
      const fa = (id) => {
        const temp = [];
        if (data != null) {
          for (let i = 0; i < data.length; i++) {
            const n = data[i];
            if (n[this.obj.pid] === id) {
              n[this.obj.children] = fa(n[this.obj.id]);
              temp.push(n);
            }
          }
        }
        return temp;
      };
      return fa(id);
    },

  }
};
</script>

<style lang="scss" scoped>
.mask {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 11;
}

.common-tree {
  /* width: 3000px; */
  overflow: auto;
}

/deep/.el-input__inner {
  height: 36px;
}

.tree-select {
  width: 95%;
  max-width: 800px;
  min-width: 150px;
  z-index: 111;
  max-height: 100px !important;

  /deep/.el-select__tags {
    max-height: 100px !important;
    overflow-y: auto !important;

    /* 滚动条样式 */
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  // /deep/.el-input {
  //     max-height: 100px !important;
  //     overflow-y: auto !important;
  // }
}

.ok {
  float: right;
}

.el-row {
  padding-top: 0px !important;
}
</style>

