export default{
    data(){
        return{

        }
    },
    methods:{
        // // 表格排序
        // tableSort(e) {
        //     this.form.SortType = e.order.includes('desc') ? "DESC" : "ASC";
        //     this.form.SortTitle = e.prop;
        //     this.getData();
        // },
        //点击行触发，选中或不选中复选框
        handleRowClick(row) {
            // console.log(row)
            this.$refs.tableList.toggleRowSelection(row);
        },
        handleRowClick1(row){
            this.$refs.dialogList.toggleRowSelection(row);
        }
    }
}