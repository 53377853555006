<template>
  <el-dialog :visible.sync="state" width="545px" @close="close()" top="45px">
    <div class="settin-box">
      <div class="_right">
        <div class="left">
          <p>{{ $t('BasicHome.tips1') }}</p>
          <span class="_tip">{{ $t('BasicHome.tips2') }}</span>
          <div class="_top">
            <div class="top_item" v-for="(item, index) in checkList" :key="index">
              <img :src="getSrc(item.CollectImg)" @error="errorImg($event)" />
              <span>{{ item.MenuName }}</span>
              <img class="_icon" src="@/assets/img/sub.png" alt="" @click="quit(index)">
            </div>
          </div>
          <div class="_title">
            <p>{{ $t('BasicHome.tips3') }}</p>
            <el-input :placeholder="$t('BasicIndex.PleaseEnter')" v-model="str" @input="reset" class="search"
              suffix-icon="el-icon-search">
            </el-input>
          </div>
          <div class="_bottom">
            <div class="top_item" v-for="(item, index) in menuList" :key="index">
              <img :src="getSrc(item.CollectImg)" @error="errorImg($event)" />
              <span>{{ item.MenuName }}</span>
              <img v-show="checkList.length < 8" class="_icon" src="@/assets/img/add.png" alt=""
                @click="CollectMenu(item, index)">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button class="button_info" @click="close">{{ $t('BasicHome.cancel') }}</el-button>
      <el-button class="button_primary" style="margin-left: 20px;" @click="addMenu">{{ $t('BasicHome.confirm') }}</el-button>
    </div>
  </el-dialog>
</template> 
<script>
import {
  AddBasicUserMenu,
  getBasicMenuPCList,
  successTips,
} from "@/api/user";
import mixins from "@/mixins";
export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  name: "SettingView",
  components: {},
  mixins: [mixins],
  computed: {
    state: {
      get() {
        return this.type;
      },
      set() {

      }
    }
  },
  props: {
    type: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    DataList: {
      type: Array,
      default() {
        return []
      }
    },
    seltype: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  data() {
    return {
      // state:'', 
      TotalCount: 0,
      pageNumber: 0,
      UserRoomShow: false,
      addUserImg: '',
      loading: false,
      tabs: 0,
      menutab: 0,
      ID: "",
      level: "",
      userState: '',
      filterText: "",
      drawer: false,
      icon: '',
      UserTitle: '',
      companyChecked: false,
      dialogFormVisible: false,
      userChecked: false,
      checked: true,
      passWordChecked: false,
      list: [],
      title: "",
      currentPage: 1,
      value: '',
      DeptTitle: "",
      menuStr: "",
      dataStr: "",
      SearchName: '',
      DeptID: '',
      tableData: [],
      showList: [],
      financial: [],
      Menu_list: [],
      basic: [],
      storage: [],
      asset: [],
      AllList: [],
      checkedValues: [], // 存储选中的值
      Dept: {
        DeptCode: '',
        DeptName: '',
        ParentDeptID: '',
        ParentDeptID1: '',
        ParentDeptID2: '',
        ParentDeptID3: '',
        ParentDeptID4: '',
        ParentDeptID5: '',
        ParentDeptID6: '',
        ParentDeptID7: '',
        ParentDeptID8: '',
        ParentDeptID9: '',
        ParentDeptID10: '',
        MainUserID: '',
        DeptLevel: '',
        ISCompany: '',
        DeptStatus: '',
        pid: '',
        DeptRemark: ''
      },
      BasicUser: {
        SearchName: '',
        PageSize: '20',
        PageIndex: '0',
        UserCode: '',
        UserName: '',
        UserSex: '',
        UserAccount: '',
        UserPassword: '',
        UserFace: '',
        UserUHF: '',
        UserEPC: '',
        UserImage: '',
        MobilePhone: '',
        TelePhone: '',
        UserEmail: '',
        DeptID: '',
        ManageID: '',
        UserType: '',
        UserStatus: '',
        UserRemark: '',
        UpdateTime: '',
        CreateTime: ''
      },
      BasicUserList: [],
      data: [],
      updatetype: false,
      oldImg: '',
      imageNum: 0,
      multipleSelection: [],
      allMenu: [],
      menuList: [],
      checkList: [],
      str: ''
    };
  },
  mounted() {
    this.state = this.type;
    this.checkList = JSON.parse(JSON.stringify(this.DataList));
    this.getData();
  },
  methods: {
    //收藏菜单
    addMenu() {
      AddBasicUserMenu({ MenuKey: this.checkedValues.join(',') }).then((res) => {
        if (res.Code == 0) {
          this.$emit('add_Menu', false)
          successTips(res.Message);
          this.$emit('close');
          return;
        } else {
          this.$emit('add_Menu', true)
        }
      });
    },
    getData() {
      getBasicMenuPCList().then((res) => {
        if (res.Code == 0) {
          this.data = res.Data;
          this.allMenu = res.Data;
          this.reset();
        } else {
          this.data = [];
        }
      });
    },
    CollectMenu(item, i) {
      this.checkList.push(item);
      this.menuList.splice(i, 1);
      this.reset();
    },
    quit(index) {
      this.checkList.splice(index, 1);
      this.reset();
    },
    reset() {
      let list = JSON.parse(JSON.stringify(this.allMenu));
      this.checkedValues = [];
      for (let val of this.checkList) {
        list = list.filter(item => item.MenuKey != val.MenuKey);
        this.checkedValues.push(val.MenuKey);
      }
      if (this.str) {
        this.menuList = list.filter(item => item.MenuName.includes(this.str));
      } else {
        this.menuList = list;
      }
    },
    getSrc(src) {
      if (src.includes('http://') || src.includes('https://')) {
        return src
      } else {
        return this.$url + src
      }
    },
    errorImg(e) {
      e.srcElement.src = require('@/assets/img/loaderr.png');
      e.srcElement.onerror = null; //防止闪图
    },
    close() {
      this.$emit('close')
    },
  },
};
</script>

<style lang="scss" scoped>
.settin-box {
  width: 525px;
  height: calc(100vh - 275px);
  display: flex;
  padding: 0;
  background-color: #fff;
  border-radius: 5px;
  overflow-y: auto;

  ._right {
    display: flex;
    flex: 1;
    position: relative;

    .left {
      margin: 0 10px;
      height: calc(100vh - 275px);
      padding: 10px;
      display: flex;
      flex-direction: column;

      ._title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .search {
          height: 30px;
          border-radius: 15px;
          margin-right: 12px;
          max-width: 200px;
        }

        /deep/.el-input {
          line-height: 30px !important;
        }

        /deep/.el-input__inner {
          height: 30px !important;
          line-height: 30px !important;
          border-radius: 15px;
          background-color: #fafafa;
          border: 0;
        }

        /deep/.el-input__icon {
          line-height: 30px;
        }
      }

      p {
        color: #1d2129;
        font-size: 16px;
        font-weight: 600;
      }

      ._tip {
        font-size: 14px;
        color: #86909c;
      }

      ._top {
        width: 463px;
        height: 224px;
        padding: 24px 0 0 0;
        border-radius: 8px;
        border: 1px solid #f2f3f5;
        display: flex;
        flex-wrap: wrap;
        margin-top: 24px;
      }

      .top_item {
        width: 115px;
        height: 76px;
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;
        font-size: 12px;
        color: #4e5969;

        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: #eee;
        }

        ._icon {
          width: 14px;
          height: 14px;
          position: absolute;
          top: -2px;
          right: 24px;
          cursor: pointer;
          z-index: 100;
        }
      }

      ._bottom {
        width: 468px;
        height: 240px;
        padding: 10px 0;
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
      }
    }

  }

  @media screen and (max-width: 1400px) {
    ._left {
      display: none;
    }
  }
}
</style>