import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n)
import zh_CN from './zh_CN';
import zh_TW from './zh_TW';
import en from './en';
import enLocale from 'element-ui/lib/locale/lang/en';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import zhtwLocale from 'element-ui/lib/locale/lang/zh-TW';
import ElementLocale from 'element-ui/lib/locale';
const messages = {
    zh_CN: {
        ...zh_CN,
        ...zhLocale
    },
    zh_TW: {
        ...zh_TW,
        ...zhtwLocale
    },
    en: {
        ...en,
        ...enLocale
    }
}

const i18n = new VueI18n({
    locale: localStorage.getItem("locale") || "zh_CN", // 从localStorage中获取 默认中文简体
    messages
});
ElementLocale.i18n((key, value) => i18n.t(key, value))
export default i18n;
