<template>
  <el-dialog :title="Title" :fullscreen="true" :visible.sync="dialogFormVisible" top="20px" height="800px" width="1000px" lock-scroll append-to-body @close="close">
    <div style="float:right;">

      <span class="Refresh"><i class="el-icon-delete-solid" @click="DelGoods()" style="color:#165dff;cursor: pointer;">
          {{ $t('StorageGoods.DelGoods') }}</i> </span> <!--自定义表头-->
      <span class="Refresh"><i class="el-icon-setting" @click="TableOk = true" style="color:#165dff;cursor: pointer;">
          {{ $t('BasicDept.CustomizeHeader') }}</i> </span> <!--自定义表头-->
    </div>
    <el-form ref="form" class="body" label-position="left" label-width="100px" input-width="80px" :model="StorageGoods">
      <el-table border ref="multipleTable2"  :data="GoodsListID" height="calc(100vh - 180px)" @row-click="handleRowClick1" v-loading="loading"
        @selection-change="handleSelectionChange1" @header-dragend="headerDragend">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column :sortable="item.sort" v-for="(item, index) in GoodsTable" :key="index" :prop="item.propName" :label="item.labelName" :width="item.width">
          <template slot-scope="scope">
            <span v-if="item.propName == 'GoodsRFIDEPC'">
              <el-input class="_table_input" v-model="scope.row.GoodsRFIDEPC" :data-rowindex="'GoodsRFIDEPC' + scope.$index"
                @keyup.enter.native="focusNextInputTow(scope.$index + 1, 'GoodsRFIDEPC')"></el-input>
            </span>
            <span v-else-if="item.propName == 'GoodsCount'">
              <el-input class="_table_input" v-model="scope.row.FaKaCount" :data-rowindex="'FaKaCount' + scope.$index"
                @keyup.enter.native="focusNextInputTow(scope.$index + 1, 'FaKaCount')"></el-input>
            </span>
            <span v-else>
              {{ scope.row[item.propName] }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button class="button_info" @click="dialogFormVisible = false" style="margin-right: 7px">{{$t('StorageGoods.cancel')}}</el-button>
      <!-- 添加组织 -->
      <el-button class="button_primary" type="primary" @click="append('form')"> {{$t('StorageGoods.confirm')}} </el-button>
    </div>
    <DragTable v-if="TableOk" TableName="GoodsStockBatchBindRFID" :show="TableOk" @message="Message_">
    </DragTable>
  </el-dialog>
</template> 
  <script> 
import {
  successTips,
  BindStorageGoodsEPCList,
  LoadBasicUserColumn,
  SetBasicUserColumnWidth,
  msgTips
} from "@/api/user";
export default {
  props: {
    GoodsData: {
      type: Array,
      default() {
        return [];
      }
    },
    Title: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
    },
    checked: {
      type: Boolean,
      default: () => {
        return false
      }
    },
  },
  data() {
    return {
      disabledChecked: false,
      dialogFormVisible: false,
      loading: false,
      goodsChecked: false,
      TableOk: false,
      selectTable: [],
      GoodsListID: [],
      GoodsTable: [],
      StorageGoods: {
        SearchName: '',
        PageSize: 20,
        PageIndex: 0,
        GoodsCode: '',
        GoodsName: '',
        CategoryID: '',
        WarehouseID: '',
        HuoPinID: '',
        BatchNumber: '',
        GoodsRFIDEPC: '',
        GoodsImage: '',
        FaKaCount: 0,
        DefaultPrice: '',
        CalcUnit: '',
        AllPrice: '',
        FactStock: '',
        CouldStock: '',
        InKuStatus: '',
        GoodsStatus: '',
        GoodsRemark: '',
        DateOfProduction: '',
        ExpirationTime: '',
        UpdateTime: '',
        CreateTime: ''
      },
    };
  },
  mounted() {
    this.GoodsListID = this.GoodsData;
    this.FaKaStorageHuoPin = this.FaData;
    this.dialogFormVisible = this.show;
    this.getTableColumn();
  },
  methods: {
    //刷新
    Message_(e) {
      if (e == 0) {
        this.FaKaStorageHuoPin = this.FaData;
        this.getTableColumn();
      }
      this.TableOk = false;
    },
    // 表单加载
    getTableColumn() {
      LoadBasicUserColumn({ TableKey: 'GoodsStockBatchBindRFID' }).then(res => {
        if (res.Code == 0) {
          this.GoodsTable = res.Data;
        }
      })
    },
    // 表头拖动事件
    headerDragend(newWidth, data, e) {
     data
      SetBasicUserColumnWidth({ TableKey: 'GoodsStockBatchBindRFID', ColumnKey: e.property, Width: newWidth }).then(res => {
        if (res.Code == 0) {
          successTips(res.Message);
        }
      })
    },
    // dialog中的货品列表选中数据
    handleSelectionChange1(val) {
      if (val.length != 0) {
        this.goodsChecked = false;
        this.selectTable = val;
      } else {
        this.goodsChecked = false;
      }
    },
    //单个发卡选中
    handleRowClick1(row) {
      this.$refs.multipleTable2.toggleRowSelection(row); //设置复选框为选中状态
    },
    // 删除物资
    DelGoods() {
      for (let i = 0; i < this.selectTable.length; i++) {
        let list = JSON.parse(JSON.stringify(this.GoodsListID));
        this.GoodsListID = list.filter((item) => item.index != this.selectTable[i].index);
      }
    },
    //回车换行
    focusNextInputTow(rowIndex, columnName) {
      this.$nextTick(() => {
        let nextInput = null
        if (rowIndex == this.GoodsListID.length) {
          nextInput = document.querySelector(`[data-rowindex="${columnName}0"]`)
        } else {
          nextInput = document.querySelector(
            `[data-rowindex="${columnName}${rowIndex}"]`
          )
        }
        if (nextInput) {
          nextInput.focus()
        }
      })
    },
    //批量发卡
    append() {
      this.DetailList = [];
      for (let i = 0; i < this.GoodsListID.length; i++) {
        let RFID = this.GoodsListID[i].GoodsRFIDEPC;
        let Count = this.GoodsListID[i].FaKaCount;
        let GoodsID = this.GoodsListID[i].GoodsID;
        if (RFID == "" || RFID == this.$t('StorageGoods.NoFaKa')) {
          msgTips(this.$t('StorageGoods.Section') + (i + 1) + this.$t('StorageGoods.line') + this.$t('StorageGoods.BatchFaKaMsgTips'));
          return;
        }
        if (Count == "" || Count == "0") {
          msgTips(this.$t('StorageGoods.Section') + (i + 1) + this.$t('StorageGoods.line') + this.$t('StorageGoods.FaKaCountMesTips'));
          return;
        }
        this.DetailList.push({ GoodsID, Count, RFID })
      }
      BindStorageGoodsEPCList({ Data: this.DetailList }).then((res) => {
        if (res.Code == 0) {
          successTips(res.Message);
          this.$emit("close");
          this.$emit('Message', res.Code);
          return;
        }
      });
    },
    // 关闭
    close() {
      this.$emit("close");
    },
  },
};
</script> 
<style lang="scss" scoped>
.el-dialog__body {
  margin-bottom: 20px !important;
}
.SearchName {
  margin-right: 0% !important;
}

.el-input {
  width: 75% !important;
  margin-right: 8%;
}

.el-select {
  width: 75% !important;
  margin-right: 8%;
}

.textarea {
  width: 75% !important;
}

/deep/ .el-table__empty-block {
  width: 20%;
  min-width: 121%;
  max-width: 20%;
  padding-right: 20%;
}

.companyimg {
  line-height: 150px;
}

.el-icon-plus {
  border: 1px dashed;
  // background-color: #8c939d;
}

//头像
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.avatar-uploader-icon {
  font-size: 15px;
  color: #8c939d;
  width: 135px;
  height: 128;
  line-height: 120px;
  text-align: center;
}

.el-input--mini .el-input__inne {
  height: 39px;
}

.avatar {
  margin-top: -40px;
  width: 135px;
  height: 128;
  display: block;
}

//
.ul_but li {
  margin-bottom: 20px;
}

.ul_but:hover {
  cursor: pointer;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.settin-box {
  width: 100%;
  height: calc(100vh - 20px);
  display: flex;
  background-color: #fff;
  border-radius: 5px;

  ul {
    padding: 0;
    margin: 0;
  }

  ._right {
    display: flex;
    flex: 1;
    position: relative;

    .content_left {
      width: 25%;
      height: calc(100vh - 70px);
      padding: 30px 10px;
      font-size: 19px;

      .tabs {
        display: flex;
        height: 50px;

        .tabs_item {
          display: block;
          padding: 10px;
          line-height: 30px;

          &:hover {
            color: #409eff;
            cursor: pointer;
          }
        }
      }

      .content {
        padding: 20px 10px;

        span {
          font-size: 14px;
        }

        .title_box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .el-icon-plus {
            color: #409eff;
            cursor: pointer;
          }
        }

        .title {
          font-size: 14px;
          font-weight: 550;
          display: block;
          margin-bottom: 20px;
        }

        ul li {
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 0 0 10px;
          border-radius: 20px;
          margin: 10px 0;

          // &:hover {
          //   background-color: #409eff;
          //   color: #fff;
          //   cursor: pointer;
          // }

          span {
            margin-left: 10px;
          }
        }
      }
    }

    .avatars {
      width: 135px;
      height: 128px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 50px;
      line-height: 1;
      background-color: #eee;
      border: 1px solid #ccc;
      cursor: pointer;

      &:hover {
        background-color: #fffefe;
      }
    }

    .avatar {
      width: 135px;
      height: 128px;
      display: block;
    }

    .content_right {
      height: calc(100vh - 70px);
      position: absolute;
      width: 100%;
      right: 0;
      padding: 10px;

      .head_line {
        display: flex;
        align-items: center;
        height: 80px;

        .el-input {
          width: 200px !important;
        }

        .el-button {
          margin-left: 20px;
        }
      }

      .content_table {
        width: 100%;
        overflow: hidden;
        box-shadow: 0 0 5px rgb(201, 199, 199);
      }
    }
  }

  @media screen and (max-width: 1400px) {
    ._left {
      display: none;
    }
  }
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
  // margin-bottom: 50px;
}

._filter {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  span {
    margin-right: 20px;
    font-size: 14px;
    cursor: pointer;

    .el-icon-refresh {
      margin-left: 10px;
      font-size: 16px;
    }
  }

  span i {
    margin-right: 20px;
    color: #1a73e8;
    font-size: 14px;
    cursor: pointer;

    .el-icon-refresh {
      margin-left: 10px;
      font-size: 16px;
    }
  }
}

.check {
  color: #409eff;
  border-bottom: 2px solid #409eff;
}

.listindex {
  color: #fff;
  background-color: #409eff;
}

._filter_btn {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 100px;

  .el-button {
    width: 100px;

    &:nth-child(2) {
      margin-left: 50px;
    }
  }
}

.filter_box {
  padding: 0 30px 0 0;

  ul {
    padding-left: 10px;
  }

  li {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;

    .date {
      // margin-left: 45px;
      width: 300px;

      .el-input:nth-child(2) {
        margin-top: 10px;
      }

      /deep/.el-input {
        width: 300px !important;

        .el-input__inner {
          width: 300px !important;
        }
      }
    }
  }

  font {
    display: inline-block;
    width: 100px;
    font-size: 14px;
    color: #fff;
  }

  .el-select {
    width: 300px;
  }

  .el-input {
    width: 300px;
  }

  ._filter_btn {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 100px;

    .el-button {
      width: 100px;

      &:nth-child(2) {
        margin-left: 50px;
      }
    }
  }
}
</style>