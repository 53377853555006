<template>
  <el-dialog :title="UserTitle" :visible.sync="dialogFormVisible" center height="684px" width="800px" lock-scroll append-to-body @close="close">
    <el-form ref="ruleForm" :rules="rules" label-width="100px" input-width="100px" :model="BasicUser">
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('BasicUserOperation.UserCode')"><!--用户编号-->
            <el-input :placeholder="$t('BasicUserOperation.UserCode')" v-model="BasicUser.UserCode" @input="changeDraft($event, 'UserCode', 0)" maxlength="32"
              show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('BasicUserOperation.UserName')" prop="UserName"><!--用户名称-->
            <el-input :placeholder="$t('BasicUserOperation.UserName')" v-model="BasicUser.UserName" @input="changeDraft($event, 'UserName', 0)" maxlength="32" show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('BasicUserOperation.UserAccount')" prop="UserAccount"><!--用户账号-->
            <el-input :placeholder="$t('BasicUserOperation.UserAccount')" v-model="BasicUser.UserAccount" @input="changeDraft($event, 'UserAccount', 0)" maxlength="32"
              show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('BasicUserOperation.UserPassword')" prop="UserPassword"><!--用户密码-->
            <el-input :placeholder="$t('BasicUserOperation.PleaseEnter')" v-model="BasicUser.UserPassword" @input="changeDraft($event, 'UserPassword', 0)"
              :disabled="passWordChecked == true" class="input-with-select" show-password>
              <el-button slot="append" v-if="UserTitle == $t('BasicUserOperation.UpdateUser')" icon="el-icon-edit" @click="passWordChecked = false, BasicUser.UserPassword = ''">
              </el-button>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('BasicUserOperation.MobilePhone')"><!--移动电话-->
            <el-input :placeholder="$t('BasicUserOperation.MobilePhone')" v-model="BasicUser.MobilePhone" @input="changeDraft($event, 'MobilePhone', 0)" maxlength="11"
              show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('BasicUserOperation.TelePhone')"><!--固定电话-->
            <el-input :placeholder="$t('BasicUserOperation.TelePhone')" v-model="BasicUser.TelePhone" @input="changeDraft($event, 'TelePhone', 0)" maxlength="13"
              show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('BasicUserOperation.UserMailbox')" prop="UserEmail"><!--用户邮箱-->
            <el-input :placeholder="$t('BasicUserOperation.UserMailbox')" v-model="BasicUser.UserEmail" @input="changeDraft($event, 'UserEmail', 0)"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12"><!--所属部门-->
          <el-form-item :label="$t('BasicUserOperation.Dept')" prop="DeptName">
            <el-input :placeholder="$t('BasicUserOperation.Dept')" v-model="BasicUser.DeptName" @input="changeDraft($event, 'DeptName', 0)" readonly class="input-with-select">
              <el-button slot="append" icon="el-icon-thumb" @click="DeptClick(BasicUser)"></el-button>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('BasicUserOperation.UserGender')"><!--用户性别-->
            <el-radio v-model="BasicUser.UserSex" :label="1" @input="changeDraft($event, 'UserSex', 0)">{{$t('BasicUserOperation.Male')}}</el-radio>
            <el-radio v-model="BasicUser.UserSex" :label="2" @input="changeDraft($event, 'UserSex', 0)">{{$t('BasicUserOperation.Female')}}</el-radio>
            <el-radio v-model="BasicUser.UserSex" :label="3" @input="changeDraft($event, 'UserSex', 0)">{{$t('BasicUserOperation.Secrecy')}}</el-radio>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('BasicUserOperation.UserImage')"><!--用户图片-->
            <input ref="imgfiles" :placeholder="$t('BasicUserOperation.UserImage')" type="file" @change="selImg($event)" accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
              style="width: 0;height: 0;" />
            <div v-show="!addUserImg" @click="upImg()" class="avatars">
              <span style="font-size:25px">+</span>
              <!-- <span>上传图片</span> -->
            </div>
            <img @click="upImg()" v-if="addUserImg" :src="addUserImg || BasicUser.UserImage" class="avatar" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('BasicUserOperation.UserRemark')"><!--用户介绍-->
            <el-input style="width:220px" rows="2.5" type="textarea" v-model="BasicUser.UserRemark" @input="changeDraft($event, 'UserRemark', 0)" maxlength="256"
              show-word-limit></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cancel()" class="button_info">{{$t('BasicUserOperation.cancel')}}</el-button><!--取消-->
      <!-- 添加组织 -->
      <el-button type="primary"  class="button_primary" @click="AddUpdate()" :disabled="$store.getters.Loading">{{$t('BasicUserOperation.confirm')}}</el-button><!--确定-->
    </div>
    <!-- 部门组件 -->
    <SelectDept v-if="DeptType" :type="DeptType" @close="DeptType = false" @addMember="addDept" :DataList="DeptList"></SelectDept>
  </el-dialog>
</template> 
<script> 
import {
  successTips,
  AddBasicUser,
  UpdateBasicUser,
  GetBasicDeptTree,
  GetBasicDeptOnlyDeptTree,
  GetBasicUser,
  upLoadFile
} from "@/api/user";
import draft from '@/mixins/draft';
export default {
  props: {
    form: {
      type: Object,
    },
    Notes: {
      type: Array,
      default() {
        return [];
      },
    },
    UserOpertion: {
      type: Object,
    },
    ParentDeptID: {
      type: Number,
      default: 0
    },
    ID: {
      type: Number,
      default: 0
    },
    UserTitle: {
      type: String,
      default: ''
    },
    Superior: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
    },
    state: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  mixins: [draft],
  data() {
    var checkEmail = (rule, value, cb) => {
      //验证邮箱的正则表达式
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (regEmail.test(value) || value == "") {
        //合法的邮箱
        return cb();
      } else if (value != "") {
        //邮箱不合法
        cb(new Error(this.$t('BasicCompany.EmailFormat')));
      }
    };
    return {
      downUrl: "",
      PName: "",
      model: {},
      list: [],
      CDeptID: [],
      DeptIDList: [],
      DeptList: [],
      addUserImg: "",
      draftTitle: ['BasicUser'],
      dialogFormVisible: false,
      DeptType: false,
      loading: false,
      passWordChecked: false,
      oldImg: '',
      Dept: {
        DeptCode: '',
        DeptName: '',
        ParentDeptID: '',
        ParentDeptID1: '',
        ParentDeptID2: '',
        ParentDeptID3: '',
        ParentDeptID4: '',
        ParentDeptID5: '',
        ParentDeptID6: '',
        ParentDeptID7: '',
        ParentDeptID8: '',
        ParentDeptID9: '',
        ParentDeptID10: '',
        MainUserID: '',
        DeptLevel: '',
        ISCompany: '',
        DeptStatus: '',
        pid: '',
        DeptRemark: ''
      },
      BasicUser: {
        UserID: '',
        DeptName: '',
        CDeptID: '',
        UserCode: '',
        UserName: '',
        UserSex: 3,
        UserAccount: '',
        UserPassword: '',
        UserFace: '',
        UserUHF: '',
        UserEPC: '',
        UserImage: '',
        MobilePhone: '',
        TelePhone: '',
        UserEmail: '',
        DeptID: '',
        ManageID: '',
        UserType: '',
        UserStatus: '',
        UserRemark: '',
        UpdateTime: '',
        CreateTime: ''
      },
      rules: {
        DeptCode: [
          { required: true, message: this.$t('BasicUserOperation.PleaseEnter'), trigger: 'blur' },
          { min: 1, max: 32, message: this.$t('BasicUserOperation.limit'), trigger: 'blur' }
        ],
        DeptName: [
          { required: true, message: this.$t('BasicUserOperation.PleaseEnter'), trigger: 'blur' },
          { min: 1, max: 32, message: this.$t('BasicUserOperation.limit'), trigger: 'blur' }
        ],
        UserCode: [
          { required: true, message: this.$t('BasicUserOperation.PleaseEnter'), trigger: 'blur' },
          { min: 1, max: 32, message: this.$t('BasicUserOperation.limit'), trigger: 'blur' }
        ],
        UserName: [
          { required: true, message: this.$t('BasicUserOperation.PleaseEnter'), trigger: 'blur' },
          { min: 1, max: 32, message: this.$t('BasicUserOperation.limit'), trigger: 'blur' }
        ],
        UserAccount: [
          { required: true, message: this.$t('BasicUserOperation.PleaseEnter'), trigger: 'blur' },
          { min: 1, max: 32, message: this.$t('BasicUserOperation.limit'), trigger: 'blur' }
        ],
        CDeptID: [
          { required: true, message: this.$t('BasicUserOperation.PleaseSelect'), trigger: 'change' }
        ],
        DeptID: [
          { required: true, message: this.$t('BasicUserOperation.PleaseSelect'), trigger: 'change' }
        ],
        UserEmail: [
          { validator: checkEmail }
        ],
        UserPassword: [
          { required: true, message: this.$t('BasicUserOperation.PleaseEnter'), trigger: 'blur' },
          { min: 6, max: 32, message: this.$t('BasicUserOperation.Passwordlimit'), trigger: 'blur' }
        ]
      },
    };
  },
  mounted() {
    if (this.state) {
      console.log(1);
      this.BasicUser = JSON.parse(window.localStorage.getItem(this.draftTitle[this.draftIndex]));
      if (this.BasicUser) {
        //使用部门渲染
        GetBasicDeptOnlyDeptTree({ DeptID: this.BasicUser.CDeptID }).then((res) => {
          if (res.Code == 0) {
            this.DeptIDList = res.Data;
          }
        });
      }
    }
    if (this.UserOpertion.Opertion == 'Update') {
      this.DeptShow();
      this.addUserImg = '';
      GetBasicUser({ UserID: this.ID }).then((res) => {
        if (res.Code == 0 && res.Data) {
          this.BasicUser = res.Data;
          this.addUserImg = res.Data.UserImage;
          //使用部门渲染 
          GetBasicDeptOnlyDeptTree({ DeptID: res.Data.CDeptID }).then((res) => {
            if (res.Code == 0) {
              this.DeptIDList = res.Data;
            }
          });
        }
      });
      this.updatetype = true;
      this.userChecked = true;
      this.passWordChecked = true;
    } else {
      this.BasicUser.CDeptID = this.$store.state.userinfo.CDeptID;
      //使用部门渲染 
      GetBasicDeptOnlyDeptTree({ DeptID: this.$store.state.userinfo.CDeptID }).then((res) => {
        if (res.Code == 0) {
          this.DeptIDList = res.Data;
          this.BasicUser.DeptID = this.$store.state.userinfo.DeptID;
        }
      });
    }
    this.DeptShow();
    this.PName = this.Superior;
    this.dialogFormVisible = this.show;

  },
  methods: {
    //部门组件事件
    addDept(e) {
      this.BasicUser.DeptID = e.DeptID;
      this.BasicUser.DeptName = e.DeptName;
      this.$refs['ruleForm'].clearValidate(['DeptName']);  //移除表单某个字段的校验 
      this.DeptType = false;
    },
    //部门点击事件
    DeptClick(data) {
      if (data.DeptName == "") {
        data.DeptID = "";
      }
      this.DeptList = [];
      if (data) {
        this.DeptType = true;
        this.DeptList.push(data.DeptID);
      }
    },
    //下拉框搜索
    selectClick(e) {
      //使用部门渲染
      GetBasicDeptOnlyDeptTree({ DeptID: e.id }).then((res) => {
        if (res.TotalCount == 0) {
          this.DeptIDList = [];
        }
        if (res.Code == 0) {
          this.DeptIDList = JSON.parse(JSON.stringify(res.Data));
          this.BasicUser.DeptName = "";
          this.BasicUser.DeptID = '';
        }
      });
    },
    //领用公司渲染
    DeptShow() {
      GetBasicDeptTree({ OnlyCompany: 1 }).then((res) => {
        if (res.Code == 0) {
          this.CDeptID = res.Data;
        }
      });
    },
    //取消
    cancel() {
      // window.localStorage.setItem(this.draftTitle[this.draftIndex], null);
      this.dialogFormVisible = false;

    },
    //操作
    upImg() {
      this.$refs.imgfiles.click();
    },
    selImg(e) {
      if (e.target.files.length == 0) {
        return
      }
      let file = e.target.files[0];
      if (this.LimitaTionImg(file)) {
        return;
      }
      this.upfile = file;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        this.addUserImg = e.target.result;
      }
    },
    //用户增加页面 
    async AddUpdate() {
      let state = false;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          state = true;
        }
      })
      if (!state) return
      this.loading = true;
      if (this.upfile) {
        let formData = new FormData();
        formData.append('file', this.upfile);
        let ret = await upLoadFile(formData);
        if (ret.Code == 0) {
          this.BasicUser.UserImage = ret.Data;
        }
      }
      //添加修改用户
      if (this.UserOpertion.Opertion == 'Add') {
        AddBasicUser(this.BasicUser).then((res) => {
          if (res.Code == 0) {
            successTips(res.Message);
            this.oldImg = '';
            this.userChecked = false;
            this.BasicUser.UserImage = '';
            this.imageNum = 0;
            this.draftState[this.draftIndex] = false;
            window.localStorage.setItem(this.draftTitle[this.draftIndex], null);
            this.$emit('UserMessage', res.Code);
            this.dialogFormVisible = false;
          }
        });
      } else if (this.UserOpertion.Opertion == 'Update') {
        UpdateBasicUser(this.BasicUser).then((res) => {
          if (res.Code == 0) {
            successTips(res.Message);
            this.ok = true;
            this.BasicUser.UserImage = '';
            this.imageNum = 0;
            this.updatetype = false;
            this.userChecked = false;
            if (this.oldImg) {
              this.clearImg(this.oldImg);
            }
            this.oldImg = '';
            this.$emit('UserMessage', res.Code);
            this.dialogFormVisible = false;
          }
        });
        this.ok = false;
      }
      this.loading = false;
      this.upfile = null;
    },
    close() {
      this.$emit("close");
    },
  },
};
  </script> 
  <style lang="scss" scoped>
  ::v-deep .el-dialog--center .el-dialog__footer{
    text-align: end !important;
  }
.el-dialog__header {
  padding: 20px 39px 10px !important;
}
.cancel {
  background-color: #f2f3f5;
  border: 0px;
}
.confirm {
  background-color: #165dff;
  border: 0px;
}
.el-dialog__footer {
  margin-left: 44px !important;
}
.input-with-select.el-input.el-input--suffix {
  margin-left: -9px;
}
.el-input {
  width: 220px;
}
.el-dialog__body {
  padding: 24px !important;
}
.el-form-item__content {
  width: 220px;
}
.treeTable {
  overflow: auto;
  max-height: 600px;
}

::v-deep .el-form-item > .el-form-item__label::before {
  content: "" !important;
  color: #000 !important;
}

::v-deep .el-form-item__label::after {
  content: "*";
  color: #ffffff;
  margin-left: 4px;
}

::v-deep .is-required .el-form-item__label::after {
  content: "*";
  color: #ff0000;
  margin-left: 4px;
}

._filter el-row el-col {
  margin-left: 20px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

//头像
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.el-icon-plus {
  border: 1px dashed;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

._filter {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  span {
    margin-right: 20px;
    font-size: 14px;
    cursor: pointer;

    .el-icon-refresh {
      margin-left: 1px;
      font-size: 16px;
    }
  }
}

._filter_btn {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 100px;

  .el-button {
    width: 100px;

    &:nth-child(2) {
      margin-left: 50px;
    }
  }
}

.avatar-uploader-icon {
  font-size: 15px;
  color: #8c939d;
  width: 135px;
  height: 128;
  line-height: 120px;
  text-align: center;
}

.avatars {
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  line-height: 1;
  background-color: #ffffff;
  border: 1px dashed #ccc;
  cursor: pointer;
  margin-top: -40px;

  &:hover {
    background-color: #fffefe;
  }
}

.avatar {
  margin-top: -40px;
  width: 72px;
  height: 72px;
  display: block;
}

//
.ul_but li {
  margin-bottom: 20px;
}

.ul_but:hover {
  cursor: pointer;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.custom-tree-node-checked {
  color: #409eff;
}

.settin-box {
  width: 100%;
  height: calc(100vh - 20px);
  display: flex;
  background-color: #fff;
  border-radius: 5px;

  ul {
    padding: 0;
    margin: 0;
  }

  ._right {
    display: flex;
    flex: 1;
    position: relative;

    .companyimg {
      line-height: 150px;
    }
  }

  @media screen and (max-width: 1400px) {
    ._left {
      display: none;
    }
  }
}

.check {
  color: #409eff;
  border-bottom: 2px solid #409eff;
}

.listindex {
  color: #fff;
  background-color: #409eff;
}
</style>