<template>
  <el-dialog :title="Title" :visible="dialogFormVisible" center top="10%" height="800px" width="1000px" lock-scroll append-to-body @close="close">
    <el-form :rules="rules" ref="form" label-position="left" label-width="100px" input-width="100px" :model="BasicLableGroup">
      <el-form-item :label="$t('BasicLableOperation.LableGroupCode')"><!--分组编号-->
        <el-input :placeholder="$t('BasicLableOperation.LableGroupCode')" v-model="BasicLableGroup.LableGroupCode" maxlength="32" show-word-limit></el-input>
      </el-form-item>
      <el-form-item :label="$t('BasicLableOperation.LableGroupName')" prop="LableGroupName"><!--分组名称-->
        <el-input :placeholder="$t('BasicLableOperation.LableGroupName')" v-model="BasicLableGroup.LableGroupName"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false" style="margin-right: 7px;">{{$t('BasicLableOperation.cancel')}}</el-button><!--取消-->
      <el-button type="primary" @click="append('form')" :disabled="$store.getters.Loading">{{$t('BasicLableOperation.confirm')}}</el-button><!--确定-->
    </div>
  </el-dialog>
</template> 
<script> 
import {
  successTips,
  AddBasicLableGroup,
  GetBasicLableGroup,
  UpdateBasicLableGroup
} from "@/api/user";
export default {
  props: {
    form: {
      type: Object,
    },
    LableOpertion:{
      type:Object
    },
    Notes: {
      type: Array,
      default() {
        return [];
      },
    },
    ParentDeptID: {
      type: Number,
      default: 0
    },
    ID: {
      type: Number,
      default: 0
    },
    Title: {
      type: String,
      default: ''
    },
    Superior: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      downUrl: "",
      PName: "",
      model: {},
      list: [],
      dialogFormVisible: false,
      loading: false,
      rules: {
        LableGroupCode: [
          { required: true, message: this.$t('BasicLableOperation.PleaseEnter'), trigger: 'blur' },
          { min: 1, max: 32, message: this.$t('BasicLableOperation.LengthLimit'), trigger: 'blur' }
        ],
        LableGroupName: [
          { required: true, message: this.$t('BasicLableOperation.PleaseEnter'), trigger: 'blur' },
          { min: 1, max: 32, message: this.$t('BasicLableOperation.LengthLimit'), trigger: 'blur' }
        ],
      },
      BasicLableGroup: {
        BasicLableGroupID: '',
        CompanyID: '',
        LableGroupCode: '',
        LableGroupName: '',
        ISDefault: '',
        LableGroupStatus: '',
        LableGroupRemark: '',
        UpdateTime: '',
        CreateTime: ''
      },

    };
  },
  mounted() {
    this.PName = this.Superior;
    this.dialogFormVisible = this.show;
    if (this.LableOpertion.Opertion == 'Update') {
      GetBasicLableGroup({ LableGroupID: this.ID }).then((res) => {
        if (res.Code == 0) {
          this.BasicLableGroup = res.Data;
        }
      });
    }
  },
  methods: {
    //增改接口请求
    append(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid == false) {
          return;
        } else {
          //增改
          if (this.LableOpertion.Opertion == 'Add') {
            AddBasicLableGroup(this.BasicLableGroup).then(
              (res) => {
                if (res.Code == 0) {
                  successTips(res.Message);
                  this.dialogFormVisible = false; //弹窗页面是否开启 
                  this.LableGroupID = "";
                  this.$emit('message', res.Code);
                }
              }
            );
          } else if (this.LableOpertion.Opertion == 'Update') {
            UpdateBasicLableGroup(this.BasicLableGroup).then(
              (res) => {
                if (res.Code == 0) {
                  this.ok = true;
                  successTips(res.Message);
                  this.dialogFormVisible = false; //弹窗页面是否开启 
                  this.LableGroupID = "";
                  this.menutab = -1;
                  this.$emit('message', res.Code);
                }
              }
            );

          }
        }
      })

    },
    close() {
      this.$emit("close");
    },
  },
};
  </script>
  
  <style>
</style>
  