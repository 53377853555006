<template>
  <div class="_dialog">
    <div class="_top">
      <i @click="close" style="cursor: pointer;" class="el-icon-d-arrow-left"></i>
      <span @click="close">{{ $t('StorageGoods.back') }}</span>
      <div style="width: 1px;height: 20px;background-color:#e5e6eb;"></div>
      <font>{{ Title }}</font>
    </div>
    <div class="span_pandian">
      <span>{{ $t('AssetPanDian.tips11') }}({{UnpackagedCount}}{{ $t('AssetPanDian.strip') }})</span>
    </div>
    <div class="_filter" style="margin: 0 0 10px 0;">
      <div>
        <el-button class="button_info" style="margin-right:10px" :disabled="ok == true||!panOk" @click="BatchDriven()">{{ $t('AssetPanDian.BatchDriven')
        }}</el-button><!--批量已盘-->
        <el-button class="button_info" style="margin-right:10px" :disabled="ok == true||!panOk" @click="BatchUnplugged()">{{ $t('AssetPanDian.BatchUnplugged')
        }}</el-button><!--批量未盘-->
        <el-divider direction="vertical" style="margin-right:10px"></el-divider>
        <el-switch v-model="panOk" style="margin:10px">
        </el-switch>
        <span>{{$t('AssetPanDian.tips10')}}</span>
      </div>

      <span class="Refresh"><i class="el-icon-setting" @click="TableOk2 = true" style="color:#165dff;cursor: pointer;">
          {{ $t('BasicDept.CustomizeHeader') }}</i> </span> <!--自定义表头-->
    </div>
    <div style="position: relative;width: 100%;height: calc(100vh - 710px);">
      <el-table :key="itemKey" style="position: absolute;" border="" :data="Unpackaged" height="calc(100vh - 710px)" v-loading="loading" stripe
        @selection-change="handleSelectionChange1" ref="dialogList" @row-click="PanClick" @header-dragend="headerDragend">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column :sortable="item.sort" v-for="(item, index) in GoodsTable" :key="index" :prop="item.propName" :label="item.labelName" :width="item.width">
          <template slot-scope="scope">
            <ImageView v-if="item.propName == 'GoodsImage'" :url="scope.row.GoodsImage"></ImageView>
            <span v-else-if="item.propName == 'PanDianStatusName'" :style="`color:${scope.row.PanDianStatusColor};background:${scope.row.PanDianStatusColor}1a;`">{{
                scope.row.PanDianStatusName
              }}</span>
            <span v-else>
              {{ scope.row[item.propName] }}
            </span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('AssetAllot.operate')" width="150px" align="center" v-if="!panOk">
          <template slot-scope="scope">
            <div class="_operate">
              <el-link type="danger" @click.stop="disc(1,scope.row)">{{
                $t('AssetPanDian.state2') }}</el-link>
              <el-link type="danger" @click.stop="disc(2,scope.row)">{{
                $t('AssetPanDian.state1') }}</el-link>
            </div>

          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="span_pandian">
      <span>{{ $t('AssetPanDian.tips11') }}({{AlreadyDiskCount}}{{ $t('AssetPanDian.strip') }})</span>
    </div>
    <div class="_filter" style="margin: 0 0 10px 0;">
      <div>
        <el-button class="button_info" style="margin-right:10px" :disabled="pandianok == true" @click="HeavyPlate()">{{ $t('AssetPanDian.BatchReDisk')
        }}</el-button><!--批量重盘-->
      </div>
    </div>
    <div style="position: relative;width: 100%;height: calc(100vh - 710px);">
      <el-table :key="itemKey" style="position: absolute;" border="" :data="AlreadyDisk" height="calc(100vh - 710px)" stripe @selection-change="handleSelectionChange2"
        ref="dialogList2" @row-click="PanClick2" @header-dragend="headerDragend">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column :sortable="item.sort" v-for="(item, index) in GoodsTable" :key="index" :prop="item.propName" :label="item.labelName" :width="item.width">
          <template slot-scope="scope">
            <ImageView v-if="item.propName == 'GoodsImage'" :url="scope.row.GoodsImage"></ImageView>
            <span v-else-if="item.propName == 'PanDianStatusName'" :style="`color:${scope.row.PanDianStatusColor};background:${scope.row.PanDianStatusColor}1a;`">{{
                scope.row.PanDianStatusName
              }}</span>
            <span v-else>
              {{ scope.row[item.propName] }}
            </span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('AssetAllot.operate')" width="100px" align="center">
          <template slot-scope="scope">
            <div class="_operate">
              <el-link type="danger" @click.stop="disc(3,scope.row)">{{
                $t('AssetPanDian.HeavyPlate') }}</el-link>
            </div>

          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="_footer">
      <el-button class="button_info" @click="close" style="margin-right: 7px;">{{ $t('AssetAllotAdd.quit')
      }}</el-button>
      <el-button class="button_primary" @click="approvedAssetPanDian()"> {{ $t('AssetAllotAdd.query') }} </el-button>
    </div>

    <DragTable v-if="TableOk" TableName="AssetPanDianPanDian" :show="TableOk" @message="Message_">
    </DragTable>
    <!-- 盘点修改状态页面 -->
    <el-dialog :title="PandianTitle" :visible.sync="UpdatePanDianData" center class="dialog" width="1000px" lock-scroll @close="close">
      <el-form ref="form" :rules="rules" label-position="left" label-width="120px" input-width="0px" :model="panForm">
        <div>
          <el-form-item :label="$t('AssetPanDian.PanDianStatus')" prop="PanDianStatus">
            <el-select v-model="panForm.PanDianStatus" :placeholder="$t('AssetPanDian.sel')" style="width:85%;">
              <el-option :key="1" :value="1" :label="$t('AssetPanDian.state2')">
              </el-option>
              <el-option :key="5" :value="5" :label="$t('AssetPanDian.state1')">
              </el-option>

            </el-select>
          </el-form-item>
          <el-form-item :label="$t('AssetListAdd.PutFactAddress')">
            <el-input :placeholder="$t('AssetListAdd.PutFactAddress')" style="width: 85%;" v-model="panForm.PutFactAddress" @input="changeDraft($event, 'PutFactAddress', 0)"
              class="input-with-select" maxlength="32" show-word-limit>
            </el-input>
          </el-form-item>
          <!-- 盘点人 -->
          <el-form-item :label="$t('AssetAllotAdd.AllotUserName')">
            <el-input :readonly="true" :placeholder="$t('AssetAllotAdd.AllotUserName')" v-model="panForm.UserName" style="width: 85%;" class="input-with-select">
              <el-button slot="append" icon="el-icon-user" @click="userClick(panForm)"></el-button>
            </el-input>
          </el-form-item>
          <!--部门-->
          <el-form-item :label="$t('AssetPanDian.DeptID')">
            <el-input :placeholder="$t('AssetPanDian.DeptID')" v-model="panForm.DeptName" readonly class="input-with-select" style="width: 85%">
              <el-button slot="append" icon="el-icon-thumb" @click="DeptClick(panForm)"></el-button>
            </el-input>
          </el-form-item>
          <!--区域-->
          <el-form-item :label="$t('AssetPanDian.DistrictID')">
            <el-input :placeholder="$t('AssetPanDian.DistrictID')" v-model="panForm.DistrictName" readonly class="input-with-select" style="width: 85%">
              <el-button slot="append" icon="el-icon-thumb" @click="RegionClick(panForm)"></el-button>
            </el-input>
          </el-form-item>

          <el-form-item :label="$t('AssetPanDian.PanDianRemark')">
            <el-input type="textarea" rows="3" v-model="panForm.PanDianRemark" maxlength="512" show-word-limit style="width:85%"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="UpdatePanDianData = false" style="margin-right: 7px">{{ $t('AssetPanDian.quit')
        }}</el-button><!--取消-->
        <!-- 添加组织 -->
        <el-button type="primary" @click="append()"> {{ $t('AssetPanDian.query') }} </el-button><!--确定-->
      </div>
      <!-- 调拨人组件 -->
      <AdminUser v-if="PanDianType" :type="PanDianType" @close="PanDianType = false" @addMember="addAssetPan" :DataList="UserList">
      </AdminUser>
      <!-- 区域组件 -->
      <SelectRegion v-if="RegionType" :type="RegionType" @close="RegionType = false" @addMember="addRegion" :DataList="RegionList"></SelectRegion>
      <!-- 部门组件 -->
      <SelectDept v-if="DeptType" :type="DeptType" @close="DeptType = false" @addMember="addDept" :DataList="DeptList">
      </SelectDept>
    </el-dialog>
  </div>
</template>
<script>
import {
  GetBasicDeptTree,
  MultiPanDianAssetPanDian,
  ApprovedAssetPanDian,
  SetBasicUserColumnWidth,
  GetAssetPanDianDetailList,
  LoadBasicUserColumn,
  successTips
} from "@/api/user";
import draft from '@/mixins/draft'
export default {
  mixins: [draft],
  components: {},
  props: {
    PanDianID: {
      type: Number
    },
    Title: {
      type: String
    },
    show: {
      type: Boolean,
      default: () => {
        return false
      }
    },


  },
  data() {
    return {
      RegionType: false,
      DeptType: false,
      UnpackagedCount: 0,
      AlreadyDiskCount: 0,
      LinData: [],
      panForm: {
        PanDianID: 0,
        DeptName: '',
        PanDianDetailIDList: '',
        CDeptID: '',
        DeptID: '',
        DistrictID: '',
        DistrictName: '',
        PutFactAddress: '',
        UserID: '',
        UserName: '',
        PanDianRemark: ''
      },

      itemKey: '',
      panOk: true,
      plok: true,
      UpdatePanDianData: false,
      showstate: false,
      querystate: true,
      delstate: true,
      operated: true,
      receive: true,
      refuse: true,
      loading: false,
      pandianok: false,
      TotalCount: 0,
      okChecked: false,
      inShow: false,
      detailState: true,
      TableChecked: false,
      AllotCDeptType: false,
      ReceiveCDeptType: false,
      TableOk: false,
      RegionList: [],
      DeptList: [],
      OutTableData: [],
      AllotCDeptList: [],
      ReceiveCDeptList: [],
      multipleSelection: [],
      Unpackaged: [],
      AlreadyDisk: [],
      ID: "",
      AllotID: '',
      drawer: false,
      GoodsStatusName: '调拨',
      filterText: "",
      title: "",
      type: false,
      type2: false,
      gooodsType: false,
      checked: true,
      goodsChecked: true,
      OutChecked: false,
      Times: null,
      activeName: 'first',
      index: '0',
      DataList: [],
      AllotCDeptID: [],
      AllotStatus: [],
      ReceiveCDeptID: [],
      JobCDeptID: [],
      UseDeptID: [],
      UseDistrictID: [],
      tableData: [],
      BorrowReturnID: '',
      AssetAllot: {
        AllotID: '',
        CompanyID: '',
        AllotCode: '',
        JobCDeptID: '',
        JobCDeptName: '',
        AllotCDeptID: '',
        AllotCDeptName: '',
        AllotStatusName: '',
        AllotStatusColor: '',
        AllotUserID: '',
        AllotUserName: '',
        AllotOperator: '',
        AllotRemark: '',
        AllotTime: new Date().toLocaleString().replaceAll('/', '-'),
        ReceiveCDeptID: '',
        ReceiveCDeptName: '',
        ReceiveUserID: '',
        ReceiveUserName: '',
        ReceiveOperator: '',
        ReceiveRemark: '',
        ReceiveTime: new Date().toLocaleString().replaceAll('/', '-'),
        AllotStatus: '',
        UpdateTime: '',
        CreateTime: ''
      },
      rules: {
        PanDianStatus: [
          { required: true, message: this.$t('AssetPanDian.tips7'), trigger: "blur" },
        ]
      },
      AssetAllotNotes: [],
      OutKuNotes: [],
      DetailList: [],
      selectTable: [],
      UserList: [],
      GoodsTable: [],
      data: [],
      AdminList: [],
      Notes: [],
      ok: true,
      value: null,
      OperateType: '',
      PandianTitle: '',
      NotesID: null,
      upload: false,
      PanDianType: false,
      uptype: '',
      exportColumn: [],
      ExportType: '',
      errString: "",
      errUrl: '',
      viewtype: '',
      showdetail: false,
      draftTitle: ['AssetAllot']
    };
  },
  mounted() {
    this.showstate = this.show;
    this.getTableColumn();
    this.GetTableData();


  },
  methods: {
    //区域组件事件
    addRegion(e) {
      this.panForm.DistrictID = e.DistrictID;
      this.panForm.DistrictName = e.DistrictName;
      this.RegionType = false;
    },
    //区域点击事件
    RegionClick(data) {
      this.RegionList = [];
      if (data) {
        this.RegionType = true;
        this.RegionList.push(data.DistrictID);
      }
    },
    //部门点击事件
    DeptClick(data) {
      this.DeptList = [];
      if (data) {
        this.DeptType = true;
        this.DeptList.push(data.DeptID);
      }
    },
    //部门组件事件
    addDept(e) {
      this.panForm.DeptID = e.DeptID;
      this.panForm.DeptName = e.DeptName;
      this.DeptType = false;
    },

    //盘点
    disc(e, data) {
      let DetailList = [];
      DetailList.push(data.PanDianDetailID);
      this.panForm.PanDianDetailIDList = JSON.stringify(DetailList);
      this.panForm.PanDianID = this.PanDianID;
      if (e == 1) {
        this.panForm.PanDianStatus = 1;
      } else if (e == 2) {
        this.panForm.PanDianStatus = 5;
      } else if (e == 3) {
        this.panForm.PanDianStatus = 3;
      }
      MultiPanDianAssetPanDian(this.panForm).then((res) => {
        if (res.Code == 0) {
          successTips(res.Message);
          this.GetTableData();
        }
      });
    },
    //批量已盘
    BatchDriven() {
      this.panForm.CDeptID = '';
      this.panForm.DeptID = '',
        this.panForm.DistrictID = '',
        this.panForm.PutFactAddress = '',
        this.panForm.UserID = '',
        this.panForm.PanDianRemark = ''
      this.panForm.PanDianID = this.PanDianID;
      this.PandianTitle = this.$t('AssetPanDian.topandian');
      this.UpdatePanDianData = true;
    },
    //批量盘亏
    BatchUnplugged() {
      this.panForm.PanDianID = this.PanDianID;
      this.panForm.PanDianStatus = 5;
      MultiPanDianAssetPanDian(this.panForm).then((res) => {
        if (res.Code == 0) {
          successTips(res.Message);
          this.GetTableData();
        }
      });
    },
    //批量未盘
    HeavyPlate() {
      this.panForm.PanDianID = this.PanDianID;
      this.panForm.PanDianStatus = 3;
      MultiPanDianAssetPanDian(this.panForm).then((res) => {
        if (res.Code == 0) {
          successTips(res.Message);
          this.GetTableData();
        }
      });
    },
    //提交盘点
    approvedAssetPanDian() {
      ApprovedAssetPanDian({ PanDianID: this.PanDianID }).then((res) => {
        if (res.Code == 0) {
          successTips(res.Message);
          this.getData();
          this.$emit('success');
        }
      });
    },
    append() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          MultiPanDianAssetPanDian(this.panForm).then((res) => {
            if (res.Code == 0) {
              successTips(res.Message);
              this.UpdatePanDianData = false;
              this.GetTableData();
            }
          });

        }
      });
    },
    GetTableData() {
      this.loading = true;
      GetAssetPanDianDetailList({ PanDianID: this.PanDianID }).then((res) => {
        if (res.Code == 0 && res.Data != null) {
          this.Notes = res.Data.DetailList;
          let list = JSON.parse(JSON.stringify(this.Notes));
          this.Unpackaged = list.filter(item => item.PanDianResult == 3);
          this.UnpackagedCount = this.Unpackaged.length;
          let list_lin = JSON.parse(JSON.stringify(this.Notes));
          this.AlreadyDisk = list_lin.filter(item => item.PanDianResult != 3);
          this.AlreadyDiskCount = this.AlreadyDisk.length;
          this.loading = false;
        }
      });
      this.loading = false;

    },
    //盘点页面选择
    PanClick(row) {
      this.$refs.dialogList.toggleRowSelection(row);//多选
    },
    //盘点页面选择
    PanClick2(row) {
      this.$refs.dialogList2.toggleRowSelection(row);//多选
    },
    // 表头拖动事件
    headerDragend(newWidth, data, e) {
      data
      if (e.property != null) {
        SetBasicUserColumnWidth({ TableKey: 'AssetPanDianPanDian', ColumnKey: e.property, Width: newWidth }).then(res => {
          if (res.Code == 0) {
            res
          }
        })
      }
    },
    // 表单加载
    getTableColumn() {
      LoadBasicUserColumn({ TableKey: 'AssetPanDianPanDian' }).then(res => {
        if (res.Code == 0) {
          this.GoodsTable = res.Data;
        }
      })
    },
    //刷新
    Message_() {
      let lin_Data = this.AssetAllotNotes;
      this.AssetAllotNotes = lin_Data;
      this.getTableColumn();
      this.itemKey = Math.random();
      this.TableOk = false;
    },
    //所属公司点击事件
    CDeptClick(data) {
      this.CDeptList = [];
      if (data) {
        this.CDeptType = true;
        this.CDeptList.push(data.UseCDeptID);
      }
    },
    //调出组件事件
    addAllotCDept(e) {
      this.AllotCDeptType = false;
      this.AssetAllot.AllotCDeptID = e.DeptID;
      this.AssetAllot.AllotCDeptName = e.DeptName;
      this.changeDraft(e.DeptID, 'AllotCDeptID', 0);
      this.changeDraft(e.DeptName, 'AllotCDeptName', 0);
    },
    //接收组件事件
    addReceiveCDept(e) {
      this.ReceiveCDeptType = false;
      this.AssetAllot.ReceiveCDeptID = e.DeptID;
      this.AssetAllot.ReceiveCDeptName = e.DeptName;
      this.changeDraft(e.DeptID, 'ReceiveCDeptID', 0);
      this.changeDraft(e.DeptName, 'ReceiveCDeptName', 0);
    },
    getTree() {
      GetBasicDeptTree({ OnlyCompany: 1 }).then((res) => {
        if (res.Code == 0) {
          this.JobCDeptID = res.Data;
          this.AllotCDeptID = res.Data;
          this.ReceiveCDeptID = res.Data;
        }
      });
    },
    //选择调拨公司清空明细
    allSelect() {
      this.AssetAllotNotes = [];
    },

    //盘点人点击事件
    userClick(data) {
      this.UserList = [];
      if (data) {
        this.PanDianType = true;
        this.UserList.push(data.UserID);
      }
    },

    //调出公司点击事件
    AllotCDeptClick(data) {
      if (data.AllotCDeptName == "") {
        data.AllotCDeptID = "";
      }
      this.AllotCDeptList = [];
      if (data) {
        this.AllotCDeptType = true;
        this.AllotCDeptList.push(data.AllotCDeptID);
      }
      this.$refs['form'].clearValidate(['AllotCDeptName']);
    },
    //接收公司点击事件
    ReceiveCDeptClick(data) {
      if (data.ReceiveCDeptName == "") {
        data.ReceiveCDeptID = "";
      }
      this.ReceiveCDeptList = [];
      if (data) {
        this.ReceiveCDeptType = true;
        this.ReceiveCDeptList.push(data.ReceiveCDeptID);
      }
      this.$refs['form'].clearValidate(['ReceiveCDeptName']);
    },
    // 选择资产
    async addGoods(e) {
      for (let i = 0; i < e.length; i++) {
        this.AssetAllotNotes.push(e[i]);
      }
      let arr1 = this.unique(this.AssetAllotNotes);
      this.AssetAllotNotes = [];
      this.AssetAllotNotes = arr1;
      window.localStorage.setItem('AssetAllotNotes', JSON.stringify(e));
      this.gooodsType = false;
    },
    //去重
    unique(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.GoodsID) && res.set(arr.GoodsID, 1));
    },
    //增加资产
    AddGoods() {
      this.gooodsType = true;
      this.DataList = [];
      if (this.AssetAllotNotes != null) {
        for (let i = 0; i < this.AssetAllotNotes.length; i++) {
          this.DataList.push(this.AssetAllotNotes[i].GoodsID);
        }
      }
    },
    // 删除资产
    delgoods(e) {
      let list = JSON.parse(JSON.stringify(this.AssetAllotNotes));
      this.AssetAllotNotes = list.filter(item => item.GoodsID != e.GoodsID);
      window.localStorage.setItem('AssetAllotNotes', JSON.stringify(this.AssetAllotNotes));
    },
    DelGoods() {
      for (let i = 0; i < this.selectTable.length; i++) {
        let list = JSON.parse(JSON.stringify(this.AssetAllotNotes));
        this.AssetAllotNotes = list.filter(item => item.GoodsID != this.selectTable[i].GoodsID);
        window.localStorage.setItem('AssetAllotNotes', JSON.stringify(this.AssetAllotNotes));
      }
    },
    // 盘点中盘点人回传
    addAssetPan(e) {
      this.panForm.UserID = e.UserID;
      this.panForm.UserName = e.UserName;
      this.PanDianType = false;

    },



    handleRowClick2(row) {
      let index = this.multipleSelection.findIndex(item => {
        // 判断已选数组中是否已存在该条数据
        return item.GoodsID == row.GoodsID
      });
      if (index == -1) {
        // 如果未存在，设置已选状态，并在list中添加这条数据
        this.$refs.dialogList.toggleRowSelection(row, true); //设置复选框为选中状态
      } else {
        // 如果已存在，设置未选状态，并在list中删除这条数据
        this.$refs.dialogList.toggleRowSelection(row, false); //设置复选框为未选状态
      }
    },
    // dialog中的货品列表选中数据
    handleSelectionChange1(val) {
      if (val.length != 0) {
        let DetailList = [];
        this.ok = false;
        this.plok = false;
        for (let i = 0; i < val.length; i++) {
          DetailList.push(parseInt(val[i].PanDianDetailID));
        }
        this.panForm.PanDianDetailIDList = JSON.stringify(DetailList);
      } else {
        this.ok = true;
        this.plok = true;
      }
    },
    handleSelectionChange2(val) {
      if (val.length != 0) {
        this.pandianok = false;
        let DetailList = [];
        for (let i = 0; i < val.length; i++) {
          DetailList.push(parseInt(val[i].PanDianDetailID));
        }
        this.panForm.PanDianDetailIDList = JSON.stringify(DetailList);
      } else {
        this.pandianok = true;
      }
    },
    close() {
      this.showstate = false;
      this.$emit('close');
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-switch.is-checked .el-switch__core {
  border-color: #165dff !important;
  background-color: #165dff !important;
}
.span_pandian {
  display: flex;
  margin: 15px 10px;
  span {
    font: 14px;
    color: rgb(29, 33, 41);
    font-weight: 500;
  }
}
::v-deep .el-divider--vertical {
  height: 2em;
}
.el-dialog__footer {
  margin-top: 25%;
}

/deep/ .el-table__empty-block {
  width: 20%;
  min-width: 121%;
  max-width: 20%;
  padding-right: 20%;
}

::v-deep .is-required .el-form-item__label::before {
  content: "*";
  color: #ff0000;
  margin-left: 4px;
}

.companyimg {
  line-height: 150px;
}

.el-icon-plus {
  border: 1px dashed;
  // background-color: #8c939d;
}

.el-link {
  margin-right: 30px;
}

//头像
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 15px;
  color: #8c939d;
  width: 135px;
  height: 128;
  line-height: 120px;
  text-align: center;
}

.el-input--mini .el-input__inne {
  height: 39px;
}

.avatar {
  width: 135px;
  height: 128;
  display: block;
}

//
.ul_but li {
  margin-bottom: 20px;
}

.ul_but:hover {
  cursor: pointer;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

._table_input {
  border-bottom: 1px solid #eee;

  /deep/.el-input__inner {
    border: 0;
    text-align: center;
  }
}

.settin-box {
  width: 100%;
  height: calc(100vh - 20px);
  display: flex;
  background-color: #fff;
  border-radius: 5px;

  ul {
    padding: 0;
    margin: 0;
  }

  ._right {
    display: flex;
    flex: 1;
    position: relative;

    .content_left {
      height: calc(100vh - 70px);
      padding: 30px 10px;
      font-size: 19px;

      .tabs {
        display: flex;
        height: 50px;

        .tabs_item {
          display: block;
          padding: 10px;
          line-height: 30px;

          &:hover {
            color: #409eff;
            cursor: pointer;
          }
        }
      }

      .content {
        padding: 20px 10px;

        span {
          font-size: 14px;
        }

        .title_box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .el-icon-plus {
            color: #409eff;
            cursor: pointer;
          }
        }

        .title {
          font-size: 14px;
          font-weight: 550;
          display: block;
          margin-bottom: 20px;
        }

        ul li {
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 0 0 10px;
          border-radius: 20px;
          margin: 10px 0;

          // &:hover {
          //   background-color: #409eff;
          //   color: #fff;
          //   cursor: pointer;
          // }

          span {
            margin-left: 10px;
          }
        }
      }
    }

    .content_right {
      height: calc(100vh - 70px);
      position: absolute;
      width: 100%;
      right: 0;
      padding: 10px;

      .head_line {
        display: flex;
        align-items: center;
        height: 80px;

        .el-input {
          width: 200px !important;
        }

        .el-button {
          margin-left: 20px;
        }
      }

      .content_table {
        width: 100%;
        overflow: hidden;
        box-shadow: 0 0 5px rgb(201, 199, 199);
      }
    }
  }

  @media screen and (max-width: 1400px) {
    ._left {
      display: none;
    }
  }
}

._filter {
  height: 60px;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  justify-content: space-between;
  span {
    margin-right: 20px;
    font-size: 14px;
    cursor: pointer;

    .el-icon-refresh {
      margin-left: 10px;
      font-size: 16px;
    }
  }
}

.check {
  color: #409eff;
  border-bottom: 2px solid #409eff;
}

.listindex {
  color: #fff;
  background-color: #409eff;
}

._filter_btn {
  width: 100%;
  text-align: center;
  bottom: 100px;
  margin-top: 15%;

  .el-button {
    width: 100px;

    &:nth-child(2) {
      margin-left: 50px;
    }
  }
}

.el-icon-refresh {
  color: #409eff;
}

.filter_box {
  ul {
    padding-left: 10px;
  }

  li {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;

    .date {
      // margin-left: 45px;
      width: 300px;

      .el-input:nth-child(2) {
        margin-top: 10px;
      }

      /deep/.el-input {
        width: 300px !important;

        .el-input__inner {
          width: 300px !important;
        }
      }
    }
  }

  font {
    display: inline-block;
    width: 100px;
    font-size: 14px;
    color: #fff;
  }

  .el-select {
    width: 300px;
  }

  .el-input {
    width: 300px;
  }

  ._filter_btn {
    width: 100%;
    text-align: center;
    bottom: 100px;

    .el-button {
      width: 100px;

      &:nth-child(2) {
        margin-left: 50px;
      }
    }
  }
}
</style>