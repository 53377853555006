// 草稿功能
export default {
    data() {
        return {
            draftState: [],
            draftData: [],
            draftIndex: 0,
            draftDetail: ''
        }
    },
    mounted() {
        for (let item of this.draftTitle) {
            let data = JSON.parse(window.localStorage.getItem(item));
            if (data) {
                this.draftState.push(true);
                this.draftData.push(data);
            } else {
                this.draftState.push(false);
                this.draftData.push(null);
            }
        }
    },
    methods: {
        changeDraft(e, str, i) {
            this.draftIndex = i;
            let data = JSON.parse(window.localStorage.getItem(this.draftTitle[i])) || this[this.draftTitle[i]];
            data[str] = e;
            this[this.draftTitle[i]][str] = e;
            window.localStorage.setItem(this.draftTitle[i], JSON.stringify(data));
            this.draftState[this.draftIndex] = true;
        }
    }
}