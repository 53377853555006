<template>
  <el-select :disabled="this.allType" ref="select" :value="value" :placeholder="$t('CategoryList.sel')" @visible-change="visibleChange"
    clearable style="width: 100%;" @clear="clear">
    <el-option ref="option" class="option" :value="optionData.id" :label="optionData.name">
      <el-tree ref="tree" class="tree" :node-key="nodeKey" :data="data" :props="props" :default-expanded-keys='[value]'
        highlight-current :expand-on-click-node="false" @node-click="handleNodeClick">
        <span v-if="data.length != 0" class="custom-tree-node" slot-scope="{ node,data }">
          <span style="font-size: 15px;"><i
              :class="data.level == 1 && data.children.length != 0 ? 'el-icon-office-building' : data.level == 1 && data.children.length == 0 ? 'el-icon-school' : data.level == 2 && data.children.length != 0 ? 'el-icon-price-tag' : 'el-icon-house'"></i>
            {{ node.label
            }}</span>
        </span>
      </el-tree>
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'TreeSelect',
  props: {
    // v-model绑定
    value: {
      type: [String, Number],
      default: ''
    },
    // 树形的数据
    data: {
      type: Array,
      default: function () {
        return []
      }
    },
    // 每个树节点用来作为唯一标识的属性
    nodeKey: {
      type: [String, Number],
      default: 'id'
    },
    // tree的props配置
    props: {
      type: Object,
      default: function () {
        return {
          label: 'label',
          children: 'children'
        }
      }
    },
    allType: {
      type: Boolean

    },
  },
  data() {
    return {
      optionData: {
        id: '',
        name: ''
      }
    }
  },
  watch: {
    // value: {
    //   handler(val) {
    //     if (!this.isEmpty(this.data)) {
    //       this.init(val)
    //     }
    //   },
    //   immediate: true
    // },
    data: function (val) {
      if (!this.isEmpty(val)) {
        this.init(this.value)
      }
    }
  },
  mounted() {
  },
  methods: {
    // 是否为空
    isEmpty(val) {
      for (let key in val) {
        return false;
      }
      return true;
    },
    handleNodeClick(data) { 
      if (data.level == 1) return
      this.$emit('input', data.label, data.id);
      this.$emit('selectRoom', data);
      this.$emit('change', data.id);
      this.$refs.select.visible = false;
    },
    init(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.tree.setCurrentKey(val);
          let node = this.$refs.tree.getNode(val);
          if (node) {
            this.optionData.id = val;
            this.optionData.name = node.label;
          }
        })
      } else {
        this.$refs.tree.setCurrentKey(null);
      }
    },
    visibleChange(e) {
      if (e) {
        let selectDom = this.$refs.tree.$el.querySelector('.is-current');
        setTimeout(() => {
          this.$refs.select.scrollToOption({ $el: selectDom });
        }, 0)
      }
    },
    clear() {
      this.$emit('input', '');
    }
  }
}
</script>

<style lang="scss" scoped>
.option {
  height: auto;
  line-height: 1;
  padding: 0;
  background-color: #fff;
}

.tree {
  padding: 4px 20px;
  font-weight: 400;
}

/deep/.el-tree-node__expand-icon.is-leaf {
  color: #ccc;
}
</style>

