<template>
  <el-dialog :title="DeptTitle.Title" :visible.sync="dialogFormVisible"   height="800px" width="50%" lock-scroll append-to-body @close="close">
    <el-form ref="form" :rules="rules" label-position="left" label-width="100px" input-width="100px" :model="Dept">
      <el-form-item :label="$t('BasicDeptOperation.SuperiorName')"><!--上级名称-->
        <el-input :placeholder="$t('BasicDeptOperation.SuperiorName')" readonly v-model="PName"></el-input>
      </el-form-item>
      <el-form-item :label="$t('BasicDeptOperation.DeptCode')"><!--组织标识-->
        <el-input :placeholder="$t('BasicDeptOperation.DeptCode')" v-model="Dept.DeptCode" maxlength="32" show-word-limit></el-input>
      </el-form-item>
      <el-form-item :label="$t('BasicDeptOperation.DeptName')" prop="DeptName"><!--组织名称-->
        <el-input :placeholder="$t('BasicDeptOperation.DeptName')" v-model="Dept.DeptName"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button class="button_info" @click="dialogFormVisible = false" style="margin-right:7px;">{{$t('BasicDept.cancel')}}</el-button>
      <el-button class="button_primary" type="primary" @click="append()" :disabled="$store.getters.Loading">{{$t('BasicDept.confirm')}}</el-button>
    </div>
  </el-dialog>
</template> 
<script> 
import {
  successTips,
  AddBasicDept,
  GetBasicDept,
  UpdateBasicDept,
} from "@/api/user";
export default {
  props: {
    form: {
      type: Object,
    },
    DeptTitle: {
      type: Object,
      default() {
        return {}
      }
    },
    Notes: {
      type: Array,
      default() {
        return [];
      },
    },
    ParentDeptID: {
      type: Number,
      default: 0
    },
    ID: {
      type: Number,
      default: 0
    },
    Superior: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      downUrl: "",
      PName: "",
      model: {},
      list: [],
      dialogFormVisible: false,
      loading: false,
      Dept: {
        DeptCode: '',
        DeptName: '',
        ParentDeptID: '',
        ParentDeptID1: '',
        ParentDeptID2: '',
        ParentDeptID3: '',
        ParentDeptID4: '',
        ParentDeptID5: '',
        ParentDeptID6: '',
        ParentDeptID7: '',
        ParentDeptID8: '',
        ParentDeptID9: '',
        ParentDeptID10: '',
        MainUserID: '',
        DeptLevel: '',
        ISCompany: '',
        DeptStatus: '',
        pid: '',
        DeptRemark: ''
      },
      rules: {
        DeptCode: [
          { required: true, message: this.$t('BasicDeptOperation.PleaseEnter'), trigger: 'blur' },
          { min: 1, max: 32, message: this.$t('BasicDeptOperation.limit'), trigger: 'blur' }
        ],
        DeptName: [
          { required: true, message: this.$t('BasicDeptOperation.PleaseEnter'), trigger: 'blur' },
          { min: 1, max: 32, message: this.$t('BasicDeptOperation.limit'), trigger: 'blur' }
        ],
        UserCode: [
          { required: true, message: this.$t('BasicDeptOperation.PleaseEnter'), trigger: 'blur' },
          { min: 1, max: 32, message: this.$t('BasicDeptOperation.limit'), trigger: 'blur' }
        ],
        UserName: [
          { required: true, message: this.$t('BasicDeptOperation.PleaseEnter'), trigger: 'blur' },
          { min: 1, max: 32, message: this.$t('BasicDeptOperation.limit'), trigger: 'blur' }
        ],
        UserAccount: [
          { required: true, message: this.$t('BasicDeptOperation.PleaseEnter'), trigger: 'blur' },
          { min: 1, max: 32, message: this.$t('BasicDeptOperation.limit'), trigger: 'blur' }
        ],
        CDeptID: [
          { required: true, message: this.$t('BasicDeptOperation.PleaseSelect'), trigger: 'change' }
        ],
        DeptID: [
          { required: true, message: this.$t('BasicDeptOperation.PleaseSelect'), trigger: 'change' }
        ],
        UserPassword: [
          { required: true, message: this.$t('BasicDeptOperation.PleaseEnter'), trigger: 'blur' },
          { min: 6, max: 32, message: this.$t('BasicDeptOperation.Passwordlimit'), trigger: 'blur' }
        ]
      },
    };
  },
  mounted() {
    this.PName = this.Superior;
    this.dialogFormVisible = this.show; 
    if (this.DeptTitle.options == "Update") {
      GetBasicDept({ DeptID: this.ID }).then((res) => {
        if (res.Code == 0) {
          this.Dept.DeptCode = res.Data.DeptCode;
          this.Dept.DeptName = res.Data.DeptName;
        }
      });
    }
  },
  methods: {
    //操作
    async append() {
      let state = false;
      this.$refs.form.validate((valid) => {
        if (valid) {
          state = true;
        }
      })
      if (!state) return 
      if (this.DeptTitle.options == "AddSameLevelCompany") {
        this.Dept.ISCompany = 1;
        this.Dept.ParentDeptID = this.ParentDeptID;
        let { DeptCode, DeptName, ISCompany, ParentDeptID } = this.Dept;
        this.ISCompany = 1;
        AddBasicDept({ ISCompany, DeptCode, DeptName, ParentDeptID }).then(
          (res) => {
            if (res.Code == 0) {
              successTips(res.Message);
              this.dialogFormVisible = false; //弹窗页面是否开启
              this.$emit('message', res.Code);
            }
          }
        );
      } else if (this.DeptTitle.options == "AddSubordinatesCompany") {
        this.Dept.ISCompany = 1;
        this.Dept.ParentDeptID = this.ID;
        let { DeptCode, DeptName, ParentDeptID, ISCompany } = this.Dept;
        AddBasicDept({ ISCompany, DeptCode, DeptName, ParentDeptID }).then(
          (res) => {
            if (res.Code == 0) {
              successTips(res.Message);
              this.dialogFormVisible = false; //弹窗页面是否开启
              this.$emit('message', res.Code);
            }
          }
        );
      } else if (this.DeptTitle.options == "AddSamelevelDept") {
        this.Dept.ISCompany = 2;
        this.Dept.ParentDeptID = this.ParentDeptID;
        let { ISCompany, DeptCode, DeptName, ParentDeptID } = this.Dept;
        AddBasicDept({ ISCompany, DeptCode, DeptName, ParentDeptID }).then(
          (res) => {
            if (res.Code == 0) {
              successTips(res.Message);
              this.dialogFormVisible = false; //弹窗页面是否开启 
              this.$emit('message', res.Code);
            }
          }
        );
      } else if (this.DeptTitle.options == "AddsubordinateDept") {
        this.Dept.ISCompany = 2;
        this.Dept.ParentDeptID = this.ID;
        let { ISCompany, DeptCode, DeptName, ParentDeptID } = this.Dept;
        AddBasicDept({ ISCompany, DeptCode, DeptName, ParentDeptID }).then(
          (res) => {
            if (res.Code == 0) {
              successTips(res.Message);
              this.dialogFormVisible = false; //弹窗页面是否开启
              this.$emit('message', res.Code);
            }
          }
        );
      } else if (this.DeptTitle.options == "Update") {
        this.Dept.ParentDeptID = this.ID;
        let { DeptCode, DeptName, ParentDeptID } = this.Dept;
        UpdateBasicDept({ DeptID: ParentDeptID, DeptName, DeptCode }).then(
          (res) => {
            if (res.Code == 0) {
              this.ok = true;
              successTips(res.Message);
              this.dialogFormVisible = false; //弹窗页面是否开启 
              this.$emit('message', res.Code);
            }
          }
        );
      }
      this.loading = false;
    },
    close() {
      this.$emit("close");
    },
  },
};
  </script>
  
  <style>
</style>
  