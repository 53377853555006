<template>
  <el-dialog :title="PanDainTitle" center :visible.sync="dialogFormVisible" @close="close">
    <el-form ref="form" :rules="rules" label-position="right" label-width="120px" input-width="100px" :model="StoragePanDianDan" style="margin-left:12%">
      <el-form-item :label="$t('StoragePanDian.PanDianDanName')" prop="PanDianDanName">
        <el-input :placeholder="$t('StoragePanDian.PanDianDanName')" v-model="StoragePanDianDan.PanDianDanName" @input="changeDraft($event, 'PanDianDanName', 0)"></el-input>
      </el-form-item>
      <el-form-item :label="$t('StoragePanDian.WarehouseName')" prop="WarehouseName">
        <el-input :placeholder="$t('StorageOutKu.choice')" v-model="StoragePanDianDan.WarehouseName" readonly class="input-with-select" style="width: 75% !important">
          <el-button slot="append" icon="el-icon-thumb" @click="RoomClick(StoragePanDianDan)"></el-button>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('StoragePanDian.PanDianUserName')" prop="PanDianUserName">
        <el-input :placeholder="$t('StoragePanDian.choice')" ref="inputRef" v-model="StoragePanDianDan.PanDianUserName" readonly class="input-with-select">
          <el-button slot="append" icon="el-icon-user-solid" @click="panDianClick(StoragePanDianDan)"></el-button>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('StoragePanDian.PanDianRemark')">
        <el-input type="textarea" maxlength="512" show-word-limit class="textareaText" rows="2" :placeholder="$t('StoragePanDian.PanDianRemark')"
          v-model="StoragePanDianDan.PanDianRemark" @input="changeDraft($event, 'PanDianRemark', 0)"></el-input>
      </el-form-item>
    </el-form>
    <div style="margin-left:12%;padding: 0 0 0 40px;">
      <span style="color: red; font-size: 14px">{{ $t('StoragePanDian.prompt') }}</span>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" class="button_info" style="margin-right: 46px">{{ $t('StoragePanDian.cancel') }}</el-button> <!--取消-->
      <!-- 添加组织 -->
      <el-button class="button_primary" type="primary" @click="submitPanDian">{{ $t('StoragePanDian.preserve') }} </el-button><!--保存-->
    </div>
    <!-- 调出库房 -->
    <SelectWarehouse v-if="Roomtype" :type="Roomtype" @close="Roomtype = false" @addMember="addRoom" :DataList="RoomList">
    </SelectWarehouse>
    <!-- 领用人组件 -->
    <AdminUser v-if="type" :type="type" @close="type = false" @addMember="selectUser" :DataList="GoodsDataList">
    </AdminUser>
  </el-dialog>
</template>

<script>
import {
  GetStorageWarehouseTree,
  addPanDianDan,
  updatePanDianDan,
  getPanDianDetail,
  GetExportColumn,
} from "@/api/user";
import { successTips } from "@/api/user";
import mixins from "@/mixins";
import draft from '@/mixins/draft';
export default {
  props: {
    show: {
      type: Boolean,
    },
    panId: {
      type: Number,
      default: 0
    },
    PanDianOpertion: {
      type: Object
    },
    PanDainTitle: {
      type: String,
      default: ''
    },
    state: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  name: "SettingView",
  components: {},
  mixins: [mixins, draft],
  data() {
    return {
      pandianState: true,
      querystate: true,
      scrapstate: true,
      delstate: true,
      operated: true,
      pantype: false,
      panState: false,
      tabs: 0,
      menutab: 0,
      current: 0,
      ID: "",
      level: "",
      panShow: false,
      drawer: false,
      filterText: "",
      icon: "",
      title: "",
      currentPage: 1,
      fileList: "",
      Title: "",
      type: false,
      gooodsType: false,
      checked: true,
      goodsChecked: true,
      PanDianSelete: false,
      PanDianTrue: false,
      dialogFormVisible: false,
      panDianShow: false,
      tableData: [],
      GoodsDataList: [],
      Times: null,
      form: {
        PageSize: 20,
        PageIndex: 0,
        StartTime: "",
        EndTime: "",
        SearchName: "",
        PanDianDanCode: "",
        PanDianDanName: "",
        PanDianDStatus: "",
        PanDianDStatusName: "",
        PanDianResult: "",
        PanDianResultName: "",
        WarehouseID: "",
        WarehouseName: "",
        PanDianUserID: "",
        PanDianUserName: "",
        Operator: "",
      },
      TotalCount: 0,
      dialog1: {
        visible: false,
      },
      PanDianNotes: [],
      StoragePanDianDan: {
        PanDianDanID: "",
        WarehouseID: "",
        WarehouseName: "",
        PanDianDanCode: "",
        PanDianUserID: "",
        PanDianUserName: "",
        PanDianDanName: "",
        Operator: "",
        PanDianType: "1",
        PanDianMethod: "",
        PanDianResult: "",
        PanDianDStatus: "3",
        PanDianRemark: "",
        PanDianTime: new Date().toLocaleString().replaceAll('/', '-'),
      },
      downUrl: "",
      data: [],
      setType: "",
      value: "",
      NotesID: "",
      options: [],
      results: [],
      loading: false,
      rules: {
        WarehouseName: [
          { required: true, message: this.$t('StoragePanDian.choice'), trigger: "blur" },
        ],
        PanDianUserName: [
          { required: true, message: this.$t('StoragePanDian.choice'), trigger: "blur" },
        ],
        PanDianDanName: [
          { required: true, message: this.$t('StoragePanDian.choice'), trigger: "blur" },
        ]
      },
      multipleSelection: [],
      multipleSelection1: [],
      RoomList: [],
      upload: false,
      Roomtype: false,
      uptype: '',
      exportColumn: [],
      ExportType: '',
      errString: "",
      errUrl: '',
      viewtype: '',
      draftTitle: ['StoragePanDianDan']
    };
  },
  mounted() {
    if (this.PanDianOpertion.Opertion == 'Update') {
      getPanDianDetail({
        PanDianDanID: this.panId,
      }).then((res) => {
        if (res.Code == 0) {
          this.StoragePanDianDan = res.Data;
        }
      });
    }
    if (this.state) {
      this.StoragePanDianDan = JSON.parse(window.localStorage.getItem(this.draftTitle[this.draftIndex]));
    }
    this.getWarehouse();
    this.dialogFormVisible = this.show;
  },
  methods: {
    addRoom(e) {
      this.StoragePanDianDan.WarehouseID = e.WarehouseID;
      this.StoragePanDianDan.WarehouseName = e.WarehouseName;
      this.changeDraft(e.WarehouseID, 'WarehouseID', 0);
      this.changeDraft(e.WarehouseName, 'WarehouseName', 0);
      this.$refs['form'].clearValidate(['WarehouseName']);  //移除表单某个字段的校验 
      this.Roomtype = false;
    },
    //库房点击事件
    RoomClick(data) {
      this.RoomList = [];
      if (data) {
        this.Roomtype = true;
        this.RoomList.push(data.WarehouseID);
      }
    },
    removeFocus(field) {
      // this.$refs[field].resetFields();
      this.$refs.form.clearValidate(field)
      // this.$refs.form.resetFields([field]);
    },
    //盘点人点击事件
    panDianClick(data) {
      this.GoodsDataList = [];
      if (data) {
        this.GoodsDataList.push(data.PanDianUserID);
      } 
      this.type = true;
    },
    getColumn() {
      GetExportColumn({ ExportName: this.viewtype }).then(res => {
        if (res.Code == 0) {
          this.exportColumn = res.Data;
          this.upload = true;
        }
      })
    },
    //清空表格数据
    close() {
      this.draftState[this.draftIndex] = false;
      this.$emit("close");
    },
    //获取库房数据
    getWarehouse() {
      GetStorageWarehouseTree().then((res) => {
        if (res.Code == 0) {
          this.data = res.Data;
        }
      });
    },
    //操作
    submitPanDian() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let {
            PanDianDanName,
            WarehouseID,
            PanDianUserID,
            PanDianRemark,
          } = this.StoragePanDianDan;
          let form = { PanDianDanName, WarehouseID, PanDianUserID, PanDianRemark };
          if (this.PanDianOpertion.Opertion == 'Add') {
            addPanDianDan(form).then((res) => {
              if (res.Code == 0) {
                this.reset();
                this.$emit('message', res.Code);
                successTips(res.Message); 
                window.localStorage.setItem('StoragePanDianDan', null);
                window.localStorage.setItem(this.draftTitle[this.draftIndex], null);
                this.draftState[this.draftIndex] = false;
                this.$emit("close");
              }
            });
          } else {
            updatePanDianDan({
              PanDianDanID: this.StoragePanDianDan.PanDianDanID,
              ...form,
            }).then((res) => {
              if (res.Code == 0) {
                this.reset();
                this.$emit('message', res.Code);
                successTips(res.Message);
                window.localStorage.setItem('StoragePanDianDan', null);
                window.localStorage.setItem(this.draftTitle[this.draftIndex], null);
                this.draftState[this.draftIndex] = false;
                this.$emit("close");
              }
            });
          }
        }

      });
    },
    reset() {
      for (let i in this.StoragePanDianDan) {
        this.StoragePanDianDan[i] = "";
      }
      for (let i in this.form) {
        this.form[i] = "";
      }
      this.form.PageIndex = 0;
      this.form.PageSize = 20;
    },


    setWarehouseID(e) {
      this.StoragePanDianDan.WarehouseID = e.id;
      this.form.WarehouseID = e.id;
      this.form.WarehouseName = e.label;
    },
    selectUser(e) {
      this.removeFocus('PanDianUserName');
      this.StoragePanDianDan.PanDianUserID = e.UserID;
      this.StoragePanDianDan.PanDianUserName = e.UserName;
      this.changeDraft(e.UserID, 'PanDianUserID', 0);
      this.changeDraft(e.UserName, 'PanDianUserName', 0);
      this.$refs['form'].clearValidate(['PanDianUserName']);  //移除表单某个字段的校验 
      this.form.PanDianUserID = e.UserID;
      this.form.PanDianUserName = e.UserName;
      this.type = false;
    },

    //点击行触发，选中或不选中复选框
    handleRowClick(row) {
      this.$refs.tableList.toggleRowSelection(row); //设置复选框为选中状态
    },
    selectRowClassName({ row }) {
      var color = "";
      this.multipleSelection.forEach(item => {
        if (item.PanDianDanID == row.PanDianDanID) {
          color = "warning-row";
        }
      });
      return color;
    },
    selectable(row) {
      if (row.PanDianDStatus == 1) {
        return false;
      } else {
        return true;
      }
    },

  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-form-item__error {
  padding-top: 42px !important;
}
a {
  text-decoration: none;
  color: #409eff;
}

._table_input {
  border-bottom: 1px solid #eee;

  /deep/.el-input__inner {
    border: 0;
    text-align: center;
  }
}

.SearchName {
  margin-right: 0% !important;
}

.el-input {
  width: 75% !important;
  margin-right: 8%;
}

.el-select {
  width: 75% !important;
  margin-right: 8%;
}

.textarea {
  width: 89% !important;
}

.textareaText {
  width: 75% !important;
}

// ::v-deep .el-form-item > .el-form-item__label::before {
//   content: "" !important;
//   color: #000 !important;
// }

// ::v-deep .is-required .el-form-item__label::before {
//   content: "*";
//   color: #ff0000;
//   margin-left: 4px;
// }

.companyimg {
  line-height: 150px;
}

.el-icon-plus {
  border: 1px dashed;
  // background-color: #8c939d;
}

.el-link {
  margin-right: 30px;
}

//头像
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 15px;
  color: #8c939d;
  width: 135px;
  height: 128;
  line-height: 120px;
  text-align: center;
}

.el-input--mini .el-input__inne {
  height: 39px;
}

.avatar {
  width: 135px;
  height: 128;
  display: block;
}

//
.ul_but li {
  margin-bottom: 20px;
}

.ul_but:hover {
  cursor: pointer;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.settin-box {
  width: 100%;
  height: calc(100vh - 20px);
  display: flex;
  background-color: #fff;
  border-radius: 5px;

  ul {
    padding: 0;
    margin: 0;
  }

  ._right {
    display: flex;
    flex: 1;
    position: relative;

    .content_left {
      height: calc(100vh - 70px);
      padding: 30px 10px;
      font-size: 19px;

      .tabs {
        display: flex;
        height: 50px;

        .tabs_item {
          display: block;
          padding: 10px;
          line-height: 30px;

          &:hover {
            color: #409eff;
            cursor: pointer;
          }
        }
      }

      .content {
        padding: 20px 10px;

        span {
          font-size: 14px;
        }

        .title_box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .el-icon-plus {
            color: #409eff;
            cursor: pointer;
          }
        }

        .title {
          font-size: 14px;
          font-weight: 550;
          display: block;
          margin-bottom: 20px;
        }

        ul li {
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 0 0 10px;
          border-radius: 20px;
          margin: 10px 0;

          // &:hover {
          //   background-color: #409eff;
          //   color: #fff;
          //   cursor: pointer;
          // }

          span {
            margin-left: 10px;
          }
        }
      }
    }

    .content_right {
      height: calc(100vh - 70px);
      position: absolute;
      width: 100%;
      right: 0;
      padding: 10px;

      .head_line {
        display: flex;
        align-items: center;
        height: 80px;

        .el-input {
          width: 200px !important;
        }

        .el-button {
          margin-left: 20px;
        }
      }

      .content_table {
        width: 100%;
        overflow: hidden;
        box-shadow: 0 0 5px rgb(201, 199, 199);
      }
    }
  }

  @media screen and (max-width: 1400px) {
    ._left {
      display: none;
    }
  }
}

._filter {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  span {
    font-size: 14px;
    cursor: pointer;

    .el-icon-refresh {
      margin-left: 10px;
      font-size: 16px;
    }
  }
}

.check {
  color: #409eff;
  border-bottom: 2px solid #409eff;
}

.listindex {
  color: #fff;
  background-color: #409eff;
}

._filter_btn {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 100px;

  .el-button {
    width: 100px;

    &:nth-child(2) {
      margin-left: 50px;
    }
  }
}

.filter_box {
  ul {
    padding: 0 20px;
  }

  li {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;

    .date {
      // margin-left: 45px;
      width: 300px;

      .el-input:nth-child(2) {
        margin-top: 10px;
      }

      /deep/.el-input {
        width: 300px !important;

        .el-input__inner {
          width: 300px !important;
        }
      }
    }
  }

  font {
    display: inline-block;
    width: 100px;
    font-size: 14px;
    color: #fff;
  }

  .el-select {
    width: 300px;
  }

  .el-input {
    width: 300px;
  }

  ._filter_btn {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 100px;

    .el-button {
      width: 100px;

      &:nth-child(2) {
        margin-left: 50px;
      }
    }
  }
}
</style>
