// 资产模块详情
import AssetAllotDetail from './components/asset/AllotDetail.json';
import AssetReturnDetail from './components/asset/AssetReturnDetail.json';
import BrrowAndReturnDetail from './components/asset/BrrowAndReturnDetail.json';
import FinancialChangeDetail from './components/asset/FinancialChangeDetail.json';
import GoodsChangeDetail from './components/asset/GoodsChangeDetail.json';
import HandOverDetail from './components/asset/HandOverDetail.json';
import MaintenanceDetail from './components/asset/MaintenanceDetail.json';
import ReceivedDetail from './components/asset/ReceivedDetail.json';
import RepairDetail from './components/asset/RepairDetail.json';
import RequisitionDetail from './components/asset/RequisitionDetail.json';
import RevertDetail from './components/asset/RevertDetail.json';
import ScraptDetial from './components/asset/ScraptDetial.json';
import main from './components/main.json';
// 资产模块增删改//礼清更改
import AssetAllotAdd from './components/assetadd/AllotAdd.json';
import AssetListAdd from './components/assetadd/AssetListAdd.json';
import AssetBrrowAndReturnAdd from './components/assetadd/BrrowAndReturnAdd.json';
import AssetFinancialAdd from './components/assetadd/FinancialAdd.json';
import AssetReturnAdd from './components/assetadd/AssetReturnAdd.json';
import AssetGoodsChangeAdd from './components/assetadd/GoodsChangeAdd.json';
import AssetHandOverAdd from './components/assetadd/HandOverAdd.json';
import AssetMaintenanceAdd from './components/assetadd/MaintenanceAdd.json';
import AssetPanDianAdd from './components/assetadd/PanDianAdd.json';
import AssetReceivedAdd from './components/assetadd/ReceivedAdd.json';
import AssetRepairAdd from './components/assetadd/RepairAdd.json';
import AssetRequisitionAdd from './components/assetadd/RequisitionAdd.json';
import AssetRevertAdd from './components/assetadd/RevertAdd.json';
import AssetScraptAdd from './components/assetadd/ScraptAdd.json';
import AssetsubsidiarychangeAdd from './components/assetadd/subsidiarychangeAdd.json';

// 库存模块详情
import StorageAllotDetail from './components/storage/AllotDetail.json';
import StorageInKuDetail from './components/storage/InKuDetail.json';

// 资产模块
import Allot from './asset/Allot.json';
import AssetList from './asset/AssetList.json';
import BrrowAndReturn from './asset/BrrowAndReturn.json';
import AssetFaKa from './asset/AssetFaKa.json';
import Financial from './asset/Financial.json';
import GoodsChange from './asset/GoodsChange.json';
import HandOver from './asset/HandOver.json';
import HomePage from './asset/HomePage.json';
import AssetReturn from './asset/AssetReturn.json';
import Maintenance from './asset/Maintenance.json';
import AssetPanDian from './asset/PanDian.json';
import Received from './asset/Received.json';
import Repair from './asset/Repair.json';
import RequisitionAndRevert from './asset/RequisitionAndRevert.json';
import Scrapt from './asset/Scrapt.json';
import Subsidiary from './asset/Subsidiary.json';
import subsidiarychange from './asset/subsidiarychange.json';
import AssetMaintenancePlan from './asset/MaintenancePlan.json';
import AssetMaintenanceWork from './asset/MaintenanceWork.json';
// 资产报表
import AssetReporFive from './asset/report/ReporFive.json';
import AssetReporSeven from './asset/report/ReporSeven.json';
import AssetReporSix from './asset/report/ReporSix.json';
import AssetReportEight from './asset/report/ReportEight.json';
import AssetReportFour from './asset/report/ReportFour.json';
import AssetReportOne from './asset/report/ReportOne.json';
import AssetReportThree from './asset/report/ReportThree.json';
import AssetReportTwo from './asset/report/ReportTwo.json';
// 资产设置
// 资产设置//志杰更改
import AssetSettingManage from './asset/setting/SettingManage.json';
import AssetLabel from './asset/setting/Label.json';
import AssetType from './asset/setting/Type.json';
import District from './asset/setting/District.json';

// 通用组件
import AdminUser from './components/AdminUser.json';
import CategoryDataList from './components/CategoryDataList.json';
import CategoryList from './components/CategoryList.json';
import ExportView from './components/ExportView.json';
import SystemUser from './components/SystemUser.json';


//系统模块
import Home from './Home.json';
import Company from './basic/Company.json';
import Dept from './basic/Dept.json';
import BasicDeptOperation from './components/basicadd/BasicDeptOperation.json';
import BasicUserOperation from './components/basicadd/BasicUserOperation.json';
import Manage from './basic/Manage.json';
import MenuPower from './basic/MenuPower.json';
import Lable from './basic/rfid/Lable.json';
import LableOperation from './components/basicadd/BasicLableOperation.json';
import Device from './basic/rfid/Device.json';
import Login from './basic/log/Login.json';
import LoginSystem from './Login.json';
import Application from './basic/log/Application.json';
import Platform from './basic/log/Platform.json';
import SystemMessage from './basic/SystemMessage.json';
import Index from './Index.json';
import SystemEnterprise from './manage/SystemEnterprise.json';
import SystemPower from './manage/SystemPower.json';
import Register from './register/Register.json';
import UserInformation from './register/UserInformation.json';
// 库存模块
import Goods from './storage/Goods.json';
import InKu from './storage/InKu.json';
import Change from './storage/Change.json';
import OutKu from './storage/OutKu.json';
import StorageAllot from './storage/Allot.json';
import PanDian from './storage/PanDian.json';
import ReportOne from './storage/report/ReportOne.json';
import ReportTwo from './storage/report/ReportTwo.json';
import ReportThree from './storage/report/ReportThree.json';
import ReportFour from './storage/report/ReportFour.json';
import Warehouse from './storage/setting/WarehouseManage.json';
import Category from './storage/setting/CategoryManage.json';
import Label from './storage/setting/Label.json';
import SettingManage from './storage/setting/SettingManage.json';
import RepairPlan from './asset/RepairPlan.json';
import RepairWork from './asset/RepairWork.json';
export default {
    // 通用组件
    AdminUser,
    CategoryDataList,
    CategoryList,
    ExportView,
    SystemUser,
    SystemEnterprise,
    SystemPower,
    main,
    UserInformation,
    Register,
    // 资产详情
    AssetReturnDetail,
    AssetAllotDetail,
    BrrowAndReturnDetail,
    FinancialChangeDetail,
    GoodsChangeDetail,
    HandOverDetail,
    MaintenanceDetail,
    ReceivedDetail,
    RepairDetail,
    RequisitionDetail,
    RevertDetail,
    ScraptDetial,
    StorageAllotDetail,
    StorageInKuDetail,
    // 资产增改
    AssetAllotAdd,
    AssetListAdd,
    AssetReturnAdd,
    AssetSettingManage: AssetSettingManage,
    AssetLabel: AssetLabel,
    AssetType: AssetType,
    AssetBrrowAndReturnAdd,
    AssetFinancialAdd,
    AssetGoodsChangeAdd,
    AssetHandOverAdd,
    AssetMaintenanceAdd,
    AssetPanDianAdd,
    AssetReceivedAdd,
    AssetRepairAdd,
    AssetRequisitionAdd,
    AssetRevertAdd,
    AssetScraptAdd,
    AssetsubsidiarychangeAdd,

    // 资产
    RepairWork,
    AssetRepairPlan: RepairPlan,
    AssetAllot: Allot,
    AssetList,
    AssetBrrowAndReturn: BrrowAndReturn,
    AssetFaKa,
    AssetFinancial: Financial,
    AssetGoodsChange: GoodsChange,
    AssetHandOver: HandOver,
    AssetHomePage: HomePage,
    AssetMaintenance: Maintenance,
    AssetPanDian,
    AssetReceived: Received,
    AssetRepair: Repair,
    AssetRequisition: RequisitionAndRevert,
    AssetScrapt: Scrapt,
    AssetSubsidiary: Subsidiary,
    AssetDistrict: District,
    Assetsubsidiarychange: subsidiarychange,
    AssetReporFive,
    AssetReporSeven,
    AssetReporSix,
    AssetReturn,
    AssetReportEight,
    AssetReportFour,
    AssetReportOne,
    AssetReportThree,
    AssetReportTwo,
    AssetMaintenancePlan,
    AssetMaintenanceWork,

    //库存
    StorageGoods: Goods,
    StorageInKu: InKu,
    StorageOutKu: OutKu,
    StorageAllot: StorageAllot,
    StoragePanDian: PanDian,
    StorageReportOne: ReportOne,
    StorageReportTwo: ReportTwo,
    StorageReportThree: ReportThree,
    StorageReportFour: ReportFour,
    StorageWarehouse: Warehouse,
    StorageCategory: Category,
    StorageLabel: Label,
    StorageSetting: SettingManage,
    StorageChange: Change,
    //系统管理
    BasicHome: Home,
    BasicCompany: Company,
    BasicDept: Dept,
    BasicDeptOperation: BasicDeptOperation,
    BasicUserOperation: BasicUserOperation,
    BasicManage: Manage,
    BasicMenuPower: MenuPower,
    BasicLable: Lable,
    BasicLableOperation: LableOperation,
    BasicDevice: Device,
    BasicLogin: Login,
    LoginSystem,
    BasicApplication: Application,
    BasicPlatform: Platform,
    BasicSystemMessage: SystemMessage,
    BasicIndex: Index
}