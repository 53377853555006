<template>
  <el-drawer :visible.sync="dialogFormVisible" direction="rtl" :modal="false" size="1000" @close="close">
    <div class="_dialog">
      <div class="_top">
        <i @click="close" style="cursor: pointer;" class="el-icon-d-arrow-left"></i>
        <span @click="close">{{ $t('StorageGoods.back') }}</span>
        <div style="width: 1px;height: 20px;background-color:#e5e6eb;"></div>
        <font>{{ $t('StorageGoods.RFIDFaka') }}</font>
      </div>
      <div class="_info">
        <p class="title">{{ $t('StorageGoods.GoodsName') }}</p>
        <div class="content">
          <div class="content_item">
            <p>
              <span>{{ $t('StorageGoods.HuoPinName') }}:</span>
              <font>{{ FaKaStorageHuoPin.HuoPinName }}</font>
            </p>
            <p>
              <span>{{ $t('StorageGoods.BatchNumber') }}:</span>
              <font>{{ FaKaStorageHuoPin.BatchNumber }}:</font>
            </p>
            <p>
              <span>{{ $t('StorageGoods.FactStock') }}:</span>
              <font>{{ FaKaStorageHuoPin.FactStock }}</font>
            </p>
          </div>
          <div class="content_item">
            <p>
              <span>{{ $t('StorageGoods.CalcUnit') }}:</span>
              <font>{{ FaKaStorageHuoPin.CalcUnit }}</font>
            </p>
            <p>
              <span>{{ $t('StorageGoods.DefaultPrice') }}:</span>
              <font>{{ FaKaStorageHuoPin.DefaultPrice }}</font>
            </p>
            <p>
              <span>{{ $t('StorageGoods.CategoryName') }}:</span>
              <font>{{ FaKaStorageHuoPin.CategoryName }}</font>
            </p>
          </div>
          <div class="content_item">
            <p>
              <span>{{ $t('StorageGoods.WarehouseName') }}:</span>
              <font>{{ FaKaStorageHuoPin.WarehouseName }}</font>
            </p>
            <p>
              <span>{{ $t('StorageGoods.HuoPinBarCode') }}:</span>
              <font>{{ FaKaStorageHuoPin.HuoPinBarCode }}</font>
            </p>
            <p>
              <span>{{ $t('StorageGoods.BrandTrademark') }}:</span>
              <font>{{ FaKaStorageHuoPin.BrandTrademark }}</font>
            </p>
          </div>
          <div class="content_item">
            <p>
              <span>{{ $t('StorageGoods.HuoPinModels') }}:</span>
              <font>{{ FaKaStorageHuoPin.HuoPinModels }}</font>
            </p>
            <p>
              <span></span>
              <font></font>
            </p>
            <p>
              <span></span>
              <font></font>
            </p>
          </div>
        </div>
      </div>
      <hr style="border: none;height: 1px;background: #f2f3f5;">
      <div style="position: relative;">
        <div class="_filter">
          <div>
            <el-button class="button_primary" @click="FaKaRFID()">
              {{ $t('StorageGoods.addOneRFID') }}
            </el-button>
            <el-button class="button_info" :disabled="OneGoodsListID.length == 0" @click="AddBatch()">
              {{ $t('StorageInKu.BatchInput') }}
            </el-button>
            <el-button class="button_info" @click="DeleteEPC()" :disabled="OneGoodsListID.length == 0">
              {{ $t('StorageGoods.DeleteEPC') }}
            </el-button>
            <el-button class="button_info" @click="DelGoods()" :disabled="goodsChecked">
              {{ $t('StorageGoods.DelGoods') }}
            </el-button>
          </div>
          <span class="Refresh"><i class="el-icon-setting" @click="TableOk = true" style="color:#165dff;">
              {{ $t('BasicDept.CustomizeHeader') }}</i> </span> <!--自定义表头-->

        </div>

        <el-table border ref="dialogList" :row-class-name="selectRowClassName" @row-click="handleRowClick1" :data="OneGoodsListID"
          :cell-style="{ borderBottom: '1px dashed #e8e8e8' }" @selection-change="handleSelectionChange1" height="calc(100vh - 420px)" v-loading="loading"
          @header-dragend="headerDragend">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column :sortable="item.sort" v-for="(item, index) in GoodsTable" :key="index" :prop="item.propName" :label="item.labelName" :width="item.width">
            <template slot-scope="scope">
              <span v-if="item.propName=='GoodsRFIDEPC'">
                <el-input class="_table_input" v-model="scope.row.GoodsRFIDEPC" :data-rowindex="'GoodsRFIDEPC' + scope.$index"
                  @keyup.enter.native="focusNextInputTow(scope.$index + 1, 'GoodsRFIDEPC')"></el-input>
              </span>
              <span v-else-if="item.propName=='GoodsCount'">
                <el-input class="_table_input" v-model="scope.row.FaKaCount" :data-rowindex="'FaKaCount' + scope.$index"
                  @keyup.enter.native="focusNextInputTow(scope.$index + 1, 'FaKaCount')"></el-input>
              </span>
              <span v-else>
                {{ scope.row[item.propName] }}
              </span>
            </template>
          </el-table-column>
          <div slot="empty" class="empty">
            <img src="@/assets/img/empty.png" />
            <span class="txt">{{ $t('StorageGoods.none') }}
              <font @click="FaKaRFID()">{{ $t('StorageGoods.Batchoperation') }}</font>~
            </span>
          </div>
        </el-table>
      </div>
      <div class="_footer">
        <el-button class="button_info" @click="close" style="margin-right: 7px">{{
        $t('StorageGoods.cancel') }}</el-button>
        <!-- 添加组织 -->
        <el-button class="button_primary" @click="appendGoods('form')">{{ $t('StorageGoods.confirm') }}</el-button>
      </div>
      <!--批量填充-->
      <el-dialog :title="$t('StorageInKu.BatchInput')" :visible.sync="dialogVisible" width="30%" append-to-body>
        <el-input v-model="batchNumber" type="number" :placeholder="$t('StorageGoods.FaKaCountMesTips')" style="width: 100% !important;"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button class="button_info" @click="dialogVisible = false">{{ $t('StorageGoods.cancel') }}</el-button>
          <el-button class="button_primary" @click="BatchInput()">{{ $t('StorageGoods.confirm') }}</el-button>
        </span>
      </el-dialog>
      <!--增加RFID-->
      <el-dialog :title="$t('StorageGoods.prompt')" :visible.sync="FakaState" width="30%" append-to-body>
        <el-form :lable="$t('StorageGoods.FaKaCount')" ref="form" label-position="left" label-width="85px" input-width="100px">
          <el-form-item :label="$t('StorageGoods.FaKaCount')" style="width: 80%">
            <el-input v-model="RFIDInput" type="number" :placeholder="$t('StorageGoods.FaKaCountMesTips')" style="width: 100% !important;"></el-input>
          </el-form-item>
        </el-form>
        <div class="FaKaRadion">
          <el-checkbox-group v-model="resource" @change="bindCheckBox">
            <el-checkbox label="1">{{ $t('StorageGoods.Generate') }}</el-checkbox>
            <el-checkbox label="2">{{ $t('StorageGoods.NoGenerate') }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button class="button_info" @click="FakaState = false">{{ $t('StorageGoods.cancel') }}</el-button>
          <el-button class="button_primary" @click="GoodsFaKa()">{{ $t('StorageGoods.confirm') }}</el-button>
        </div>
      </el-dialog>
    </div>
    <DragTable v-if="TableOk" TableName="GoodsStockSingleBindRFID" :show="TableOk" @message="Message_">
    </DragTable>
  </el-drawer>
</template> 
<script>
import {
  msgTips,
  BindSingleStorageGoodsEPCList,
  successTips,
  ProductRFID,
  SetBasicUserColumnWidth,
  LoadBasicUserColumn
} from "@/api/user";
export default {
  props: {
    FaData: {
      type: Object,
      default() {
        return {}
      }
    },
    FaTable: {
      type: Object,
    },
    form: {
      type: Object,
    },
    Notes: {
      type: Array,
      default() {
        return [];
      },
    },
    ParentDeptID: {
      type: Number,
      default: 0
    },
    ID: {
      type: Number,
      default: 0
    },
    Title: {
      type: String,
      default: ''
    },
    Superior: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
    },
    checked: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    state: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data() {
    return {
      downUrl: "",
      PName: "",
      model: {},
      batchNumber: null,
      RFIDInput: "",
      resource: ['1'],
      list: [],
      CDeptID: [],
      RFIDDeviceType: [],
      GoodsTable: [],
      WorkType: [],
      RFIDDeviceDataUse: [],
      Direction: [],
      multipleSelection: [],
      RFIDDeviceStatus: [],
      RFIDDeviceNetType: [],
      OneGoodsListID: [],
      DeptIDList: [],
      RFIDDeviceDataID: [],
      selectTable: [],
      disabledChecked: false,
      GoodsStatus: false,
      goodsChecked: false,
      TableOk: false,
      addUserImg: "",
      dialogFormVisible: false,
      loading: false,
      passWordChecked: false,
      FakaState: false,
      DeviceTitleName: '',
      oldImg: '',
      FaKaStorageHuoPin: {
        HuoPinID: '',
        CompanyID: '',
        CategoryID: '',
        HuoPinCode: '',
        HuoPinName: '',
        HuoPinImage: '',
        HuoPinBarCode: '',
        BrandTrademark: '',
        HuoPinModels: '',
        DefaultPrice: '',
        CalcUnit: '',
        SafeStockLowerLimit: '',
        SafeStockUpperLimit: '',
        FactStock: '',
        RFIDStock: '',
        ElseStock: '',
        HuoPinStatus: '',
        HuoPinRemark: '',
        UpdateTime: '',
        CreateTime: '',
        SearchName: ''
      },
      StorageGoods: {
        SearchName: '',
        PageSize: 20,
        PageIndex: 0,
        GoodsCode: '',
        GoodsName: '',
        CategoryID: '',
        WarehouseID: '',
        HuoPinID: '',
        BatchNumber: '',
        GoodsRFIDEPC: '',
        GoodsImage: '',
        FaKaCount: 0,
        DefaultPrice: '',
        CalcUnit: '',
        AllPrice: '',
        FactStock: '',
        CouldStock: '',
        InKuStatus: '',
        GoodsStatus: '',
        GoodsRemark: '',
        DateOfProduction: '',
        ExpirationTime: '',
        UpdateTime: '',
        CreateTime: ''
      },
      dialogVisible: false,
    };
  },
  mounted() {
    this.FaKaStorageHuoPin = this.FaData;
    this.dialogFormVisible = this.show;
    this.getTableColumn();
  },
  methods: {
    //刷新
    Message_(e) {
      if (e == 0) {
        this.FaKaStorageHuoPin = this.FaData;
        this.getTableColumn();
      }
      this.TableOk = false;
    },
    // 表单加载
    getTableColumn() {
      LoadBasicUserColumn({ TableKey: 'GoodsStockSingleBindRFID' }).then(res => {
        if (res.Code == 0) {
          this.GoodsTable = res.Data;
        }
      })
    },
    bindCheckBox() {
      if (this.resource.length > 1) {
        this.resource.splice(0, 1)
      }
    },
    //批量填充
    BatchInput() {
      let list = this.OneGoodsListID.map(item => {
        item.FaKaCount = this.batchNumber;
        return item
      })
      this.OneGoodsListID = JSON.parse(JSON.stringify(list));
      this.dialogVisible = false;
    },
    AddBatch() {
      this.dialogVisible = true;
    },
    //选择整行 
    selectRowClassName({ row }) {
      var color = "";
      this.multipleSelection.forEach(item => {
        if (item.index == row.index) {
          color = "warning-row";
        }
      });
      return color;
    },
    //回车换行
    focusNextInputTow(rowIndex, columnName) {
      this.$nextTick(() => {
        let nextInput = null
        if (rowIndex == this.OneGoodsListID.length) {
          nextInput = document.querySelector(`[data-rowindex="${columnName}0"]`)
        } else {
          nextInput = document.querySelector(
            `[data-rowindex="${columnName}${rowIndex}"]`
          )
        }
        if (nextInput) {
          nextInput.focus()
        }
      })
    },
    // 删除物资
    delGoods(e) {
      let list = JSON.parse(JSON.stringify(this.OneGoodsListID));
      this.OneGoodsListID = list.filter((item) => item.index != e.index);
    },
    // 删除物资
    DelGoods() {
      for (let i = 0; i < this.selectTable.length; i++) {
        let list = JSON.parse(JSON.stringify(this.OneGoodsListID));
        this.OneGoodsListID = list.filter((item) => item.index != this.selectTable[i].index);
      }
    },
    //移除发卡RFID
    DeleteEPC() {
      for (let i = 0; i < this.OneGoodsListID.length; i++) {
        this.OneGoodsListID[i].GoodsRFIDEPC = "";
      }
    },
    // dialog中的货品列表选中数据
    handleSelectionChange1(val) {
      if (val.length != 0) {
        this.goodsChecked = false;
        this.selectTable = val;
        this.multipleSelection = JSON.parse(JSON.stringify(val))
      } else {
        this.goodsChecked = true;
      }
    },

    //单个发卡选中
    handleRowClick1(row) {
      this.multipleSelection.findIndex(item => {
        // 判断已选数组中是否已存在该条数据
        return item.GoodsID == row.GoodsID
      });
      this.$refs.dialogList.toggleRowSelection(row); //设置复选框为选中状态
    },
    // 表头拖动事件
    headerDragend(newWidth, data, e) {
      data
      if (e.property != null) {
        SetBasicUserColumnWidth({ TableKey: 'GoodsStockSingleBindRFID', ColumnKey: e.property, Width: newWidth }).then(res => {
          if (res.Code == 0) {
            successTips(res.Message);
          }
        })
      }
    },
    //单个发卡
    appendGoods() {
      this.DetailList = [];
      let GoodsID;
      for (let i = 0; i < this.OneGoodsListID.length; i++) {
        let RFID = this.OneGoodsListID[i].GoodsRFIDEPC;
        let Count = this.OneGoodsListID[i].FaKaCount;
        GoodsID = this.OneGoodsListID[i].GoodsID;
        if (RFID == "" || RFID == this.$t('StorageGoods.NoFaKa')) {
          msgTips(this.$t('StorageGoods.Section') + (i + 1) + this.$t('StorageGoods.line') + this.$t('StorageGoods.BatchFaKaMsgTips'));
          return;
        }
        if (Count == "NaN" || Count == 0) {
          msgTips(this.$t('StorageGoods.Section') + (i + 1) + this.$t('StorageGoods.line') + this.$t('StorageGoods.FaKaCountMesTips'));
          return;
        }
        this.DetailList.push({ Count, RFID })
      }
      BindSingleStorageGoodsEPCList({ GoodsID: GoodsID, Data: this.DetailList }).then((res) => {
        if (res.Code == 0) {
          successTips(res.Message);
          this.OneFaStatic = false;
          this.$emit("close");
          this.$emit('Message', res.Code);
          return;
        }
      });

    },
    FaKaRFID() {
      let obj = this.FaKaStorageHuoPin;
      this.RFIDInput = obj.FactStock;
      this.FakaState = true;
    },
    //单个发卡
    GoodsFaKa() {
      this.GoodsStatus = true;
      let obj = this.FaKaStorageHuoPin;
      let len = this.OneGoodsListID.length;
      if (this.RFIDInput > 1000) {
        msgTips(this.$t('StorageGoods.FaKaError'));
        this.GoodsStatus = false;
        return;
      }
      if (len > obj.FactStock) {
        msgTips(this.$t('StorageGoods.GoodsFaKaMessage') + obj.FactStock);
        this.GoodsStatus = false;
        return;
      }
      if (this.RFIDInput > obj.FactStock) {
        msgTips(this.$t('StorageGoods.GoodsFaKaMessage') + obj.FactStock);
        this.GoodsStatus = false;
        return;
      }
      let index;
      if (len == 0) {
        index = 0
      } else {
        index = this.OneGoodsListID[len - 1].index;
      }
      for (let i = index + 1; i < parseInt(2 + index); i++) {
        let epc = "";
        obj.FaKaCount = 1;
        if (this.resource == '1') {
          ProductRFID({ count: this.RFIDInput }).then((res) => {
            if (res.Code == 0) {
              for (let i = 0; i < res.Data.length; i++) {
                epc = res.Data[i];
                obj.GoodsRFIDEPC = epc;
                this.OneGoodsListID.push({ index: i, ...obj });
                if (this.GoodsStatus == true) {
                  this.GoodsStatus = false;
                } else {
                  this.GoodsStatus = true;
                }
              }
            }
          });
        } else if (this.resource == "2") {
          for (let i = 0; i < this.RFIDInput; i++) {
            obj.GoodsRFIDEPC = "";
            this.OneGoodsListID.push({ index: i, ...obj });
            if (this.GoodsStatus == true) {
              this.GoodsStatus = false;
            } else {
              this.GoodsStatus = true;
            }
          }
        }
      }
      this.FakaState = false;
    },

    // 关闭
    close() {
      this.$emit('Message', 0);
      this.$emit("close");
    },
  },
};
</script> 
<style lang="scss" scoped>
._dialog {
  width: 100%;
  height: calc(100vh - 115px);
  padding: 0 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  ._top {
    height: 48px;
    display: flex;
    align-items: center;

    span {
      color: #4e5969;
      font-size: 14px;
      margin: 0 20px 0 5px;
      cursor: pointer;
    }

    font {
      font-size: 16px;
      font-weight: bold;
      margin-left: 20px;
    }
  }

  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 160px;
      height: 160px;
    }

    span {
      line-height: 30px;
    }

    font {
      color: #5c90ff;
      cursor: pointer;
    }
  }

  ._footer {
    padding: 10px 0 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.FaKaRadion {
  margin-top: 27px;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
  // margin-bottom: 50px;
}

._filter {
  height: 40px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  span {
    margin-right: 20px;
    font-size: 14px;
    cursor: pointer;

    .el-icon-refresh {
      margin-left: 10px;
      font-size: 16px;
    }
  }

  span i {
    margin-right: 20px;
    color: #1a73e8;
    font-size: 14px;
    cursor: pointer;

    .el-icon-refresh {
      margin-left: 10px;
      font-size: 16px;
    }
  }
}

._info {
  width: 100%;

  .title {
    color: #4e5969;
    font-size: 14px;
    font-weight: bold;
  }

  .content {
    display: flex;
    height: 90px;

    .content_item {
      height: 90px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      p {
        margin: 0;
      }

      span {
        display: inline-block;
        min-width: 80px;
        // text-align: right;
        color: #86909c;
        font-size: 14px;
      }
    }
  }
}

._table_input .el-input {
  width: 100%;
}
</style>